import { Scene } from "phaser";
import Phaser from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store/index.js";
import router from "@/router";
import _ from "lodash";

const TOTAL_TILE_COUNT = 16; // Must be a square number
// const TOTAL_BOMB_COUNT = 5;
const BOMBING_TIMES = 3;

export default class BossScene extends Scene {
  constructor() {
    super({ key: "BossScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.round = data.round;
    this.questions = data.questions;
    this.score = data.score;
    this.bossRound = data.bossRound;
    this.finishedTime = data.finishedTime;
    this.isGemCracked = data.isGemCracked;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
  }

  avatarObject = [];
  smokeBombList = [];
  newMetalWall = [];
  disposedBombCount = 0;
  bompingTimes = 0;

  create() {
    this.bombCount = this.difficulty == 1 ? 2 : this.difficulty == 2 ? 3 : 4;
    this.missClicks = this.isGemCracked ? 1 : 0;
    this.gemScore = Math.floor(Math.random() * (50 - 30 + 1)) + 30;
    this.add.image(240, 427, "bg").setScale(0.5);

    this.wall = this.add.image(240, 390, "wall").setScale(1.12);

    // Treasure
    this.treasurePath = new Phaser.Curves.Path(240, 390).splineTo([
      170, 300, 100, 360, 100, 730,
    ]);

    this.treasure = this.add
      .follower(this.treasurePath, 240, 390, "bossGem")
      .setScale(0.4);

    this.treasure.anim = this.add
      .sprite(240, 390, "breaking")
      .setFrame(this.isGemCracked ? 1 : 0)
      .setScale(0.9)
      .setVisible(false);

    this.treasure.mask = this.treasure.anim.createBitmapMask();

    this.treasureBlink = this.add.image(240, 390, "blink").setScale(0);
    this.gemTween = this.tweens.add({
      onStart: () => {
        this.gemBlinkSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.gemBlinkSound.play();
      },
      onRepeat: () => {
        this.gemBlinkSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.gemBlinkSound.play();
      },
      targets: this.treasureBlink,
      scaleX: 0.3,
      scaleY: 0.3,
      duration: 200,
      yoyo: true,
      ease: "Sine.easeInOut",
      repeat: -1,
      repeatDelay: 3000,
    });

    // Score, Round and Settings Graphics
    this.add.image(80, 35, "score").setScale(0.3);
    this.add.image(70, 70, "score").setScale(0.25, 0.2);
    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#000",
        }
      )
      .setOrigin(0, 0.5);

    this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#000",
      })
      .setOrigin(0, 0.5);

    this.settingBtn = this.add.image(440, 40, "setting").setScale(0.6);
    this.settingBtn.on("pointerdown", clickSetting, this);
    this.settingBtn.setInteractive({ useHandCursor: true });

    // Timer Canvas
    this.clock = this.add.image(90, 140, "clock").setScale(0.5).setDepth(10);
    this.timerBox = this.add
      .graphics()
      .fillStyle(0x875f41, 1)
      .fillRect(100, 130, 310, 20);

    this.timerBar = this.add
      .graphics()
      .fillStyle(this.finishedTime < 0.3 ? 0xfa3838 : 0x23de46, 1)
      .fillRect(105, 135, 300 * this.finishedTime, 10);

    this.pickaxe = this.add.sprite(160, 720, "pickaxeBoss").setScale(0.6);

    this.boom = this.add.sprite(370, 720, "boom").setScale(0.9).setFrame(0);

    // avatar
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(this.add.image(100, 720, key).setScale(0.3));
          this.avatarObject.push(
            this.add
              .image(100, 720, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.3)
              .setAlpha(0.3)
          );
        } else if (key != "color") {
          this.avatarObject.push(this.add.image(100, 720, key).setScale(0.3));
        }
      }
    }

    // feedback and warning graphics
    this.feedback = this.add
      .text(240, 390, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(99);
    this.feedback.setShadow(3, 3, "#000", 0, true, true);

    this.warningText = this.add
      .text(240, 370, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(99);
    this.warningText.setShadow(3, 3, "#000", 0, true, true);

    this.warningMessageText = this.add
      .text(240, 420, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 28,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(99);
    this.warningMessageText.setShadow(1.5, 1.5, "#000", 0, true, true);

    this.warningBackground = this.add
      .rectangle(240, 390, 408, 200, 0xffffff)
      .setDepth(9)
      .setAlpha(0);

    this.modelbg = this.add
      .rectangle(240, 390, 408, 100, 0xffffff)
      .setDepth(9)
      .setAlpha(0);

    this.speechText = this.add
      .text(100, 650, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 24,
        color: "#492a12",
      })
      .setOrigin(0.5)
      .setAlpha(0);

    // Animations
    this.anims.create({
      key: "bomb_smoke",
      frames: this.anims.generateFrameNumbers("smoke_bomb_sprite", {
        frames: [0, 1, 2, 3, 2, 1, 0],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.anims.create({
      key: "smoke",
      frames: this.anims.generateFrameNumbers("smoke_sprite", {
        frames: [0, 1, 2, 3, 4, 5, 4, 3, 4, 5, 6, 7],
      }),
      frameRate: 5,
    });

    this.anims.create({
      key: "thief_smoke",
      frames: this.anims.generateFrameNumbers("smoke_sprite", {
        frames: [3, 4, 5, 4, 3, 4, 5, 6, 7],
      }),
      frameRate: 5,
    });

    this.anims.create({
      key: "gemCrack",
      frames: this.anims.generateFrameNumbers("breaking", {
        frames: [0, 1],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "gemBreak",
      frames: this.anims.generateFrameNumbers("breaking", {
        frames: [2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "throw",
      frames: this.anims.generateFrameNumbers("pickaxeBoss", {
        frames: [1, 2, 3, 0],
      }),
      repeat: -1,
      frameRate: 20,
    });

    this.anims.create({
      key: "hit",
      frames: this.anims.generateFrameNumbers("boom", {
        frames: [1, 2, 3, 2, 1, 0],
      }),
      frameRate: 15,
    });

    this.generateBombs();

    // Sounds
    this.evilSound = this.sound.add("evil");
    this.bombThrowSound = this.sound.add("bombThrow");
    this.warningAlarmSound = this.sound.add("warningAlarm");
    this.warningAlarmSound.loop = true;
    this.smokeBombingSound = this.sound.add("smokeBombing");
    this.scoreIncreaseSound = this.sound.add("scoreIncrease");
    this.collectGemSound = this.sound.add("collectGem");
    this.bombClickSound = this.sound.add("bombClick");
    this.failureSound = this.sound.add("failure");
    this.successSound = this.sound.add("success");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.somkeSound = this.sound.add("smoke");
    this.stealGemSound = this.sound.add("stealGem");
    this.bossRoundSound = this.sound.add("bossWhistle");
    this.bombTimerSound = this.sound.add("timerBomb");
    this.bombTimerSound.loop = true;
    this.gemSplashSound = this.sound.add("gemSplash");
    this.gemBlinkSound = this.sound.add("gemBlink");
    this.digGemSound = this.sound.add("digGem");
    this.gemLostSound = this.sound.add("gemLost");
    this.clearedSound = this.sound.add("cleared");

    this.thief = this.add.image(380, 720, "thief").setScale(-0.3, 0.3);
    this.thief.visible = false;
    this.thiefSmoke = this.add
      .sprite(360, 740, "smoke_sprite")
      .setScale(0.6)
      .setDepth(99)
      .setAlpha(0);

    this.avatarMask;
    this.thiefMask;

    // all stars here
    this.time.delayedCall(
      500,
      () => {
        this.warningAlarmSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.warningAlarmSound.play();
        this.warningText.setText(i18n.t("miniGames.mining.warning"));
        this.warningText.setTint(0xfa3838);
        this.warningMessageText.setText(i18n.t("miniGames.mining.warningMsg"));
        this.warningMessageText.setTint(0xfa3838);
        this.tweens.add({
          targets: this.warningBackground,
          alpha: 0.5,
          duration: 200,
          ease: "Sine.easeInOut",
        });
        this.tweens.add({
          targets: [this.warningText, this.warningMessageText],
          scaleY: 1,
          scaleX: 1,
          duration: 200,
          ease: "Sine.easeInOut",
          onComplete: () => {
            this.tweens.add({
              targets: this.warningText,
              alpha: 0,
              repeat: 3,
              yoyo: true,
              duration: 200,
              ease: "Sine.easeInOut",
              onComplete: () => {
                this.warningAlarmSound.stop();
                this.tweens.add({
                  targets: [this.warningText, this.warningMessageText],
                  scaleY: 0,
                  scaleX: 0,
                  duration: 200,
                  delay: 1000,
                  ease: "Sine.easeInOut",
                });
                this.tweens.add({
                  targets: [
                    this.timerBox,
                    this.timerBar,
                    this.clock,
                    this.warningBackground,
                  ],
                  alpha: 0,
                  duration: 200,
                  delay: 1000,
                  ease: "Sine.easeInOut",
                });

                this.tweens.add({
                  targets: this.thiefSmoke,
                  alpha: 1,
                  duration: 200,
                  delay: 1000,
                  ease: "Sine.easeInOut",
                  onStart: () => {
                    this.tweens.add({
                      targets: [this.avatarMark, this.avatarSpeech],
                      scaleX: 0.3,
                      scaleY: 0.3,
                      duration: 300,
                      ease: "Sine.easeOut",
                      onComplete: () => {
                        this.tweens.add({
                          targets: [this.avatarMark, this.avatarSpeech],
                          scaleX: 0,
                          scaleY: 0,
                          duration: 100,
                          delay: 2000,
                          ease: "Sine.easeOut",
                          onComplete: () => {},
                        });
                      },
                    });
                  },
                  onComplete: () => {
                    this.thiefSmoke.play("thief_smoke");
                    this.somkeSound.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    if (this.avatarMask) {
                      this.avatarMask.destroy();
                    }
                    this.avatarMask = this.add
                      .image(100, 720, "fear")
                      .setScale(0.3)
                      .setDepth(5);
                    this.somkeSound.play();
                    this.time.delayedCall(1500, () => {
                      this.thief.visible = true;
                      this.time.delayedCall(500, () => {
                        this.evilSound.setVolume(
                          store.state.settings.data.audio.sfx *
                            store.state.settings.data.audio.master
                        );
                        this.evilSound.play();
                        this.tweens.add({
                          targets: [this.bossEvil, this.bossSpeech],
                          scaleX: 0.3,
                          scaleY: 0.3,
                          duration: 100,
                          ease: "Sine.easeOut",
                          onComplete: () => {
                            this.tweens.add({
                              targets: [this.bossEvil, this.bossSpeech],
                              scaleX: 0,
                              scaleY: 0,
                              duration: 100,
                              delay: 1000,
                              ease: "Sine.easeOut",
                              onComplete: () => {
                                // if (this.avatarMask) {
                                //   this.avatarMask.destroy();
                                // }
                                this.bossRoundSound.setVolume(
                                  store.state.settings.data.audio.sfx *
                                    store.state.settings.data.audio.master
                                );
                                this.bossRoundSound.play();
                                this.time.delayedCall(2000, () => {
                                  this.treasure.setInteractive({
                                    cursor: "pointer",
                                  });
                                  this.bompingTimes = 1;
                                  this.throwBombs();
                                });
                              },
                            });
                          },
                        });
                      });
                    });
                  },
                });
              },
            });
          },
        });
      },
      [],
      this
    );

    this.bossSpeech = this.add.image(380, 660, "speech").setScale(0.0);
    this.bossMark = this.add.image(380, 660, "excl").setScale(0.0);
    this.bossEvil = this.add.image(380, 660, "evil").setScale(0.0);

    this.avatarSpeech = this.add.image(100, 620, "speech").setScale(0.0);
    this.avatarMark = this.add.image(100, 620, "excl").setScale(0.0);

    this.input.on(
      "pointerdown",
      function (event, gameObjects) {
        if (
          gameObjects[0] &&
          gameObjects[0].name == "smokeBomb" &&
          !this.isGemLost
        ) {
          this.bombClickSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.bombClickSound.play();
          let smokeBomb = gameObjects[0];
          smokeBomb.isDisposed = true;
          smokeBomb.destroy();
          smokeBomb.countdown.destroy();
          this.disposedBombCount++;

          if (this.disposedBombCount == this.bombCount) {
            this.bombTimerSound.stop();
            if (this.bompingTimes == BOMBING_TIMES) {
              if (this.speechText) {
                this.speechText.setText("");
              }
              this.treasure.removeInteractive();
              this.treasureBlink.destroy();
              this.gemTween.stop();
              this.successSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.successSound.play();
              this.isGemCollected = true;
              this.tweens.add({
                targets: [this.bossMark, this.bossSpeech],
                scaleX: 0.3,
                scaleY: 0.3,
                duration: 300,
                ease: "Sine.easeOut",
                onComplete: () => {
                  if (this.thiefMask) {
                    this.thiefMask.destroy();
                  }
                  this.thiefMask = this.add
                    .image(380, 740, "mad")
                    .setScale(-0.3, 0.3)
                    .setDepth(5);
                  this.tweens.add({
                    targets: [this.bossMark, this.bossSpeech],
                    scaleX: 0,
                    scaleY: 0,
                    duration: 100,
                    delay: 2000,
                    ease: "Sine.easeOut",
                    onComplete: () => {
                      this.tweens.add({
                        onStart: () => {
                          if (this.avatarMask) {
                            this.avatarMask.destroy();
                          }
                          this.avatarMask = this.add
                            .image(100, 720, "happy")
                            .setScale(0.3)
                            .setDepth(5);
                          this.pickaxe.play("throw");
                          this.pickaxe.setDepth(99);
                        },
                        targets: this.pickaxe,
                        x: 370,
                        duration: 500,
                        ease: "Sine.easeInOut",
                        onComplete: () => {
                          this.pickaxe.destroy();
                          this.digGemSound.setVolume(
                            store.state.settings.data.audio.sfx *
                              store.state.settings.data.audio.master
                          );
                          this.digGemSound.play();
                          this.boom.setDepth(99);
                          this.boom.play("hit");
                          if (this.thiefMask) {
                            this.thiefMask.destroy();
                          }
                          this.thiefMask = this.add
                            .image(380, 720, "stun")
                            .setScale(-0.3, 0.3)
                            .setDepth(5);

                          this.time.delayedCall(2000, () => {
                            this.thiefSmoke.play("thief_smoke");
                            this.somkeSound.setVolume(
                              store.state.settings.data.audio.sfx *
                                store.state.settings.data.audio.master
                            );
                            this.somkeSound.play();
                            if (this.thiefMask) {
                              this.thiefMask.destroy();
                            }
                            this.time.delayedCall(1000, () => {
                              this.thief.visible = false;
                              this.time.delayedCall(1000, () => {
                                this.feedback.setText(
                                  i18n.t("miniGames.mining.cleared")
                                );
                                this.feedback.setTint(0x23de46);
                                this.tweens.add({
                                  targets: this.modelbg,
                                  alpha: 0.5,
                                  duration: 200,
                                  ease: "Sine.easeInOut",
                                });
                                this.tweens.add({
                                  targets: this.feedback,
                                  scaleY: 1,
                                  scaleX: 1,
                                  duration: 200,
                                  ease: "Sine.easeInOut",
                                  onStart: () => {
                                    this.clearedSound.setVolume(
                                      store.state.settings.data.audio.sfx *
                                        store.state.settings.data.audio.master
                                    );
                                    this.clearedSound.play();
                                  },
                                  onComplete: () => {
                                    this.tweens.add({
                                      targets: this.feedback,
                                      scaleY: 0,
                                      scaleX: 0,
                                      duration: 200,
                                      delay: 2000,
                                      ease: "Sine.easeInOut",
                                    });
                                    this.tweens.add({
                                      targets: this.modelbg,
                                      alpha: 0,
                                      duration: 200,
                                      delay: 2000,
                                      ease: "Sine.easeInOut",
                                      onComplete: () => {
                                        this.treasure.mask.destroy();
                                        this.treasure.anim.destroy();
                                        this.tweens.add({
                                          targets: this.treasure,
                                          scaleY: 0.15,
                                          scaleX: 0.15,
                                          duration: 500,
                                          ease: "Sine.easeInOut",
                                          delay: 1000,
                                          onStart: () => {
                                            this.collectGemSound.setVolume(
                                              store.state.settings.data.audio
                                                .sfx *
                                                store.state.settings.data.audio
                                                  .master
                                            );
                                            this.collectGemSound.play();
                                          },
                                          onComplete: () => {
                                            this.tweens.add({
                                              targets: this.treasure,
                                              scaleX: -0.15,
                                              duration: 300,
                                              ease: "Sine.easeInOut",
                                              delay: 300,
                                              yoyo: true,
                                              onComplete: () => {
                                                this.tweens.add({
                                                  targets: this.treasure,
                                                  scaleY: 0.1,
                                                  scaleX: 0.1,
                                                  duration: 500,
                                                  ease: "Sine.easeInOut",
                                                });
                                                this.treasure.startFollow({
                                                  duration: 1000,
                                                  ease: "Quad.easeInOut",
                                                  onComplete: () => {
                                                    this.avatarSpeaking(
                                                      i18n.t(
                                                        "miniGames.general.score"
                                                      ) +
                                                        " +" +
                                                        this.gemScore
                                                    );
                                                    this.score += this.gemScore;
                                                    this.scoreText.setText(
                                                      i18n.t(
                                                        "miniGames.general.score"
                                                      ) +
                                                        " : " +
                                                        this.score
                                                    );
                                                    // this.event_data =
                                                    //   _.cloneDeep(
                                                    //     store.state.event.data
                                                    //   );
                                                    // this.event_data.boss.isCollected = true;
                                                    // store.commit(
                                                    //   "updateEvent",
                                                    //   this.event_data
                                                    // );
                                                    let tempMiniGame;
                                                    tempMiniGame = _.cloneDeep(
                                                      store.state.miniGame.data
                                                    );
                                                    tempMiniGame.currnet.isBossCollected = true;
                                                    store.commit(
                                                      "updateMiniGame",
                                                      tempMiniGame
                                                    );
                                                    this.time.delayedCall(
                                                      2000,
                                                      () => {
                                                        this.nextRound();
                                                      }
                                                    );
                                                  },
                                                });
                                              },
                                            });
                                          },
                                        });
                                      },
                                    });
                                  },
                                });
                              });
                            });
                          });
                        },
                      });
                    },
                  });
                },
              });
            } else {
              if (this.timedEvent) {
                this.timedEvent.remove(false);
                this.timedEvent.destroy();
                this.timedEvent = null;
              }
              this.bompingTimes++;
              this.disposedBombCount = 0;
              this.smokeBombList = [];
              this.bombCount = this.bombCount + 2;

              this.generateBombs();

              this.throwBombs();
            }
          }
        }
      },
      this
    );

    this.treasure.on("pointerdown", () => {
      this.digGemSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.digGemSound.play();
      this.missClicks += 1;

      // todo
      // test for ios and other platforms
      // navigator.vibrate(300)

      if (this.missClicks == 2) {
        if (this.speechText) {
          this.speechText.setText("");
        }
        this.treasureBlink.destroy();
        this.gemTween.stop();
        if (this.timedEvent) {
          this.timedEvent.remove(false);
          this.timedEvent.destroy();
          this.timedEvent = null;
        }

        this.bombTimerSound.stop();
        this.smokeBombList.forEach((element) => {
          if (!element.isDisposed) {
            element.countdown.visible = false;
            element.destroy();
          }
        });
        this.treasure.anim.play("gemBreak");
        this.gemSplashSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.gemSplashSound.play();
        if (this.avatarMask) {
          this.avatarMask.destroy();
        }
        this.avatarMask = this.add
          .image(100, 720, "sad")
          .setScale(0.3)
          .setDepth(5);

        if (this.thiefMask) {
          this.thiefMask.destroy();
        }
        this.thiefMask = this.add
          .image(380, 740, "mad")
          .setScale(-0.3, 0.3)
          .setDepth(5);

        this.feedback.setText(i18n.t("miniGames.mining.gemLostBoss"));
        this.feedback.setTint(0xfa3838);
        this.tweens.add({
          targets: this.modelbg,
          alpha: 0.5,
          duration: 200,
          ease: "Sine.easeInOut",
        });
        this.tweens.add({
          targets: this.feedback,
          scaleY: 1,
          scaleX: 1,
          duration: 200,
          ease: "Sine.easeInOut",
        });

        this.treasure.anim.on("animationcomplete", () => {
          this.treasure.destroy();
          this.time.delayedCall(
            2000,
            () => {
              if (this.thiefMask) {
                this.thiefMask.destroy();
              }
              this.thiefSmoke.play("thief_smoke");
              this.somkeSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.somkeSound.play();
              this.time.delayedCall(100, () => {
                this.thief.visible = false;
                this.treasure.visible = false;
                this.gemLostSound.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );

                this.time.delayedCall(1500, () => {
                  this.gemLostSound.play();

                  this.tweens.add({
                    targets: this.modelbg,
                    alpha: 0.5,
                    duration: 200,
                    ease: "Sine.easeInOut",
                  });
                  this.tweens.add({
                    targets: this.feedback,
                    scaleY: 1,
                    scaleX: 1,
                    duration: 200,
                    ease: "Sine.easeInOut",
                    onComplete: () => {
                      this.time.delayedCall(2000, () => {
                        this.nextRound();
                      });
                    },
                  });
                });
              });
            },
            [],
            this
          );
        });
      } else if (this.missClicks == 1) {
        this.avatarSpeaking(i18n.t("miniGames.mining.careful"), false);
        this.isGemCracked = true;
        this.treasure.anim.play("gemCrack");
        if (this.avatarMask) {
          this.avatarMask.destroy();
        }
        this.avatarMask = this.add
          .image(100, 720, "fear")
          .setScale(0.3)
          .setDepth(5);
      }
    });

    function clickSetting() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "BossScene",
      });
    }
  }

  generateBombs() {
    let map = randomMap(this.bombCount);
    let rawCount = Math.sqrt(TOTAL_TILE_COUNT);
    let colCount = Math.sqrt(TOTAL_TILE_COUNT);
    let bombCount = 0;
    for (let j = 0; j < rawCount; j++) {
      for (let i = 0; i < colCount; i++) {
        if (map[bombCount] == 1) {
          let smokeBomb = {};
          smokeBomb = this.add.sprite(400, 760, "smoke_bomb_sprite");
          smokeBomb.setScale(0);
          smokeBomb._x = 90 + i * (400 / colCount);
          smokeBomb._y = 240 + j * (400 / rawCount);
          smokeBomb.countdown = this.add.text(
            80 + i * (400 / colCount),
            240 + j * (400 / rawCount),
            "3",
            {
              fontFamily: "Sassoon, sans-serif",
              fontSize: 24,
              color: "#fff",
            }
          );
          smokeBomb.isDisposed = false;
          smokeBomb.countdown.visible = false;
          smokeBomb.play("bomb_smoke");
          smokeBomb.setInteractive({
            cursor: "pointer",
          });
          smokeBomb.index = bombCount;
          smokeBomb.name = "smokeBomb";
          this.smokeBombList.push(smokeBomb);
        }
        bombCount++;
      }
    }

    // Create a Random Tile Map Array
    function randomMap(bombCount) {
      let map = new Array(TOTAL_TILE_COUNT).fill(0);
      for (let i = 0; i < bombCount; i++) {
        let position = Math.floor(
          Math.random() * (TOTAL_TILE_COUNT - 0 + 1) + 0
        );
        if (map[position] != 0) {
          while (map[position] != 0) {
            position = Math.floor(
              Math.random() * (TOTAL_TILE_COUNT - 0 + 1) + 0
            );
          }
        }
        map[position] = 1;
      }
      return map;
    }
  }

  throwBombs() {
    this.bombThrowSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.bombThrowSound.play();
    this.smokeBombList.forEach((element) => {
      this.tweens.add({
        targets: element,
        scaleY: 1,
        scaleX: 1,
        y: element._y,
        x: element._x,
        duration: 400,
        ease: "Sine.easeInOut",
        onComplete: () => {
          element.countdown.visible = true;
          this.timedEvent = this.time.delayedCall(3000);
          this.bombTimerSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.bombTimerSound.play();
        },
      });
    });
  }

  nextRound() {
    if (this.round != this.noOfRounds) {
      if (!this.isGameEnded) {
        this.isGameEnded = true;

        if (this.thiefMask) {
          this.thiefMask.destroy();
        }
        if (this.treasure) {
          this.treasure.destroy();
        }

        this.tweens.add({
          targets: this.avatarObject,
          scaleY: 0.31,
          scaleX: 0.29,
          y: 710,
          duration: 700,
          ease: "Sine.easeInOut",
          repeat: 1,
          yoyo: true,
          delay: 2000,
          onStart: () => {
            if (this.avatarMask) {
              this.tweens.add({
                targets: this.avatarMask,
                scaleY: 0.31,
                scaleX: 0.29,
                y: 710,
                duration: 700,
                ease: "Sine.easeInOut",
                repeat: 1,
                yoyo: true,
              });
            }
          },
          onComplete: () => {
            this.scene.start("RoundStartScene", {
              avatar: this.avatar,
              round: this.round + 1,
              score: this.score,
              questions: this.questions,
              bossRound: this.bossRound,
              friend: this.friend,
              noOfRounds: this.noOfRounds,
              difficulty: this.difficulty,
              countCorrect: this.countCorrect,
            });
            if (this.timedEvent) {
              this.timedEvent.destroy();
              this.timedEvent = null;
            }
            this.registry.destroy();
            this.events.off();
            this.scene.stop("BoseScene");
          },
        });

        this.newBg = this.add.image(760, 427, "bg").setScale(0.5);
        this.newWall = this.add.image(720, 390, "wall").setScale(1.12);
        for (let j = 0; j < 4; j++) {
          for (let i = 0; i < 4; i++) {
            let tile = {};
            tile = this.add.image(570 + i * 100, 240 + j * 100, "steelWall");
            this.newMetalWall.push(tile);
          }
        }

        this.tweens.add({
          targets: [this.wall, this.feedback, this.modelbg],
          x: -240,
          duration: 1000,
          ease: "Sine.easeInOut",
          delay: 2000,
          onComplete: () => {
            if (this.avatarMask) {
              this.avatarMask.destroy();
            }
            if (this.speechText) {
              this.speechText.destroy();
            }
          },
        });

        this.newMetalWall.forEach((element) => {
          this.tweens.add({
            targets: element,
            x: element.x - 480,
            duration: 1000,
            ease: "Sine.easeInOut",
            delay: 2000,
          });
        });

        this.tweens.add({
          targets: this.newWall,
          x: 240,
          duration: 1000,
          ease: "Sine.easeInOut",
          delay: 2000,
        });
      }
    } else {
      // let star;
      // this.event_data = _.cloneDeep(store.state.event.data);
      // if (this.score >= 600 && this.score < 750) {
      //   star = 1;
      // } else if (this.score >= 750 && this.score < 900) {
      //   star = 2;
      // } else if (this.score >= 900) {
      //   star = 3;
      // } else {
      //   star = 0;
      // }

      // this.event_data.submission.countCorrect = this.countCorrect;
      // this.event_data.submission.accuracy = this.score / 10;
      // this.event_data.submission.star = star;
      // this.event_data.submission.score = this.score;
      // this.event_data.submission.submission += 1;

      // this.event_data.record.attempt += 1;
      // this.event_data.record.submission += 1;
      // this.event_data.record.topScoreOld = this.event_data.record.topScore;
      // this.event_data.record.topStarOld = this.event_data.record.topStar;
      // if (this.score > this.event_data.record.topScore) {
      //   this.event_data.record.topScore = this.score;
      //   this.event_data.record.topStar = star;
      // }

      // this.event_data.gameLoot = Math.floor(Math.random() * (5 - 3 + 1) + 3);
      // store.commit("updateEvent", this.event_data);

      this.time.delayedCall(1000, () => {
        router.push({
          name: "PageExploreDailyGameScore",
        });
      });
    }
  }

  avatarSpeaking(text, playSound = true) {
    if (this.speechText) {
      this.speechText.setText(text);
      this.tweens.add({
        targets: this.speechText,
        alpha: 1,
        yoyo: true,
        duration: 1000,
        ease: "Sine.easeInOut",
      });
      this.tweens.add({
        targets: this.speechText,
        y: 600,
        duration: 2000,
        ease: "Sine.easeInOut",
        onStart: () => {
          if (playSound) {
            this.scoreIncreaseSound.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.scoreIncreaseSound.play();
          }
        },
        onComplete: () => {
          this.speechText.y = 650;
        },
      });
    }
  }

  gemLost() {
    if (!this.isGemCollected) {
      if (this.speechText) {
        this.speechText.setText("");
      }
      this.treasure.removeInteractive();
      this.treasureBlink.destroy();
      this.gemTween.stop();
      this.bombTimerSound.stop();
      this.gemSmoke = this.add
        .sprite(240, 390, "smoke_sprite")
        .setScale(4)
        .setDepth(99);
      this.gemSmoke.play("smoke");
      if (this.avatarMask) {
        this.avatarMask.destroy();
      }
      this.avatarMask = this.add
        .image(100, 720, "fear")
        .setScale(0.3)
        .setDepth(5);
      this.smokeBombingSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.smokeBombingSound.play();
      this.time.delayedCall(500, () => {
        this.stealGemSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.stealGemSound.play();
        this.treasure.mask.destroy();
        this.treasure.anim.destroy();
        this.treasure.setScale(0.08);
        this.treasure.setDepth(9);
        this.treasure.x = 430;
        this.treasure.y = 760;

        this.smokeBombList.forEach((element) => {
          if (!element.isDisposed) {
            element.destroy();
            element.countdown.visible = false;
          }
        });
        this.time.delayedCall(1000, () => {
          if (this.avatarMask) {
            this.avatarMask.destroy();
          }
          this.avatarMask = this.add
            .image(100, 740, "mad")
            .setScale(0.3)
            .setDepth(5);
          this.evilSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.evilSound.play();
          this.tweens.add({
            targets: [this.bossEvil, this.bossSpeech],
            scaleX: 0.3,
            scaleY: 0.3,
            duration: 100,
            ease: "Sine.easeOut",
            onComplete: () => {
              this.tweens.add({
                targets: [this.bossEvil, this.bossSpeech],
                scaleX: 0,
                scaleY: 0,
                duration: 100,
                delay: 1000,
                ease: "Sine.easeOut",
                onComplete: () => {
                  this.feedback.setText(i18n.t("miniGames.mining.gemLostBoss"));
                  this.feedback.setTint(0xfa3838);

                  this.timedEvent = this.time.delayedCall(
                    2000,
                    () => {
                      this.thiefSmoke.play("thief_smoke");
                      this.somkeSound.setVolume(
                        store.state.settings.data.audio.sfx *
                          store.state.settings.data.audio.master
                      );
                      this.somkeSound.play();
                      if (this.avatarMask) {
                        this.avatarMask.destroy();
                      }
                      this.avatarMask = this.add
                        .image(100, 720, "sad")
                        .setScale(0.3)
                        .setDepth(5);
                      this.time.delayedCall(100, () => {
                        this.thief.visible = false;
                        this.treasure.visible = false;
                        this.failureSound.setVolume(
                          store.state.settings.data.audio.sfx *
                            store.state.settings.data.audio.master
                        );

                        this.time.delayedCall(1500, () => {
                          this.failureSound.play();

                          this.tweens.add({
                            targets: this.modelbg,
                            alpha: 0.5,
                            duration: 200,
                            ease: "Sine.easeInOut",
                          });
                          this.tweens.add({
                            targets: this.feedback,
                            scaleY: 1,
                            scaleX: 1,
                            duration: 200,
                            ease: "Sine.easeInOut",
                            onComplete: () => {
                              this.time.delayedCall(2000, () => {
                                this.nextRound();
                              });
                            },
                          });
                        });
                      });
                    },
                    [],
                    this
                  );
                },
              });
            },
          });
        });
      });
    }
  }

  update() {
    if (this.timedEvent && !this.isGemLost && !this.isGemCollected) {
      if (this.timedEvent.getProgress().toString().substr(0, 4) > 0.99) {
        this.isGemLost = true;
        this.gemLost();
        this.smokeBombList.forEach((element) => {
          if (!element.isDisposed) element.countdown.setText("");
        });
      } else if (this.timedEvent.getProgress().toString().substr(0, 4) > 0.66) {
        this.smokeBombList.forEach((element) => {
          if (!element.isDisposed) element.countdown.setText("1");
        });
      } else if (this.timedEvent.getProgress().toString().substr(0, 4) > 0.33) {
        this.smokeBombList.forEach((element) => {
          if (!element.isDisposed) element.countdown.setText("2");
        });
      }
    }
  }
}

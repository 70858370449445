import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class QuestionScene extends Scene {
  constructor() {
    super({ key: "QuestionScene" });
  }

  avatarObject = [];
  isAnswered = false;
  // questionScore = 80;

  init(data) {
    this.avatar = data.avatar;
    this.round = data.round;
    this.questions = data.questions;
    this.question = this.questions[this.round - 1];
    this.score = data.score;
    this.bossRound = data.bossRound;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.forceFriend = data.forceFriend;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.avatarX = data.avatarX;
  }

  preload() {
    this.load.html("question", this.question.question);
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
  }

  create() {
    this.add.image(80, 35, "score").setScale(0.3);
    this.add.image(70, 70, "score").setScale(0.25, 0.2);
    this.settingBtn = this.add.image(440, 40, "setting").setScale(0.6);
    this.settingBtn.on("pointerdown", clickSetting, this);
    this.settingBtn.setInteractive({ useHandCursor: true });
    this.scoreText = this.add
      .text(30, 35, i18n.t("miniGames.general.score") + " : " + this.score, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 18,
        color: "#000",
      })
      .setOrigin(0, 0.5);

    this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#000",
      })
      .setOrigin(0, 0.5);

    this.q_dom = this.add.dom(240, 110).createFromHTML(`
    <div style="
      width: 440px;
      height: 380px;
      border-radius: 20px;
      background-color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    "></div>
    `);
    this.q_dom.setOrigin(0.5, 0);
    this.q_dom.setPosition(240, 110);
    this.q_dom.node.innerHTML = this.question.question;
    this.q_dom.node.style.borderRadius = "10px";
    this.q_dom.node.style.padding = "20px";
    this.q_dom.node.style.backgroundColor = "#ffffff";
    this.q_dom.node.style.height = "380px";
    this.q_dom.node.style.width = "440px";
    this.q_dom.node.style.overflowY = "auto";

    this.okBtn = this.add.image(380, 800, "okBtnImage").setScale(0).setDepth(3);
    this.okBtnTxt = this.add
      .text(380, 800, i18n.t("miniGames.general.ok"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 42,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(3);
    this.okBtn.setInteractive({ cursor: "pointer" });

    // Sounds
    this.correctAnswerSound = this.sound.add("correctAnswer");
    this.wrongAnswerSound = this.sound.add("wrongAnswer");
    this.scoreIncreaseSound = this.sound.add("scoreIncrease");
    this.simpleClickSound = this.sound.add("simpleClick");

    this.okBtn.on(
      "pointerdown",
      () => {
        this.simpleClickSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.simpleClickSound.play();
        this.avatarObject = [];
        this.isAnswered = false;
        this.registry.destroy();
        this.events.off();
        this.scene.stop("QuestionScene");
        if (this.isCorrectAnswer) {
          this.scene.start("AnsCorrectPlayScene", {
            avatar: this.avatar,
            round: this.round,
            score: this.score,
            questions: this.questions,
            isCorrectAnswer: this.isCorrectAnswer,
            friend: this.friend,
            bossRound: this.bossRound,
            noOfRounds: this.noOfRounds,
            forceFriend: this.forceFriend,
            difficulty: this.difficulty,
            countCorrect: this.countCorrect,
            avatarX: this.avatarX,
          });
        } else {
          this.scene.start("AnsWrongPlayScene", {
            avatar: this.avatar,
            round: this.round,
            score: this.score,
            questions: this.questions,
            isCorrectAnswer: this.isCorrectAnswer,
            friend: this.friend,
            bossRound: this.bossRound,
            noOfRounds: this.noOfRounds,
            forceFriend: this.forceFriend,
            difficulty: this.difficulty,
            countCorrect: this.countCorrect,
            avatarX: this.avatarX,
          });
        }
      },
      this
    );

    let y = -1;
    for (let i = 0; i < this.question.options.length; i++) {
      let x = 0;
      if (i % 2 == 0) {
        x = 20;
        y++;
      } else {
        x = 250;
      }
      let option = this.add
        .image(x, 560 + y * 80, "answerback")
        .setOrigin(0, 0.5)
        .setScale(1, 1.5);

      if (i18n.locale == "zh") {
        let word =
          String.fromCharCode(i + 65) + ") " + this.question.options[i].text;
        let maxWidth = 180;

        let text = this.add
          .text(x + 20, 560 + y * 80, "", {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 18,
            color: "#000",
          })
          .setOrigin(0, 0.5);

        let wrappedWord = "";
        let accumulatedWidth = 0;

        for (let i = 0; i < word.length; i++) {
          const letter = word[i];
          text.text = letter;
          const letterWidth = text.getBounds().width;

          accumulatedWidth += letterWidth;

          if (accumulatedWidth > maxWidth) {
            wrappedWord += "\n";
            accumulatedWidth = letterWidth;
          }

          wrappedWord += letter;
        }

        text.setText(wrappedWord);
      } else {
        this.add
          .text(
            x + 20,
            560 + y * 80,
            String.fromCharCode(i + 65) + ") " + this.question.options[i].text,
            {
              fontFamily: "Sassoon, sans-serif",
              wordWrap: { width: 180 },
              fontSize: 18,
              color: "#000",
            }
          )
          .setOrigin(0, 0.5);
      }

      option.isAnswer = this.question.options[i].isAnswer;
      option.setInteractive({ useHandCursor: true });

      if (option.isAnswer) {
        this.answer = this.add
          .image(x + 15, 545 + y * 80, "answer")
          .setScale(0.45);
        this.answer.visible = false;
      }

      option.on("pointerdown", () => {
        if (!this.isAnswered) {
          this.simpleClickSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.simpleClickSound.play();
          this.isAnswered = true;
          this.answer.setTint(option.isAnswer ? 0x00ff00 : 0xff0000);
          this.answer.visible = true;
          if (option.isAnswer) {
            this.countCorrect += 1;
            // this.score += this.questionScore;
            // this.scoreText.setText(
            //   i18n.t("miniGames.general.score") + " : " + this.score
            // );

            this.add.image(option.x + 180, option.y, "correct").setScale(0.5);
            this.okHamochi = this.add
              .image(380, 750, "hamochiCorrect")
              .setScale(0)
              .setDepth(2);
            this.isCorrectAnswer = true;
          } else {
            this.add.image(option.x + 180, option.y, "wrong").setScale(0.5);
            this.okHamochi = this.add
              .image(380, 750, "hamochiWrong")
              .setScale(0)
              .setDepth(2);
            this.isCorrectAnswer = false;
          }
          this.tweens.add({
            targets: [this.okBtn, this.okBtnTxt, this.okHamochi],
            scaleX: 0.6,
            scaleY: 0.6,
            duration: 200,
            ease: "Sine.easeOut",
            delay: 500,
            onStart: () => {
              if (this.isCorrectAnswer) {
                this.correctAnswerSound.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.correctAnswerSound.play();
              } else {
                this.wrongAnswerSound.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.wrongAnswerSound.play();
              }
            },
            // onComplete: () => {
            //   if (this.isCorrectAnswer) {
            //     this.speechText = this.add
            //       .text(
            //         360,
            //         680,
            //         i18n.t("miniGames.general.score") +
            //           " +" +
            //           this.questionScore,
            //         {
            //           fontFamily: "Sassoon, sans-serif",
            //           fontSize: 22,
            //           color: "#000",
            //         }
            //       )
            //       .setOrigin(0.5);

            //     this.tweens.add({
            //       targets: this.speechText,
            //       y: 600,
            //       alpha: 0,
            //       duration: 3000,
            //       ease: "Sine.easeOut",
            //       onStart: () => {
            //         this.scoreIncreaseSound.setVolume(
            //           store.state.settings.data.audio.sfx *
            //             store.state.settings.data.audio.master
            //         );
            //         this.scoreIncreaseSound.play();
            //       },
            //     });
            //   }
            // },
          });
        }
      });
    }

    function clickSetting() {
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "QuestionScene",
      });
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
    }

    this.events.on("pause", () => {
      this.q_dom.visible = false;
    });

    this.events.on("resume", () => {
      this.q_dom.visible = true;
    });
  }

  update() {
    //
  }
}

import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import router from "@/router";
import _ from "lodash";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.questions = data.questions;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  selectedOption = null;

  create() {
    this._backgroundImage = this.add
      .image(0, 0, "imgBackgroundX")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this._gameTitle = this.add.image(240, 320, "imgGameTitle").setScale(0.25);

    // if (
    //   store.state.settings.data.cache &&
    //   store.state.gameCache.data &&
    //   store.state.event.data.record.levelId == store.state.gameCache.data.level
    // ) {
    //   this._continueBtn = this.add
    //     .image(240, 450, "imgOptionBtn")
    //     .setScale(0.35);
    //   this._continueTxt = this.add.text(
    //     240,
    //     450,
    //     i18n.t("miniGames.general.resume"),
    //     {
    //       fontFamily: "Sassoon, sans-serif",
    //       fontSize: 20,
    //       color: "#000",
    //       align: "center",
    //     }
    //   );

    //   this._newBtn = this.add.image(240, 520, "imgOptionBtn").setScale(0.35);
    //   this._newTxt = this.add.text(
    //     240,
    //     520,
    //     i18n.t("miniGames.general.newGame"),
    //     {
    //       fontFamily: "Sassoon, sans-serif",
    //       fontSize: 20,
    //       color: "#000",
    //       align: "center",
    //     }
    //   );

    //   this.tweens.add({
    //     targets: this._continueBtn,
    //     scaleX: 0.38,
    //     scaleY: 0.38,
    //     ease: "Linear",
    //     duration: 500,
    //     yoyo: true,
    //     repeat: -1,
    //   });

    //   this.tweens.add({
    //     targets: this._continueTxt,
    //     scaleX: 1.1,
    //     scaleY: 1.1,
    //     ease: "Linear",
    //     duration: 500,
    //     yoyo: true,
    //     repeat: -1,
    //   });

    //   this.bgSound = this.sound.add("bgm");
    //   this.bgSound.setVolume(
    //     store.state.settings.data.audio.music *
    //       store.state.settings.data.audio.master
    //   );
    //   this.bgSound.loop = true;

    //   this._continueTxt.on(
    //     "pointerdown",
    //     () => {
    //       this._backgroundImage.visible = false;
    //       this._gameTitle.visible = false;
    //       this._continueBtn.visible = false;
    //       this._continueTxt.visible = false;
    //       this._newBtn.visible = false;
    //       this._newTxt.visible = false;
    //       this.bgSound.play();

    //       if (store.state.gameCache.data.isBossCollected) {
    //         let _event_data = _.cloneDeep(store.state.event.data);
    //         _event_data.boss.isCollected = true;
    //         store.commit("updateEvent", _event_data);
    //       }

    //       this.scene.launch("PlayScene", {
    //         forceFriend: this.selectedOption == 1 ? true : false,
    //         noOfRounds: this.noOfRounds,
    //         score: 0,
    //         round: 0,
    //         avatar: this.avatar,
    //         name: this.name,
    //         questions: this.questions,
    //         friend: this.friend,
    //         difficulty: this.difficulty,
    //       });
    //     },
    //     this
    //   );
    //   this._continueTxt.setOrigin(0.5);
    //   this._continueTxt.setPadding(10);
    //   this._continueTxt.setInteractive({ useHandCursor: true });
    //   this._continueTxt.setFixedSize(200, 40);

    //   this._newTxt.on(
    //     "pointerdown",
    //     () => {
    //       this._backgroundImage.visible = false;
    //       this._gameTitle.visible = false;
    //       this._continueBtn.visible = false;
    //       this._continueTxt.visible = false;
    //       this._newBtn.visible = false;
    //       this._newTxt.visible = false;
    //       this.bgSound.play();

    //       this.scene.launch("PlayScene", {
    //         forceFriend: this.selectedOption == 1 ? true : false,
    //         noOfRounds: this.noOfRounds,
    //         score: 0,
    //         round: 0,
    //         avatar: this.avatar,
    //         name: this.name,
    //         questions: this.questions,
    //         friend: this.friend,
    //         difficulty: this.difficulty,
    //       });
    //     },
    //     this
    //   );
    //   this._newTxt.setOrigin(0.5);
    //   this._newTxt.setPadding(10);
    //   this._newTxt.setInteractive({ useHandCursor: true });
    //   this._newTxt.setFixedSize(200, 40);
    // } else {
    this._continueBtn = this.add.image(240, 450, "imgOptionBtn").setScale(0.35);
    this._continueTxt = this.add.text(
      240,
      450,
      i18n.t("miniGames.general.continue"),
      {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
        align: "center",
      }
    );

    this.tweens.add({
      targets: this._continueBtn,
      scaleX: 0.38,
      scaleY: 0.38,
      ease: "Linear",
      duration: 500,
      yoyo: true,
      repeat: -1,
    });

    this.tweens.add({
      targets: this._continueTxt,
      scaleX: 1.1,
      scaleY: 1.1,
      ease: "Linear",
      duration: 500,
      yoyo: true,
      repeat: -1,
    });

    this.bgSound = this.sound.add("bgm");
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
    this.bgSound.loop = true;

    this._continueTxt.on(
      "pointerdown",
      () => {
        if (this.selectedOption == 2) {
          let score = Math.floor(Math.random() * (1000 - 900 + 1)) + 900;
          this.game_cache_data = _.cloneDeep(store.state.miniGame.data);
          this.game_cache_data.submission.countCorrect =
            Math.floor(Math.random() * (10 - 7 + 1)) + 7;
          this.game_cache_data.submission.accuracy = score / 10;
          this.game_cache_data.submission.star = 3;
          this.game_cache_data.submission.score = score;
          this.game_cache_data.submission.submission += 1;
          this.game_cache_data.boss.isCollected = true;
          this.game_cache_data.record.attempt += 1;
          this.game_cache_data.difficulty = this.difficulty;
          this.game_cache_data.record.submission += 1;
          this.game_cache_data.record.topScoreOld = this.game_cache_data.record.topScore;
          this.game_cache_data.record.topStarOld = this.game_cache_data.record.topStar;
          if (score > this.game_cache_data.record.topScore) {
            this.game_cache_data.record.topScore = score;
            this.game_cache_data.record.topStar = 3;
          }

          this.game_cache_data.gameLoot = Math.floor(
            Math.random() * (5 - 3 + 1) + 3
          );
          store.commit("updateEvent", this.game_cache_data);

          this.time.delayedCall(100, () => {
            router.push({
              name: "PageEventGameScore",
              params: {
                id: store.state.miniGame.data.record.levelId,
              },
            });
          });
        } else {
          this._backgroundImage.visible = false;
          this._gameTitle.visible = false;
          this._continueBtn.visible = false;
          this._continueTxt.visible = false;

          this.bgSound.play();

          this.scene.launch("PlayScene", {
            forceFriend: this.selectedOption == 1 ? true : false,
            noOfRounds: this.noOfRounds,
            score: 0,
            round: this.selectedOption == 3 || this.selectedOption == 4 ? 1 : 0,
            qCount: 0,
            avatar: this.avatar,
            name: this.name,
            questions: this.questions,
            friend: this.friend,
            difficulty: this.difficulty,
            countCorrect: 0,
            enemyCount:
              this.selectedOption == 3 ? 2 : this.selectedOption == 4 ? 1 : 3,
          });
        }
      },
      this
    );
    this._continueTxt
      .setOrigin(0.5)
      .setPadding(10)
      .setInteractive({ useHandCursor: true })
      .setFixedSize(200, 40);
    // }
  }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}

import { Scene } from "phaser";
import i18n from "@/plugins/i18n";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossRound = data.bossRound;
    this.bossImage = data.bossImage;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    // load images
    // general Images
    // buttons
    this.load.image("closeBtnImage", "/assets/common/btn-close.png");
    this.load.image("okBtnImage", "/assets/common/btn-ok.png");
    this.load.image("optionButton", "/assets/common/btn-option.png");
    this.load.image("setting", "/assets/common/btn-setting.png");
    this.load.image("round", "/assets/common/panel-round.png");
    this.load.image("settingPanel", "/assets/common/panel-setting.png");
    this.load.image("score", "/assets/common/panel-score.png");
    this.load.image("answerback", "/assets/common/panel-answer.png");
    this.load.image("answer", "/assets/common/answer-tag.png");
    this.load.image("hamochiCorrect", "/assets/common/answer-correct.png");
    this.load.image("hamochiWrong", "/assets/common/answer-wrong.png");
    this.load.image("correct", "/assets/common/mark-correct.png");
    this.load.image("wrong", "/assets/common/mark-wrong.png");
    this.load.image("excl", "/assets/common/mark-exclamation.png");
    this.load.image("speech", "/assets/common/speech.png");

    // images specific to the game
    this.load.image("backgroundImage", "/assets/foragingGame/background.png");
    this.load.image("gameTitleImage", "/assets/foragingGame/game-title.png");
    this.load.image("play", "/assets/foragingGame/btn-play.png");
    this.load.image("btnAnswer", "/assets/foragingGame/btn-answer.png");
    this.load.image("npc", "/assets/foragingGame/npcx.png");
    this.load.image("drum", "/assets/foragingGame/drum.png");
    this.load.image("hammer", "/assets/foragingGame/hammer.png");
    this.load.image("basket", "/assets/foragingGame/basket.png");
    this.load.image("basketEnergy", "/assets/foragingGame/basket-energy.png");
    this.load.image("leftArrowBtnImage", "/assets/foragingGame/arrow-left.png");
    this.load.image(
      "rightArrowBtnImage",
      "/assets/foragingGame/arrow-right.png"
    );
    this.load.image("fruitRed", "/assets/foragingGame/fruit-red.png");
    this.load.image("fruitGold", "/assets/foragingGame/fruit-gold.png");
    this.load.image("fruitBoss", this.bossImage);
    this.load.image("fruitGreen", "/assets/foragingGame/fruit-green.png");
    this.load.image("fruitRotten", "/assets/foragingGame/fruit-rotten.png");
    this.load.image("spikeBall", "/assets/foragingGame/spike-ball.png");
    this.load.image("caterpilla", "/assets/foragingGame/caterpilla.png");
    this.load.image("snake", "/assets/foragingGame/snake.png");
    this.load.image("hook", "/assets/foragingGame/hook.png");
    this.load.image("balloon", "/assets/foragingGame/balloon.png");
    this.load.image("bubble", "/assets/foragingGame/bubble.png");
    this.load.image("thief", "/assets/miningGame/boss/thief.png");
    this.load.image("evil", "/assets/miningGame/boss/evil.png");

    // load sprite sheets
    this.load.spritesheet("pickaxe", "/assets/miningGame/pickaxe.png", {
      frameWidth: 100,
      frameHeight: 100,
    });
    this.load.spritesheet(
      "pickaxeBoss",
      "/assets/miningGame/boss/pickaxe_boss.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );
    this.load.spritesheet("steel_tile", "/assets/miningGame/steel_sprite.png", {
      frameWidth: 100,
      frameHeight: 100,
    });
    this.load.spritesheet("stone_tile", "/assets/miningGame/stone_sprite.png", {
      frameWidth: 100,
      frameHeight: 100,
    });
    this.load.spritesheet("dirt_tile", "/assets/miningGame/dirt_sprite.png", {
      frameWidth: 100,
      frameHeight: 100,
    });
    this.load.spritesheet("boom", "/assets/miningGame/boom.png", {
      frameWidth: 100,
      frameHeight: 100,
    });
    this.load.spritesheet("stones", "/assets/miningGame/stonesanim.png", {
      frameWidth: 400,
      frameHeight: 400,
    });

    this.load.spritesheet("breaking", "/assets/miningGame/breaking.png", {
      frameWidth: 400,
      frameHeight: 400,
    });

    this.load.spritesheet(
      "explosion",
      "/assets/miningGame/explosion_sprite.png",
      {
        frameWidth: 500,
        frameHeight: 500,
      }
    );

    this.load.spritesheet(
      "smoke_bomb_sprite",
      "/assets/foragingGame/smoke_bomb_sprite.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "freeze_bomb_sprite",
      "/assets/miningGame/freeze_bomb_sprite.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "smoke_sprite",
      "/assets/miningGame/boss/smoke_sprite.png",
      {
        frameWidth: 500,
        frameHeight: 500,
      }
    );

    this.load.spritesheet(
      "spriteDrumStick",
      "/assets/foragingGame/sprite-drum-stick.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );
    this.load.spritesheet(
      "spriteDrumHammer",
      "/assets/foragingGame/sprite-drum-hammer.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );
    this.load.spritesheet(
      "spriteSplash",
      "/assets/foragingGame/sprite-splash.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );
    this.load.spritesheet("spriteHit", "/assets/foragingGame/sprite-hit.png", {
      frameWidth: 200,
      frameHeight: 200,
    });

    this.load.spritesheet(
      "spriteCatch",
      "/assets/foragingGame/sprite-catch.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );

    this.load.spritesheet(
      "spriteHammerThrow",
      "/assets/foragingGame/sprite-hammer-throw.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "spriteFruitShine",
      "/assets/foragingGame/sprite-shine.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "spriteSnake",
      "/assets/foragingGame/sprite-snake.png",
      {
        frameWidth: 50,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "spriteDizzy",
      "/assets/foragingGame/sprite-dizzy.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "spriteQuestion",
      "/assets/foragingGame/sprite-question.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );

    this.load.spritesheet(
      "spriteEnergy",
      "/assets/foragingGame/sprite-energy.png",
      {
        frameWidth: 300,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "spritePower",
      "/assets/foragingGame/sprite-power.png",
      {
        frameWidth: 300,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "spriteLand",
      "/assets/foragingGame/sprite-land.png",
      {
        frameWidth: 600,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "spriteJump",
      "/assets/foragingGame/sprite-jump.png",
      {
        frameWidth: 32,
        frameHeight: 32,
      }
    );

    this.load.spritesheet(
      "spriteRottenDestory",
      "/assets/foragingGame/rotten_sprite.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "spriteSnakeDestory",
      "/assets/foragingGame/snake_sprite.png",
      {
        frameWidth: 120,
        frameHeight: 200,
      }
    );

    // load audio files
    this.load.audio("digStone", "/assets/miningGame/sounds/tile_click.mp3");
    this.load.audio("digGem", "/assets/miningGame/sounds/gem_click.mp3");
    this.load.audio(
      "spillStones",
      "/assets/miningGame/sounds/stones_falling.mp3"
    );
    this.load.audio("gemSplash", "/assets/miningGame/sounds/gem_splash.mp3");
    this.load.audio("bombing", "/assets/miningGame/sounds/bomb.wav");
    this.load.audio("bombing_2", "/assets/miningGame/sounds/bomb_2.wav");
    this.load.audio("evil", "/assets/miningGame/sounds/evil.wav");
    this.load.audio("bombThrow", "/assets/miningGame/sounds/bomb_throw.wav");
    this.load.audio(
      "warningAlarm",
      "/assets/miningGame/sounds/warning_alarm.wav"
    );
    this.load.audio("smokeBombing", "/assets/miningGame/sounds/smoke_bomb.wav");
    this.load.audio(
      "correctAnswer",
      "/assets/miningGame/sounds/correct_answer.wav"
    );
    this.load.audio(
      "wrongAnswer",
      "/assets/miningGame/sounds/wrong_answer.wav"
    );
    this.load.audio(
      "scoreIncrease",
      "/assets/miningGame/sounds/score_increase.wav"
    );
    this.load.audio("collectGem", "/assets/miningGame/sounds/collect_gem.wav");
    this.load.audio("bombClick", "/assets/miningGame/sounds/bomb_click.wav");
    this.load.audio("startGame", "/assets/miningGame/sounds/start_game.wav");
    this.load.audio(
      "simpleClick",
      "/assets/miningGame/sounds/simple_click.wav"
    );
    this.load.audio("failure", "/assets/miningGame/sounds/failure.wav");
    this.load.audio("success", "/assets/miningGame/sounds/success.wav");
    this.load.audio("close", "/assets/miningGame/sounds/close.wav");
    this.load.audio("smoke", "/assets/miningGame/sounds/smoke.wav");
    this.load.audio("stealGem", "/assets/miningGame/sounds/steal_gem.wav");
    this.load.audio("round", "/assets/miningGame/sounds/round.wav");
    this.load.audio("enter", "/assets/miningGame/sounds/enter.wav");
    this.load.audio("timerGame", "/assets/miningGame/sounds/timer_game.wav");
    this.load.audio("timerBomb", "/assets/miningGame/sounds/timer_bomb.wav");
    this.load.audio("ready", "/assets/miningGame/sounds/ready_go.wav");
    this.load.audio("go", "/assets/miningGame/sounds/go.wav");
    this.load.audio("bossDrum", "/assets/miningGame/sounds/boss_drum.wav");
    this.load.audio(
      "bossWhistle",
      "/assets/miningGame/sounds/boss_whistle.wav"
    );
    this.load.audio("emoji", "/assets/miningGame/sounds/emoji.wav");
    this.load.audio("gemBlink", "/assets/miningGame/sounds/blink.wav");
    this.load.audio("gemLost", "/assets/miningGame/sounds/gem_lost.wav");
    this.load.audio("cleared", "/assets/miningGame/sounds/cleared.wav");
    this.load.audio("bg", "/assets/foragingGame/sounds/bg.mp3");
    this.load.audio("freeze", "/assets/miningGame/sounds/freeze.wav");

    // new game
    this.load.audio(
      "positiveHitSound",
      "/assets/foragingGame/sounds/fruit-collect.mp3"
    );
    this.load.audio(
      "rottenHitSound",
      "/assets/foragingGame/sounds/rotten-collect.mp3"
    );
    this.load.audio("spikeHitSound", "/assets/foragingGame/sounds/stun.wav");
    this.load.audio("drumHitSound", "/assets/foragingGame/sounds/drum-hit.mp3");
    this.load.audio(
      "fruitShineSound",
      "/assets/foragingGame/sounds/fruit-shine.wav"
    );
    this.load.audio(
      "avatarJumpSound",
      "/assets/foragingGame/sounds/avatar-jump.wav"
    );
    this.load.audio(
      "avatarGroundSound",
      "/assets/foragingGame/sounds/avatar-ground.mp3"
    );
    this.load.audio(
      "bossSuccessSound",
      "/assets/foragingGame/sounds/boss-success.wav"
    );
    this.load.audio(
      "bossFailureSound",
      "/assets/foragingGame/sounds/boss-failure.wav"
    );
    this.load.audio(
      "bossCatchSound",
      "/assets/foragingGame/sounds/boss-catch.wav"
    );
    this.load.audio(
      "bossSteelSound",
      "/assets/foragingGame/sounds/boss-steel.mp3"
    );
    this.load.audio("btnPopSound", "/assets/foragingGame/sounds/btn-pop.wav");
    this.load.audio(
      "powerPopSound",
      "/assets/foragingGame/sounds/power-pop.mp3"
    );
    this.load.audio(
      "powerHitSound",
      "/assets/foragingGame/sounds/power-hit.mp3"
    );
    this.load.audio("chargeSound", "/assets/foragingGame/sounds/charge.wav");
    this.load.audio(
      "hammerHitSound",
      "/assets/foragingGame/sounds/hammer-hit.wav"
    );
    this.load.audio(
      "hammerThrowSound",
      "/assets/foragingGame/sounds/hammer-throw.mp3"
    );
    // load avatar data
    for (var key of Object.keys(this.avatar)) {
      if (this.avatar[key] && key != "color") {
        this.load.image(key, this.avatar[key]);
      }
    }

    // load friend avatar data
    for (var _key of Object.keys(this.friend.avatar)) {
      if (this.friend.avatar[_key] && _key != "color") {
        this.load.image(_key, this.friend.avatar[_key]);
      }
    }

    // load avatar masks
    this.load.image("angry", "/assets/Mask/M0021.png");
    this.load.image("nice", "/assets/Mask/M0012.png");
    this.load.image("fear", "/assets/Mask/M0040.png");
    this.load.image("sad", "/assets/Mask/M0031.png");
    this.load.image("happy", "/assets/Mask/M0013.png");
    this.load.image("mad", "/assets/Mask/M0080.png");
    this.load.image("stun", "/assets/Mask/M0060.png");
    this.load.image("eek", "/assets/Mask/M0030.png");
    this.load.image("ziz", "/assets/Mask/M0050.png");
    this.load.image("push", "/assets/Mask/M0070.png");
  }

  create() {
    this.scene.start("LandingScene", {
      avatar: this.avatar,
      questions: this.questions,
      friend: this.friend,
      bossRound: this.bossRound,
      env: this.env,
      noOfRounds: this.noOfRounds,
      difficulty: this.difficulty,
    });
  }
}
<template>
  <v-app class="hmc-app">
    <v-main class="main-body">
      <v-container class="app-body">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="gameApi.isError" :api="gameApi" />
        <ErrorDialog
          v-if="error"
          :error="error"
          :message="errorMessage"
          @exitError="extiError"
        />
        <div class="pe-2 ps-3 app-title text-h5">
          Tower Climbing
          <v-spacer></v-spacer>
          <CloseBtn size="40" :callback="exit" />
        </div>
        <!-- EOC -->

        <div v-if="showFloor">
          <div class="mb-3">
            <div class="d-flex justify-space-between">
              <div class="d-flex" style="position: relative">
                <div class="ticket-card d-flex" v-if="game.currentFloor">
                  Floor {{ game.currentFloor }}
                </div>
              </div>

              <div class="align-center" style="z-index: 9">
                <div class="ticket-card d-flex">
                  <div class="text-h5 ps-2 game-title">{{ ladder }}/5</div>
                  <v-img
                    contain
                    width="30"
                    :src="require('@/assets/item/C000030.png')"
                  ></v-img>
                </div>
                <div class="ticket-card d-flex mt-2">
                  <div class="text-h5 ps-2 game-title pr-2">{{ blueStar }}</div>
                  <v-img
                    contain
                    width="30"
                    :src="require('@/assets/item/C000040.png')"
                  ></v-img>
                </div>
              </div>
            </div>
          </div>
          <div class="daily-game" v-if="game && game.currentTile">
            <div
              class="tile-end mx-auto d-flex align-center justify-center"
              :class="game.currentTile == 5 ? 'border-box' : ''"
              @click="game.currentTile == 5 ? selectEnd() : null"
            >
              <v-img
                width="20px"
                src="@/assets/dailyGame/arrow_right.png"
                class="arrow-right"
              />
              <v-img
                v-if="game.currentTile == 5"
                contain
                class="tile-img-end border-box"
                :src="require('@/assets/dailyGame/platform_big_001_border.png')"
              ></v-img>
              <v-img
                v-else
                contain
                class="tile-img-end border-box"
                :src="require('@/assets/dailyGame/platform_big_001.png')"
              ></v-img>

              <AbstractAvatar
                :avatar="auth.Player.avatar"
                :height="60"
                :width="60"
                class="avatar-ladder-end"
                v-if="showAvatar && end.hasLadder && !end.isCompleted"
              ></AbstractAvatar>

              <v-img
                src="@/assets/dailyGame/CB0010_hamochi_color_hamochi_wizard.png"
                class="wizard"
              />
              <!-- <span v-if="box.id == 1"></span>
                <span v-else-if="box.id == 6">Start</span> -->
            </div>

            <v-img
              width="20px"
              src="@/assets/item/C000030.png"
              alt="Ladder"
              class="ladder mx-auto d-flex align-center justify-center"
              v-if="end.hasLadder"
            />
            <v-img
              width="20px"
              src="@/assets/item/C000030.png"
              alt="Ladder"
              class="ladder mx-auto d-flex align-center justify-center"
              v-if="game.currentTile == 6"
            />
            <div
              class="box mx-auto d-flex align-center justify-center"
              :class="game.currentTile == 4 ? 'border-box' : ''"
              @click="game.currentTile == 4 ? selectMagician() : null"
            >
              <v-img
                v-if="game.currentTile == 4"
                contain
                class="tile-img"
                :src="require('@/assets/dailyGame/platform_001_border.png')"
              ></v-img>
              <v-img
                v-else
                contain
                class="tile-img"
                :src="require('@/assets/dailyGame/platform_001.png')"
              ></v-img>
              <AbstractAvatar
                :avatar="auth.Player.avatar"
                :height="60"
                :width="60"
                class="avatar-tile"
                v-if="
                  game.currentTile == 5 && wizard.isCompleted && !showAvatar
                "
              ></AbstractAvatar>
              <AbstractAvatar
                :avatar="auth.Player.avatar"
                :height="60"
                :width="60"
                class="avatar-ladder outer"
                v-if="showAvatar && wizard.hasLadder && !wizard.isCompleted"
              ></AbstractAvatar>
            </div>
            <div class="ladder-container text-center">
              <v-img
                width="20px"
                src="@/assets/item/C000030.png"
                alt="Ladder"
                class="ladder mx-auto d-flex align-center justify-center"
                v-if="wizard.hasLadder"
              />
              <div v-for="(question, index) in questions" :key="question">
                <div
                  class="box mx-auto d-flex align-center justify-center"
                  :class="game.currentTile - 1 == 2 - index ? 'border-box' : ''"
                  @click="
                    game.currentTile - 1 == 2 - index
                      ? selectQuestion(question)
                      : null
                  "
                >
                  <v-img
                    v-if="game.currentTile - 1 == 2 - index"
                    contain
                    class="tile-img"
                    :src="require('@/assets/dailyGame/platform_001_border.png')"
                  ></v-img>
                  <v-img
                    v-else
                    contain
                    class="tile-img"
                    :src="require('@/assets/dailyGame/platform_001.png')"
                  ></v-img>
                  <!-- <span v-if="box.id == 1"></span>
                <span v-else-if="box.id == 6">Start</span> -->
                  <AbstractAvatar
                    v-if="
                      game.currentTile - 2 == 2 - index &&
                      question.isCompleted &&
                      !showAvatar
                    "
                    :avatar="auth.Player.avatar"
                    :height="60"
                    :width="60"
                    class="avatar-tile"
                  ></AbstractAvatar>

                  <AbstractAvatar
                    :avatar="auth.Player.avatar"
                    :height="60"
                    :width="60"
                    class="avatar-ladder outer"
                    v-if="
                      showAvatar && question.hasLadder && !question.isCompleted
                    "
                  ></AbstractAvatar>
                </div>
                <v-img
                  v-if="question.hasLadder"
                  width="20px"
                  src="@/assets/item/C000030.png"
                  alt="Ladder"
                  :class="
                    question.hasLadder
                      ? 'ladder mx-auto d-flex align-center justify-center'
                      : 'ladder-none mx-auto d-flex align-center justify-center'
                  "
                />
              </div>
              <div class="tile-start mx-auto d-flex align-center">
                <!-- <span v-if="box.id == 1"></span>
                <span v-else-if="box.id == 6">Start</span> -->
                <v-img
                  width="20px"
                  src="@/assets/dailyGame/arrow_left.png"
                  class="arrow-left"
                />
                <v-img
                  contain
                  class="tile-img-start"
                  :src="require('@/assets/dailyGame/platform_big_001.png')"
                ></v-img>
                <AbstractAvatar
                  v-if="game.currentTile == 1 && !showAvatar"
                  :avatar="auth.Player.avatar"
                  :height="60"
                  :width="60"
                  class="avatar-tile-start"
                ></AbstractAvatar>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="showTileDetail">
          <TileDetail
            :tile="selectedQuestion"
            :ladder="ladder"
            :callbackStart="start"
          />
        </div>
        <div v-else-if="showMagician">
          <MagicianShop
            :blueStar="blueStar"
            :callbackClickOk="clickOk"
            :callbackClickSkip="selectNextFloor"
          />
        </div>
        <div v-else-if="showGameEnd">
          <GameEnd :callbackStart="endGame" />
        </div>
        <RewardDialog
          style="z-index: 9999 !important"
          v-if="!ladderResetApi.isLoading"
          :callbackClose="next"
          :showDialog="showReward"
          :rewards="rewarItems"
        />
        <LadderSpentDialog
          style="z-index: 9999 !important"
          v-if="showSpendDialog"
          :callbackClose="closeSpentDialog"
          :callbackOk="spendLadder"
        />
        <FloorDialog
          style="z-index: 9999 !important"
          v-if="showFloorDialog"
          :currentFloor="game.currentFloor"
        />
      </v-container>
    </v-main>
  </v-app>
</template>
    
    <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    TileDetail: () =>
      import(
        /* webpackChunkName: "component-tile-detail" */ "@/components/dailyGame/TileDetail"
      ),
    MagicianShop: () =>
      import(
        /* webpackChunkName: "component-magician-shop" */ "@/components/dailyGame/MagicianShop"
      ),
    GameEnd: () =>
      import(
        /* webpackChunkName: "component-game-end" */ "@/components/dailyGame/GameEnd"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/dailyGame/RewardDialog"
      ),
    LadderSpentDialog: () =>
      import(
        /* webpackChunkName: "component-ladder-spent" */ "@/components/dailyGame/LadderSpentDialog"
      ),
    FloorDialog: () =>
      import(
        /* webpackChunkName: "component-floor" */ "@/components/dailyGame/FloorDialog"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    boxes: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }],
    schoolYear: null,
    InventoryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    ladderApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    ladderResetApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameData: {},
    questions: [],
    wizard: {},
    end: {},
    game: {},
    blueStar: 0,
    ladder: 0,
    selectedQuestion: {},
    showFloor: true,
    showTileDetail: false,
    showMagician: false,
    showGameEnd: false,
    error: false,
    errorMessage: null,
    showReward: false,
    rewarItems: [],
    isResettable: false,
    showAvatar: false,
    showSpendDialog: false,
    showFloorDialog: false,
    isPlayed: false,
  }),
  created() {
    this.InventoryApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory/listByType";

    this.InventoryApi.callbackReset = () => {
      this.InventoryApi.isLoading = true;
      this.InventoryApi.isError = false;
    };

    this.InventoryApi.callbackError = (e) => {
      this.InventoryApi.isLoading = false;
      this.InventoryApi.isError = true;
      this.InventoryApi.error = e;
    };
    this.InventoryApi.callbackSuccess = (resp) => {
      console.log(resp);
      let blueStar = [];
      // let ladder = [];
      blueStar = this.$_.find(resp.Item, { key: "C000040" });
      // ladder = this.$_.find(resp.Item, { key: "C000030" });
      this.blueStar = blueStar.inventory;
      // this.ladder = ladder.inventory;
      this.InventoryApi.isLoading = false;
    };

    this.ladderApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/ladder/check";

    this.ladderApi.callbackReset = () => {
      this.ladderApi.isLoading = true;
      this.ladderApi.isError = false;
    };

    this.ladderApi.callbackError = (e) => {
      this.ladderApi.isLoading = false;
      this.ladderApi.isError = true;
      this.ladderApi.error = e;
    };
    this.ladderApi.callbackSuccess = (resp) => {
      console.log(resp);
      this.ladder = resp.Ladder.quantity;
      this.ladderApi.isLoading = false;
      this.isResettable = resp.TimestampReset
        ? this.$moment(resp.TimestampReset).isSame(this.$moment(), "day")
        : true;
      if (this.isResettable) {
        this.$api.fetch(this.ladderResetApi);
      } 
      if (this.showSpendDialog) {
        this.showSpendDialog = false;
        this.selectNextFloor();
      }
    };
    this.ladderResetApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/ladder/reset";

    this.ladderResetApi.callbackReset = () => {
      this.ladderResetApi.isLoading = true;
      this.ladderResetApi.isError = false;
    };

    this.ladderResetApi.callbackError = (e) => {
      this.ladderResetApi.isLoading = false;
      this.ladderResetApi.isError = true;
      this.ladderResetApi.error = e;
    };
    this.ladderResetApi.callbackSuccess = (resp) => {
      this.ladder = resp.Ladder.quantity;
      const reward = { Item: resp.Ladder };
      this.rewarItems.push(reward);
      this.ladderResetApi.isLoading = false;
      this.showReward = true;
    };

    this.gameApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/dailyGame/visit";

    this.gameApi.callbackReset = () => {
      this.gameApi.isLoading = true;
      this.gameApi.isError = false;
    };

    this.gameApi.callbackError = (e) => {
      this.gameApi.isLoading = false;
      this.gameApi.isError = true;
      this.gameApi.error = e;
    };
    this.gameApi.callbackSuccess = (resp) => {
      console.log(resp);
      this.questions = resp.Data.Question.reverse();
      this.wizard = resp.Data.Wizard;
      this.end = resp.Data.End;
      this.game = resp.Game;
      this.gameApi.isLoading = false;

      const isAnyQuestionNotCompleted = this.$_.some(this.questions, {
        isCompleted: false,
        hasLadder: true,
      });
      if(this.isPlayed){
        this.floorDialog(this.questions);
      }
      this.showAvatar = false;
      setTimeout(() => {
        this.showAvatar =
          isAnyQuestionNotCompleted ||
          (this.wizard.hasLadder && !this.wizard.isCompleted) ||
          (this.end.hasLadder && !this.end.isCompleted)
            ? true
            : false;
      }, "1000");
    };
    this.InventoryApi.params = {
      type: "currency",
    };
  },
  mounted() {
    if (this.auth.Classroom) {
      this.schoolYear =this.auth.Classroom.schoolYear;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
    }

    this.gameApi.params = {
      schoolYear: this.schoolYear,
    };
     
    this.$api.fetch(this.gameApi);
    this.$api.fetch(this.InventoryApi);
    this.$api.fetch(this.ladderApi);
   
  },

  methods: {
    floorDialog(questions) {
      const isAnyQuestionNotCompleted = this.$_.some(questions, {
        isCompleted: false,
        hasLadder: true,
      });
      if (this.game.currentTile == 1 && !isAnyQuestionNotCompleted) {
        setTimeout(() => {
          this.showFloorDialog = true;
        }, "1000");

        setTimeout(() => {
          this.showFloorDialog = false;
        }, "3000");
      }
    },
    extiError() {
      this.error = false;
    },
    endGame(star) {
      this.showFloor = true;
      this.blueStar = this.blueStar + star;
      if (star != 0) {
        this.gameApi.url =
          this.$api.servers.game +
          "/api/v1/" +
          this.$_getLocale() +
          "/game/dailyGame/move";
        this.$api.fetch(this.gameApi);
      }
    },
    start() {
      this.showTileDetail = false;
      this.showGameEnd = true;
    },
    clickOk(item) {
      this.blueStar = this.blueStar - item.price;
      this.showFloor = true;
      this.gameApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/dailyGame/move";
      this.$api.fetch(this.gameApi);
    },
    selectQuestion(item) {
      if (this.ladder >= 0) {
        if (item.hasLadder) {
          this.selectedQuestion = item;
          this.showFloor = false;
          this.showTileDetail = true;
        } else {
          this.showSpendDialog = true;
          // this.selectNextFloor();
          // item.hasLadder = true;
          // this.ladder = this.ladder - 1;
        }
      } else {
        this.errorMessage =
          "Not enough ladder. Come back and collect your new ladders tomorrow.";
        this.error = true;
      }
    },
    selectMagician() {
      if (this.ladder >= 0) {
        if (this.wizard.hasLadder) {
          this.showMagician = true;
          this.showFloor = false;
          this.showTileDetail = false;
        } else {
          this.showSpendDialog = true;
        }
      } else {
        this.errorMessage =
          "Not enough ladder. Come back and collect your new ladders tomorrow.";
        this.error = true;
      }
    },
    selectEnd() {
      if (this.ladder >= 0) {
        if (this.end.hasLadder) {
          this.showFloor = false;
          this.showTileDetail = false;
          this.selectNextFloor()
        } else {
          this.showSpendDialog = true;
        }
      } else {
        this.errorMessage =
          "Not enough ladder. Come back and collect your new ladders tomorrow.";
        this.error = true;
      }
    },
    selectNextFloor() {
      this.isPlayed = true
      if (this.ladder >= 0) {
        this.showFloor = true;
        this.gameApi.url =
          this.$api.servers.game +
          "/api/v1/" +
          this.$_getLocale() +
          "/game/dailyGame/move";
        this.$api.fetch(this.gameApi);
      } else {
        this.errorMessage =
          "Not enough ladder. Come back and collect your new ladders tomorrow.";
        this.error = true;
      }
    },

    exit() {
      if (this.showFloor) {
        this.$router.push({
          name: "GameTestingDailyGame",
        });
      }
      this.showFloor = true;
      this.showMagician = false;
      this.showTileDetail = false;
    },
    next() {
      this.showReward = false;
      this.$api.fetch(this.gameApi);
    },
    closeSpentDialog() {
      this.showSpendDialog = false;
    },
    spendLadder() {
      this.$api.fetch(this.ladderApi);
    },
  },
};
</script>

<style>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.tab-bar-card {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #e1c8aa;
  text-align: center;
  position: relative;
}

.hamochi {
  width: 70%;
  display: inline-flex;
  align-items: center;
}
.image {
  width: 25%;
  display: inline-block;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
  z-index: 9 !important;
}

.level-cart {
  padding: 5px;
  margin-bottom: 12px;
  width: calc((100% / 3) - 8px);
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.level-img {
  background-color: rgb(140, 219, 140);
}

.container-1x2 {
  width: 80px;
  height: 60px;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2; /* Placeholder color */
  animation: skeleton-loader 1s ease-in-out infinite alternate;
}
.box {
  text-align: center;
  width: 100px;
  height: 100px;
}

@keyframes resize {
  0% {
    width: 230px; /* Starting width */
  }
  50% {
    width: 250px; /* Maximum width */
  }
  100% {
    width: 230px; /* Return to original width */
  }
}

.border-box {
  /* animation: resize 2s infinite; */
  cursor: pointer !important;
}

.box-start {
  text-align: center;
  padding-bottom: 30px;
  width: 100px;
  height: 60px;
  overflow: hidden;
}

.box-end {
  text-align: center;
  width: 100px;
  overflow: hidden;
}

.tile-end {
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
  width: 250px;
}
.tile-start {
  text-align: center;
  width: 250px;
  height: 100px;
  overflow: hidden;
  padding-top: 210px;
}

.ladder-container {
  position: relative;
}
.avatar-ladder {
  margin-bottom: -40px;
  z-index: 99;
}
.avatar-ladder-end {
  margin-bottom: -30px;
  z-index: 9;
}
.avatar-tile {
  margin-bottom: 40px;
  z-index: 9 !important;
}
.avatar-tile-start {
  margin-bottom: 300px;
  margin-left: 95px;
  z-index: 99 !important;
  position: absolute;
}
.ladder {
  width: 50px !important;
  margin-top: -30px;
  margin-bottom: -30px;
  z-index: 8;
}

.ladder-none {
  width: 50px !important;
  margin-top: -30px;
  margin-bottom: -30px;
  visibility: hidden;
}

.wizard {
  width: 40px !important;
  position: absolute;
  z-index: 9;
  margin-left: 90px;
  margin-top: 70px;
}

.arrow-right {
  width: 60px !important;
  position: absolute;
  z-index: 9;
  margin-left: -150px;
  margin-top: -100px;
}

.arrow-left {
  width: 60px !important;
  position: absolute;
  z-index: 99;
  margin-left: 180px;
  margin-top: -370px;
}

.box-all {
  position: absolute;
  width: 100%;
  height: 13%;
}
.daily-game {
  width: 100%;
}
.tile-img {
  position: absolute;
  width: 150px;
}

.tile-img-end {
  position: absolute;
  width: 250px;
  margin-top: -300px;
  z-index: 0;
}

@keyframes skeleton-loader {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.3;
  }
}
</style>
import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import _ from "lodash";

export default class BossScene extends Scene {
  constructor() {
    super({ key: "BossScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.score = data.score;
    this.round = data.round;
    this.questions = data.questions;
    this.bossRound = data.bossRound;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.isBossRound = data.round == data.bossRound && data.isCorrectAnswer;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.avatarX = data.avatarX;
  }

  avatarObject = [];
  friendAvatarObject = [];
  fallingFruitsGroup;
  TIMER_VALUE = 10000;
  isGameOngoing = true;
  isCollisionEnabled = true;
  spikeBallMissCount = 0;
  canMove = false;
  spawnSpikeBallCount = 0;
  wave = 0;
  tatalWaves = 0;

  create() {
    this.tatalWaves = this.difficulty == 1 ? 4 : this.difficulty == 2 ? 5 : 6;
    this.bossScore = Math.floor(Math.random() * (50 - 30 + 1)) + 30;
    // Background
    this.backgroundImage = this.add
      .image(0, 0, "backgroundImage")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this.scoreBoard = this.add.image(80, 35, "score").setScale(0.3).setDepth(2);
    this.resultBoard = this.add.image(70, 70, "score").setScale(0.25, 0.2);
    this.settingBtn = this.add
      .image(440, 40, "setting")
      .setScale(0.6)
      .setDepth(2)
      .on("pointerdown", this.clickSetting, this)
      .setInteractive({ useHandCursor: true });
    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(2);

    this.roundText = this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5)
      .setDepth(2);

    this.feedback = this.add
      .text(240, 390, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(3, 3, "#000", 0, true, true);

    this.warningBackground = this.add
      .rectangle(240, 390, 480, 200, 0xff0000)
      .setDepth(4)
      .setAlpha(0);
    this.warningText = this.add
      .text(240, 370, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(3, 3, "#000", 0, true, true);

    this.warningMessageText = this.add
      .text(240, 420, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 28,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(1.5, 1.5, "#000", 0, true, true);

    // Boss
    this.thief = this.add.image(245, 245, "thief").setScale(-0.25, 0.25);
    this.thief.visible = false;
    this.thiefSmoke = this.add
      .sprite(245, 245, "smoke_sprite")
      .setScale(0.5)
      .setDepth(5)
      .setAlpha(0);
    this.bomb = this.add.sprite(245, 245, "smoke_bomb_sprite").setScale(0);
    this.bossSpeech = this.add.image(250, 190, "speech").setScale(0.0);
    this.bossMark = this.add.image(250, 190, "excl").setScale(0.0);
    this.bossEvil = this.add.image(250, 190, "evil").setScale(0.0);
    this.hammerThrow = this.add
      .sprite(90, 580, "spriteHammerThrow")
      .setScale(0.7)
      .setAlpha(0);
    this.boom = this.add
      .sprite(this.thief.x, this.thief.y, "boom")
      .setScale(0.9)
      .setFrame(0);

    this.anims.create({
      key: "smoking",
      frames: this.anims.generateFrameNumbers("smoke_sprite", {
        frames: [3, 4, 5, 4, 3, 4, 5, 6, 7],
      }),
      frameRate: 5,
    });

    // NPC and Drum
    this.npc = this.add.image(60, 630, "npc").setScale(0.5);
    this.drum = this.add.image(130, 560, "drum").setScale(0.4);

    // Animations
    this.anims.create({
      key: "thief_smoke",
      frames: this.anims.generateFrameNumbers("smoke_sprite", {
        frames: [3, 4, 5, 4, 3, 4, 5, 6, 7],
      }),
      frameRate: 5,
    });
    this.anims.create({
      key: "Hammerthrow",
      frames: this.anims.generateFrameNumbers("spriteHammerThrow", {
        frames: [1, 2, 3, 0],
      }),
      repeat: -1,
      frameRate: 20,
    });
    this.anims.create({
      key: "hit",
      frames: this.anims.generateFrameNumbers("boom", {
        frames: [1, 2, 3, 2, 1, 0],
      }),
      frameRate: 15,
    });

    // Sounds
    this.goSound = this.sound.add("go");
    this.evilSound = this.sound.add("evil");
    this.warningAlarmSound = this.sound.add("warningAlarm");
    this.warningAlarmSound.loop = true;
    this.simpleClickSound = this.sound.add("simpleClick");
    this.somkeSound = this.sound.add("smoke");
    this.bossRoundSound = this.sound.add("bossWhistle");
    this.hammerThrowSound = this.sound.add("hammerThrowSound");
    this.hammerHitSound = this.sound.add("hammerHitSound");
    this.bossFailureSound = this.sound.add("bossFailureSound");
    this.bossSuccessSound = this.sound.add("bossSuccessSound");
    this.bossSteelSound = this.sound.add("bossSteelSound");

    // Avatar and Basket
    this.basket = this.physics.add
      .sprite(this.avatarX, 610, "basket")
      .setScale(0.4)
      .setDepth(2);
    this.basketTop = this.add
      .rectangle(0, 0, 75, 5, 0xff0000)
      .setAlpha(0)
      .setDepth(2);
    this.physics.world.enable(this.basketTop);
    this.basketTop.body.setCollideWorldBounds(true);

    // avatar
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setDepth(2)
              .setScale(0.25)
              .setCollideWorldBounds(true)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setDepth(2)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setDepth(2)
              .setScale(0.25)
              .setCollideWorldBounds(true)
          );
        }
      }
    }
    this.tweens.add({
      targets: [this.basket],
      y: 600,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });
    this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: 655,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    // User control
    this.leftArrowBtn = this.add
      .image(120, 775, "leftArrowBtnImage")
      .setScale(0.28)
      .on("pointerdown", this.startMovingLeft, this)
      .on("pointerup", this.stopMoving, this)
      .setDepth(2);

    this.rightArrowBtn = this.add
      .image(360, 775, "rightArrowBtnImage")
      .setScale(0.28)
      .on("pointerdown", this.startMovingRight, this)
      .on("pointerup", this.stopMoving, this)
      .setDepth(2);

    this.input.keyboard.on("keydown-LEFT", this.startMovingLeft, this);
    this.input.keyboard.on("keydown-RIGHT", this.startMovingRight, this);
    this.input.keyboard.on("keyup-LEFT", this.stopMoving, this);
    this.input.keyboard.on("keyup-RIGHT", this.stopMoving, this);

    this.fallingFruitsGroup = this.add.group();

    // Game transitions starts here
    this.feedback.setText(i18n.t("miniGames.general.ready"));
    this.feedback.setTint(0xffffff);
    this.time.delayedCall(
      500,
      () => {
        this.warningAlarmSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.warningAlarmSound.play();
        this.warningText.setText(i18n.t("miniGames.foraging.warning"));
        this.warningText.setTint(0xffffff);
        this.warningMessageText.setText(
          i18n.t("miniGames.foraging.warningMsg")
        );
        this.warningMessageText.setTint(0xffffff);
        this.tweens.add({
          targets: this.warningBackground,
          alpha: 0.5,
          duration: 200,
          ease: "Sine.easeInOut",
        });
        this.tweens.add({
          targets: [this.warningText, this.warningMessageText],
          scaleY: 1,
          scaleX: 1,
          duration: 200,
          ease: "Sine.easeInOut",
          onComplete: () => {
            this.tweens.add({
              targets: this.warningText,
              alpha: 0,
              repeat: 3,
              yoyo: true,
              duration: 200,
              ease: "Sine.easeInOut",
              onComplete: () => {
                this.warningAlarmSound.stop();
                this.tweens.add({
                  targets: [this.warningText, this.warningMessageText],
                  scaleY: 0,
                  scaleX: 0,
                  duration: 200,
                  delay: 1000,
                  ease: "Sine.easeInOut",
                });
                this.tweens.add({
                  targets: [this.warningBackground],
                  alpha: 0,
                  duration: 200,
                  delay: 1000,
                  ease: "Sine.easeInOut",
                });

                this.tweens.add({
                  targets: this.thiefSmoke,
                  alpha: 1,
                  duration: 200,
                  delay: 1000,
                  ease: "Sine.easeInOut",
                  onStart: () => {
                    this.tweens.add({
                      targets: [this.avatarMark, this.avatarSpeech],
                      scaleX: 0.25,
                      scaleY: 0.25,
                      duration: 300,
                      ease: "Sine.easeOut",
                      onComplete: () => {
                        this.tweens.add({
                          targets: [this.avatarMark, this.avatarSpeech],
                          scaleX: 0,
                          scaleY: 0,
                          duration: 100,
                          delay: 2000,
                          ease: "Sine.easeOut",
                          onComplete: () => {},
                        });
                      },
                    });
                  },
                  onComplete: () => {
                    this.thiefSmoke.play("thief_smoke");
                    this.somkeSound.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    this.somkeSound.play();
                    if (this.avatarMask) {
                      this.avatarMask.destroy();
                    }
                    this.avatarMask = this.add
                      .image(100, 720, "fear")
                      .setScale(0.25)
                      .setDepth(5);
                    this.time.delayedCall(1500, () => {
                      this.thief.visible = true;
                      this.time.delayedCall(500, () => {
                        this.evilSound.setVolume(
                          store.state.settings.data.audio.sfx *
                            store.state.settings.data.audio.master
                        );
                        this.evilSound.play();
                        this.npcMask = this.add
                          .image(60, 615, "fear")
                          .setScale(0.25);
                        this.tweens.add({
                          targets: [this.bossEvil, this.bossSpeech],
                          scaleX: 0.3,
                          scaleY: 0.3,
                          duration: 100,
                          ease: "Sine.easeOut",
                          onComplete: () => {
                            this.tweens.add({
                              targets: [
                                this.bossEvil,
                                this.bossSpeech,
                                this.avatarMask,
                              ],
                              scaleX: 0,
                              scaleY: 0,
                              duration: 100,
                              delay: 1000,
                              ease: "Sine.easeOut",
                              onComplete: () => {
                                this.bossRoundSound.setVolume(
                                  store.state.settings.data.audio.sfx *
                                    store.state.settings.data.audio.master
                                );
                                this.bossRoundSound.play();
                                this.tweens.add({
                                  targets: [this.bomb],
                                  scaleX: 1,
                                  scaleY: 1,
                                  y: -100,
                                  x: this.thief.x,
                                  duration: 1000,
                                  ease: "Sine.easeOut",
                                  onComplete: () => {
                                    this.cameras.main.shake(500);
                                    this.time.delayedCall(500, () => {
                                      this.tweens.add({
                                        targets: this.feedback,
                                        scaleX: 1.5,
                                        scaleY: 1.5,
                                        duration: 200,
                                        ease: "Sine.easeInOut",
                                        onComplete: () => {
                                          this.tweens.add({
                                            targets: this.feedback,
                                            scaleX: 0,
                                            scaleY: 0,
                                            duration: 100,
                                            ease: "Sine.easeInOut",
                                            delay: 1000,
                                            onComplete: () => {
                                              this.feedback.setText(
                                                i18n.t("miniGames.general.go")
                                              );
                                              this.feedback.setTint(0xffffff);
                                              this.tweens.add({
                                                targets: this.feedback,
                                                scaleX: 1.5,
                                                scaleY: 1.5,
                                                duration: 200,
                                                ease: "Sine.easeInOut",
                                                delay: 500,
                                                onComplete: () => {
                                                  this.tweens.add({
                                                    targets: this.feedback,
                                                    scaleX: 0,
                                                    scaleY: 0,
                                                    duration: 100,
                                                    ease: "Sine.easeInOut",
                                                    delay: 1000,
                                                    onStart: () => {
                                                      this.goSound.setVolume(
                                                        store.state.settings
                                                          .data.audio.sfx *
                                                          store.state.settings
                                                            .data.audio.master
                                                      );
                                                      this.goSound.play();
                                                    },
                                                    onComplete: () => {
                                                      this.leftArrowBtn.setInteractive(
                                                        { useHandCursor: true }
                                                      );
                                                      this.rightArrowBtn.setInteractive(
                                                        { useHandCursor: true }
                                                      );
                                                      this.canMove = true;
                                                      this.startSpawn = true;
                                                    },
                                                  });
                                                },
                                              });
                                            },
                                          });
                                        },
                                      });
                                    });
                                  },
                                });
                              },
                            });
                          },
                        });
                      });
                    });
                  },
                });
              },
            });
          },
        });
      },
      [],
      this
    );

    this.physics.add.collider(
      this.basketTop,
      this.fallingFruitsGroup,
      this.handleCollision,
      null,
      this
    );
  }

  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "BossScene",
    });
  }

  startMovingLeft() {
    this.stopMoving();
    if (this.canMove) {
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(-300);
      }
    }
  }

  startMovingRight() {
    this.stopMoving();
    if (this.canMove) {
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(300);
      }
    }
  }

  stopMoving() {
    for (var i = 0; i < this.avatarObject.length; i++) {
      this.avatarObject[i].setVelocityX(0);
    }
  }

  handleCollision(x, fallingImage) {
    if (this.isCollisionEnabled) {
      this.isGameOngoing = false;
      fallingImage.destroy();
      this.stopMoving();
      this.canMove = false;
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(0);
      }
      this.thiefSmoke.x = this.basket.x;
      this.thiefSmoke.y = this.basket.y;
      this.thiefSmoke.play("thief_smoke");
      this.somkeSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.somkeSound.play();
      this.time.removeAllEvents();
      this.bossFruit = this.physics.add
        .sprite(this.basket.x, this.basket.y - 60, "fruitBoss")
        .setScale(0.3);
      if (this.avatarMask) {
        this.avatarMask.destroy();
      }
      this.avatarMask = this.add
        .image(100, 720, "stun")
        .setScale(0.25)
        .setDepth(5);
      this.time.delayedCall(2000, () => {
        // Create the line
        this.hook = this.add
          .image(this.thief.x + 50, this.thief.y, "hook")
          .setScale(0.2);
        this.hookLine = new Phaser.Geom.Line(
          this.thief.x + 48,
          this.thief.y + 12,
          this.thief.x + 48,
          this.thief.y + 12
        );
        this.hookGraphics = this.add.graphics({
          lineStyle: { width: 3, color: 0x000000 },
        });
        this.hookGraphics.strokeLineShape(this.hookLine);

        // Create the tween
        this.tween = this.tweens.add({
          targets: this.hookLine,
          x2: this.bossFruit.x,
          y2: this.bossFruit.y,
          duration: 1000,
          ease: "Sine.easeInOut",
          yoyo: true,
          onStart: () => {
            this.bossSteelSound.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.bossSteelSound.play();
            this.time.delayedCall(1000, () => {
              this.tweens.add({
                targets: this.bossFruit,
                x: this.thief.x + 48,
                y: this.thief.y + 12,
                duration: 1000,
                ease: "Sine.easeInOut",
                onComplete: () => {
                  this.time.delayedCall(1500, () => {
                    this.thiefSmoke.x = this.thief.x;
                    this.thiefSmoke.y = this.thief.y;
                    this.thiefSmoke.play("thief_smoke");
                    this.somkeSound.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );

                    this.somkeSound.play();
                  });
                  this.time.delayedCall(2000, () => {
                    this.thief.visible = false;
                    this.bossFruit.visible = false;
                    this.hook.visible = false;
                  });
                  this.time.delayedCall(4000, () => {
                    this.thiefSmoke.visible = false;
                    if (this.warningBackground) {
                      this.warningBackground.destroy();
                    }
                    this.warningBackground = this.add
                      .rectangle(240, 390, 480, 200, 0xffffff)
                      .setDepth(4)
                      .setAlpha(0);
                    this.warningText.setText(
                      i18n.t("miniGames.foraging.failed")
                    );
                    this.warningText.setTint(0xfa3838);
                    this.warningMessageText.setText(
                      i18n.t("miniGames.foraging.failedMsg")
                    );
                    this.warningMessageText.setTint(0xfa3838);
                    this.tweens.add({
                      targets: this.warningBackground,
                      alpha: 0.5,
                      duration: 200,
                      ease: "Sine.easeInOut",
                      onStart: () => {
                        this.bossFailureSound.setVolume(
                          store.state.settings.data.audio.sfx *
                            store.state.settings.data.audio.master
                        );
                        this.bossFailureSound.play();
                      },
                    });
                    this.tweens.add({
                      targets: [this.warningText, this.warningMessageText],
                      scaleY: 1,
                      scaleX: 1,
                      duration: 200,
                      ease: "Sine.easeInOut",
                      onComplete: () => {
                        this.tweens.add({
                          targets: [
                            this.warningText,
                            this.warningMessageText,
                            this.warningBackground,
                          ],
                          scaleY: 0,
                          scaleX: 0,
                          delay: 2000,
                          duration: 200,
                          ease: "Sine.easeInOut",
                          onComplete: () => {
                            this.gameFinish();
                          },
                        });
                      },
                    });
                  });
                },
              });
            });
          },
        });
      });
      this.isCollisionEnabled = false;
    }
  }

  update() {
    if (this.startSpawn && this.wave < this.tatalWaves) {
      this.wave++;
      if (this.wave % 2 == 1) {
        this.startingPosition = this.bomb_pattern_0(this.wave);
      } else {
        this.bomb_pattern_4X0(this.startingPosition, this.wave);
      }
      this.startSpawn = false;
    }

    if (
      this.fallingFruitsGroup
        .getChildren()
        .some(
          (fallingImage) =>
            fallingImage.wave == this.wave && fallingImage.y >= 250
        )
    ) {
      this.startSpawn = true;
    }

    if (this.hookGraphics && this.hookLine && this.hook) {
      this.hookGraphics.clear();
      this.hookGraphics.strokeLineShape(this.hookLine);
      this.hook.x = this.hookLine.x2;
      this.hook.y = this.hookLine.y2 + 20;
    }

    if (this.avatarObject[0]) {
      this.basket.x = this.avatarObject[0].x;
    }
    if (this.avatarMask && this.avatarObject[0]) {
      this.avatarMask.x = this.avatarObject[0].x;
      this.avatarMask.y = this.avatarObject[0].y - 1.5;
    }
    if (this.basket) {
      this.basketTop.x = this.basket.x;
      this.basketTop.y = this.basket.y - 40;
    }

    if (!this.isGameOngoing) {
      this.fallingFruitsGroup.getChildren().forEach(function (fallingImage) {
        fallingImage.destroy();
      }, this);
      this.time.removeEvent(this.spawnEvent);
      this.time.removeEvent(this.engine);
    }

    this.fallingFruitsGroup.getChildren().forEach(function (fallingImage) {
      if (fallingImage.y >= 710) {
        if (fallingImage.isActive) {
          fallingImage.isActive = false;
          this.spikeBallMissCount++;
          if (
            this.spikeBallMissCount == this.spawnSpikeBallCount &&
            this.isGameOngoing
          ) {
            this.isGameOngoing = false;
            this.bossWin();
          }

          let xSprite = this.add
            .sprite(fallingImage.x, fallingImage.y, "smoke_sprite")
            .setScale(0.2)
            .setDepth(1)
            .play("smoking")
            .on("animationcomplete", () => {
              xSprite.destroy();
            });
          fallingImage.destroy();
        }
      } else {
        fallingImage.y += 4;
      }
    }, this);
  }

  bossWin() {
    this.stopMoving();
    this.canMove = false;
    this.event_data = _.cloneDeep(store.state.event.data);
    this.event_data.boss.isCollected = true;
    store.commit("updateEvent", this.event_data);
    for (var i = 0; i < this.avatarObject.length; i++) {
      this.avatarObject[i].setVelocityX(0);
    }
    if (this.avatarMask) {
      this.avatarMask.destroy();
    }
    this.time.delayedCall(1000, () => {
      this.tweens.add({
        onStart: () => {
          if (this.avatarMask) {
            this.avatarMask.destroy();
          }
          this.avatarMask = this.add
            .image(100, 720, "happy")
            .setScale(0.3)
            .setDepth(5);
          if (this.npcMask) {
            this.npcMask.destroy();
          }
          this.npcMask = this.add.image(60, 615, "happy").setScale(0.25);
          this.hammerThrow.setAlpha(1);
          this.hammerThrow.play("Hammerthrow");
          this.hammerThrow.setDepth(99);
          this.hammerThrowSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.hammerThrowSound.play();
        },
        targets: this.hammerThrow,
        x: this.thief.x,
        y: this.thief.y,
        duration: 500,
        ease: "Sine.easeInOut",
        onComplete: () => {
          this.score += this.bossScore;
          this.scoreText.setText(
            i18n.t("miniGames.general.score") + " : " + this.score
          );
          this.hammerHitSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.hammerHitSound.play();
          this.hammerThrow.destroy();
          this.boom.setDepth(99);
          this.boom.play("hit");
          if (this.thiefMask) {
            this.thiefMask.destroy();
          }
          this.thiefMask = this.add
            .image(this.thief.x, this.thief.y, "stun")
            .setScale(-0.25, 0.25)
            .setDepth(5);

          this.time.delayedCall(2000, () => {
            this.thiefSmoke.x = this.thief.x;
            this.thiefSmoke.y = this.thief.y;
            this.thiefSmoke.play("thief_smoke");
            this.somkeSound.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.somkeSound.play();
            if (this.thiefMask) {
              this.thiefMask.destroy();
            }
            this.time.delayedCall(1000, () => {
              this.thief.visible = false;
            });
          });
        },
      });
      this.time.delayedCall(4000, () => {
        this.thiefSmoke.visible = false;
        if (this.warningBackground) {
          this.warningBackground.destroy();
        }
        this.warningBackground = this.add
          .rectangle(240, 390, 480, 200, 0xffffff)
          .setDepth(4)
          .setAlpha(0);
        this.warningText.setText(i18n.t("miniGames.foraging.success"));
        this.warningText.setTint(0x24a062);
        this.warningMessageText.setText(
          i18n.t("miniGames.foraging.successMsg")
        );
        this.warningMessageText.setTint(0x24a062);
        this.tweens.add({
          targets: this.warningBackground,
          alpha: 0.5,
          duration: 200,
          ease: "Sine.easeInOut",
          onStart: () => {
            this.bossSuccessSound.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.bossSuccessSound.play();
          },
        });
        this.tweens.add({
          targets: [this.warningMessageText, this.warningText],
          scaleY: 1,
          scaleX: 1,
          duration: 200,
          ease: "Sine.easeInOut",
          onComplete: () => {
            this.tweens.add({
              targets: [
                this.warningText,
                this.warningMessageText,
                this.warningBackground,
              ],
              scaleY: 0,
              scaleX: 0,
              delay: 2000,
              duration: 200,
              ease: "Sine.easeInOut",
              onComplete: () => {
                this.gameFinish();
              },
            });
          },
        });
      });
    });
  }

  gameFinish() {
    if (this.avatarMask) {
      this.avatarMask.visible = false;
    }
    this.time.delayedCall(1000, () => {
      if (this.round != this.noOfRounds) {
        let avatarX = this.avatarObject[0].x;
        this.avatarObject = [];
        this.friendAvatarObject = [];
        this.fallingFruitsGroup;
        this.canMove = false;
        this.TIMER_VALUE = 12000;
        this.isGameOngoing = true;
        this.spikeBallMissCount = 0;
        this.isCollisionEnabled = true;
        this.scene.start("PlayScene", {
          avatar: this.avatar,
          round: this.round + 1,
          score: this.score,
          questions: this.questions,
          friend: this.friend,
          bossRound: this.bossRound,
          noOfRounds: this.noOfRounds,
          difficulty: this.difficulty,
          countCorrect: this.countCorrect,
          avatarX: avatarX,
        });
      } else {
        let star;
        this.event_data = _.cloneDeep(store.state.event.data);
        this.score += (this.difficulty - 1) * 50;
        if (this.score >= 600 && this.score < 750) {
          star = 1;
        } else if (this.score >= 750 && this.score < 900) {
          star = 2;
        } else if (this.score >= 900) {
          star = 3;
        } else {
          star = 0;
        }

        this.event_data.submission.countCorrect = this.countCorrect;
        this.event_data.submission.accuracy = this.score / 10;
        this.event_data.submission.star = star;
        this.event_data.submission.score = this.score;
        this.event_data.submission.submission += 1;

        this.event_data.record.attempt += 1;
        this.event_data.record.submission += 1;
        this.event_data.record.topScoreOld = this.event_data.record.topScore;
        this.event_data.record.topStarOld = this.event_data.record.topStar;
        if (this.score > this.event_data.record.topScore) {
          this.event_data.record.topScore = this.score;
          this.event_data.record.topStar = star;
        }

        this.event_data.gameLoot = Math.floor(Math.random() * (5 - 3 + 1) + 3);
        this.event_data.difficulty = this.difficulty;
        store.commit("updateEvent", this.event_data);

        let game_cache_data = _.cloneDeep(store.state.event.data);
        game_cache_data.round = this.round;
        game_cache_data.score = this.score;
        game_cache_data.countCorrect = this.countCorrect;
        
        store.commit("updateMiniGame", game_cache_data);

        this.time.delayedCall(1000, () => {
          router.push({
            name: "PageExploreDailyGameScore",
            params: {
              id: store.state.event.data.record.levelId,
            },
          });
        });
      }
    });
  }

  spawnSpikeBall(x, wave) {
    this.spawnSpikeBallCount++;
    const fallingImage = this.physics.add
      .sprite(x, -50, "smoke_bomb_sprite")
      .setDepth(1)
      .setScale(0.6);

    fallingImage.type = "spikeBall";
    fallingImage.score = -1;
    fallingImage.isActive = true;
    fallingImage.wave = wave;
    this.fallingFruitsGroup.add(fallingImage);
  }

  bomb_pattern_0(wave) {
    const randomNum = Math.floor(Math.random() * 5) + 1;
    const startingPosition = 50 * randomNum + 40;

    for (let index = 40; index < 450; index += 50) {
      if (
        index != startingPosition &&
        index != startingPosition + 50 &&
        index != startingPosition + 100
      ) {
        this.spawnEvent = this.time.addEvent({
          callback: () => {
            this.spawnSpikeBall(index, wave);
          },
          callbackScope: this,
        });
      }
    }
    return startingPosition;
  }

  bomb_pattern_4X0(startingPosition, wave) {
    for (
      let index = startingPosition;
      index < startingPosition + 160;
      index += 50
    ) {
      this.spawnEvent = this.time.addEvent({
        callback: () => {
          this.spawnSpikeBall(index, wave);
        },
        callbackScope: this,
      });
    }
  }

  bomb_pattern_4X1() {
    const randomNum = 4;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 250,
      callback: () => {
        this.spawnSpikeBall(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 250,
          callback: () => {
            this.spawnSpikeBall(startingPosition + 50);
            this.spawnEvent = this.time.addEvent({
              delay: 250,
              callback: () => {
                this.spawnSpikeBall(startingPosition + 100);
                this.spawnEvent = this.time.addEvent({
                  delay: 250,
                  callback: () => {
                    this.spawnSpikeBall(startingPosition + 150);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }
  bomb_pattern_4X2() {
    const randomNum = 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 250,
      callback: () => {
        this.spawnSpikeBall(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 250,
          callback: () => {
            this.spawnSpikeBall(startingPosition + 50);
            this.spawnEvent = this.time.addEvent({
              delay: 250,
              callback: () => {
                this.spawnSpikeBall(startingPosition + 100);
                this.spawnEvent = this.time.addEvent({
                  delay: 250,
                  callback: () => {
                    this.spawnSpikeBall(startingPosition + 150);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }
  bomb_pattern_4X3() {
    const randomNum = 5;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 250,
      callback: () => {
        this.spawnSpikeBall(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 250,
          callback: () => {
            this.spawnSpikeBall(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 250,
              callback: () => {
                this.spawnSpikeBall(startingPosition - 100);
                this.spawnEvent = this.time.addEvent({
                  delay: 250,
                  callback: () => {
                    this.spawnSpikeBall(startingPosition - 150);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }
  bomb_pattern_4X4() {
    const randomNum = 7;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 250,
      callback: () => {
        this.spawnSpikeBall(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 250,
          callback: () => {
            this.spawnSpikeBall(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 250,
              callback: () => {
                this.spawnSpikeBall(startingPosition - 100);
                this.spawnEvent = this.time.addEvent({
                  delay: 250,
                  callback: () => {
                    this.spawnSpikeBall(startingPosition - 150);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }
  bomb_pattern_4X5() {
    const randomNum = 5;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 250,
      callback: () => {
        this.spawnSpikeBall(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 250,
          callback: () => {
            this.spawnSpikeBall(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 250,
              callback: () => {
                this.spawnSpikeBall(startingPosition);
                this.spawnEvent = this.time.addEvent({
                  delay: 250,
                  callback: () => {
                    this.spawnSpikeBall(startingPosition - 50);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }
  bomb_pattern_4X6() {
    const randomNum = 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 250,
      callback: () => {
        this.spawnSpikeBall(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 250,
          callback: () => {
            this.spawnSpikeBall(startingPosition + 50);
            this.spawnEvent = this.time.addEvent({
              delay: 250,
              callback: () => {
                this.spawnSpikeBall(startingPosition + 250);
                this.spawnEvent = this.time.addEvent({
                  delay: 250,
                  callback: () => {
                    this.spawnSpikeBall(startingPosition + 300);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }
}
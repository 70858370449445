import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import _ from "lodash";

export default class RoundStartScene extends Scene {
  constructor() {
    super({ key: "RoundStartScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.round = data.round;
    this.questions = data.questions;
    this.score = data.score;
    this.bossRound = data.bossRound;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.forceFriend = data.forceFriend;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
  }

  avatarObject = [];

  create() {
    if (store.state.settings.data.cache) {
      let game_cache_data = _.cloneDeep(store.state.miniGame.data);
      game_cache_data.round = this.round;
      game_cache_data.score = this.score;
      game_cache_data.isBossOver = this.bossRound < this.round;
      game_cache_data.countCorrect = this.countCorrect;
      
      store.commit("updateMiniGame", game_cache_data);
    }

    this.add.image(240, 427, "bg").setScale(0.5);
    this.add.image(240, 390, "wall").setScale(1.12);
    if (this.round != 1) {
      this.add.image(80, 35, "score").setScale(0.3);
      this.add.image(70, 70, "score").setScale(0.25, 0.2);
      this.scoreText = this.add
        .text(
          30,
          35,
          i18n.t("miniGames.general.score") + " : " + this.score + "",
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 18,
            color: "#000",
          }
        )
        .setOrigin(0, 0.5);

      this.add
        .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 15,
          color: "#000",
        })
        .setOrigin(0, 0.5);
    }

    this.settingBtn = this.add.image(440, 40, "setting").setScale(0.6);
    this.settingBtn.on("pointerdown", clickSetting, this);
    this.settingBtn.setInteractive({ useHandCursor: true });

    // Sounds
    this.startGameSound = this.sound.add("startGame");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.roundSound = this.sound.add("round");
    this.emojiSound = this.sound.add("emoji");
    // this.enterSound = this.sound.add("enter");
    // this.enterSound.loop = true;

    for (let j = 0; j < 4; j++) {
      for (let i = 0; i < 4; i++) {
        this.add.image(90 + i * 100, 240 + j * 100, "steelWall");
      }
    }

    // avatar
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.add.image(this.round == 1 ? -110 : 100, 720, key).setScale(0.3)
          );
          this.avatarObject.push(
            this.add
              .image(this.round == 1 ? -110 : 100, 720, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.3)
              .setAlpha(0.3)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.add.image(this.round == 1 ? -110 : 100, 720, key).setScale(0.3)
          );
        }
      }
    }

    this.speech = this.add.image(100, 620, "speech").setScale(0.0);
    this.mark = this.add.image(100, 620, "excl").setScale(0.0);
    this.modelbg = this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0);
    this.roundObj = [
      this.add.image(240, 427, "round").setScale(0),
      this.add
        .text(240, 427, i18n.t("miniGames.general.round") + " " + this.round, {
          fontFamily: "Sassoon, sans-serif",
          color: "#000000",
          fontSize: 32,
        })
        .setOrigin(0.5)
        .setScale(0),
    ];

    this.startBtn = this.add.image(380, 750, "play").setScale(0);
    this.startBtn.setInteractive({ cursor: "pointer" });

    this.startBtn.on(
      "pointerdown",
      () => {
        this.startGameSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.startGameSound.play();
        this.avatarObject = [];
        this.registry.destroy();
        this.events.off();
        this.scene.stop("RoundStartScene");
        this.scene.start("QuestionScene", {
          avatar: this.avatar,
          round: this.round,
          questions: this.questions,
          score: this.score,
          friend: this.friend,
          bossRound: this.bossRound,
          noOfRounds: this.noOfRounds,
          forceFriend: this.forceFriend,
          difficulty: this.difficulty,
          countCorrect: this.countCorrect,
        });
      },
      this
    );

    if (this.round == 1) {
      this.tweens.add({
        targets: this.avatarObject,
        x: 100,
        duration: 1500,
        ease: "Power2",
        repeat: 0,
        delay: 500,
        onComplete: () => {
          this.tweens.add({
            targets: [this.mark, this.speech],
            scaleX: 0.3,
            scaleY: 0.3,
            duration: 300,
            ease: "Sine.easeOut",
            onStart: () => {
              this.emojiSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.emojiSound.play();
            },
          });
        },
      });

      this.tweens.add({
        targets: this.avatarObject,
        scaleY: 0.35,
        scaleX: 0.29,
        y: 710,
        duration: 700,
        ease: "Sine.easeInOut",
        repeat: 3,
        yoyo: true,
        onComplete: () => {
          this.tweens.add({
            targets: this.avatarObject,
            scaleY: 0.31,
            scaleX: 0.29,
            y: 715,
            duration: 2000,
            ease: "Sine.easeInOut",
            repeat: -1,
            yoyo: true,
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [this.mark, this.speech],
        scaleX: 0.3,
        scaleY: 0.3,
        duration: 300,
        ease: "Sine.easeOut",
        onStart: () => {
          this.emojiSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.emojiSound.play();
        },
      });
      this.tweens.add({
        targets: this.avatarObject,
        scaleY: 0.31,
        scaleX: 0.29,
        y: 715,
        duration: 2000,
        ease: "Sine.easeInOut",
        repeat: -1,
        yoyo: true,
      });
    }

    this.tweens.add({
      targets: this.modelbg,
      alpha: 0.4,
      duration: 200,
      ease: "Sine.easeOut",
      delay: this.round == 1 ? 3000 : 1000,
    });
    this.tweens.add({
      targets: this.roundObj,
      scaleX: 1,
      scaleY: 1,
      duration: 200,
      ease: "Sine.easeOut",
      delay: this.round == 1 ? 3000 : 1000,
      onStart: () => {
        this.roundSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.roundSound.play();
      },
      onComplete: () => {
        this.tweens.add({
          targets: this.roundObj,
          scaleX: 0,
          scaleY: 0,
          duration: 200,
          ease: "Sine.easeOut",
          delay: 1000,
        });
        this.tweens.add({
          targets: this.modelbg,
          alpha: 0,
          duration: 200,
          ease: "Sine.easeOut",
          delay: 1000,
          onComplete: () => {
            this.tweens.add({
              targets: this.startBtn,
              scaleX: 0.25,
              scaleY: 0.25,
              duration: 200,
              ease: "Sine.easeOut",
              delay: 500,
              onComplete: () => {
                this.tweens.add({
                  targets: this.startBtn,
                  scaleX: 0.27,
                  scaleY: 0.27,
                  duration: 500,
                  ease: "Sine.easeInOut",
                  repeat: -1,
                  yoyo: true,
                });
              },
            });
          },
        });
      },
    });

    function clickSetting() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "RoundStartScene",
      });
    }
  }

  update() {
    //
  }
}

import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import _ from "lodash";

export default class AnsCorrectPlayScene extends Scene {
  constructor() {
    super({ key: "AnsCorrectPlayScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.score = data.score;
    this.round = data.round;
    this.questions = data.questions;
    this.bossRound = data.bossRound;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.isBossRound = data.round == data.bossRound && data.isCorrectAnswer;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.avatarX = data.avatarX;
  }

  avatarObject = [];
  friendAvatarObject = [];
  fallingFruitsGroup;
  totalFruitsCaught = 0;
  isGameOngoing = true;
  isBossGenerated = false;
  isCollisionEnabled = true;
  canMove = false;
  spawnFruitCount = 0;
  totalCountForStop = 0;

  create() {
    // Background
    this.backgroundImage = this.add
      .image(0, 0, "backgroundImage")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this.scoreBoard = this.add.image(80, 35, "score").setScale(0.3).setDepth(2);
    this.resultBoard = this.add
      .image(70, 70, "score")
      .setScale(0.25, 0.2)
      .setDepth(2);
    this.settingBtn = this.add
      .image(440, 40, "setting")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(2);
    this.roundText = this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5)
      .setDepth(2);
    this.feedback = this.add
      .text(240, 390, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(3, 3, "#000", 0, true, true);

    // Animations

    this.spriteCatch = this.add
      .sprite(0, 535, "spriteCatch")
      .setScale(0.5)
      .setAlpha(0);
    this.anims.create({
      key: "catching",
      frames: this.anims.generateFrameNumbers("spriteCatch", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.spriteFruitShine = this.add
      .sprite(0, 0, "spriteFruitShine")
      .setScale(1.2)
      .setAlpha(0);
    this.anims.create({
      key: "FruitShine",
      frames: this.anims.generateFrameNumbers("spriteFruitShine", {
        frames: [0, 1, 2, 3],
      }),
      repeat: -1,
      frameRate: 5,
    });

    this.spriteEnergy = this.add
      .sprite(0, 0, "spriteEnergy")
      .setScale(0.7)
      .setAlpha(0)
      .setDepth(5);
    this.anims.create({
      key: "avatarEnergy",
      frames: this.anims.generateFrameNumbers("spriteEnergy", {
        frames: [2, 3, 4, 5],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.spriteLand = this.add
      .sprite(0, 0, "spriteLand")
      .setScale(0.5)
      .setAlpha(0)
      .setDepth(5);
    this.anims.create({
      key: "avatarLand",
      frames: this.anims.generateFrameNumbers("spriteLand", {
        frames: [0, 1, 2, 3, 4, 5],
      }),
      frameRate: 10,
    });

    this.spriteJump = this.add
      .sprite(0, 0, "spriteJump")
      .setScale(2)
      .setAlpha(0)
      .setDepth(5);
    this.anims.create({
      key: "avatarJump",
      frames: this.anims.generateFrameNumbers("spriteJump", {
        frames: [0, 1, 2, 3],
      }),
      frameRate: 5,
    });

    // NPC and Drum
    this.drumHitSound = this.sound.add("drumHitSound");
    this.npc = this.add.image(60, 630, "npc").setScale(0.5);
    this.drum = this.add.image(130, 560, "drum").setScale(0.4);

    // Sounds
    this.simpleClickSound = this.sound.add("simpleClick");
    this.fruitShineSound = this.sound.add("fruitShineSound");
    this.fruitShineSound.loop = true;
    this.avatarJumpSound = this.sound.add("avatarJumpSound");
    this.avatarGroundSound = this.sound.add("avatarGroundSound");

    // Player Avarar and Basket
    this.basket = this.physics.add
      .sprite(this.avatarX, 610, "basket")
      .setScale(0.4)
      .setDepth(2)
      .setAlpha(0);
    this.basketTop = this.add
      .rectangle(0, 0, 75, 5, 0xff0000)
      .setAlpha(0)
      .setDepth(2);
    this.physics.world.enable(this.basketTop);
    this.basketTop.body.setCollideWorldBounds(true);
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(5)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(5)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(5)
          );
        }
      }
    }
    this.spriteEnergy.setAlpha(1);
    this.spriteEnergy.play("avatarEnergy");

    this.tweens.add({
      targets: [this.basket],
      y: 600,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });
    this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: 655,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    // User control
    this.leftArrowBtn = this.add
      .image(120, 775, "leftArrowBtnImage")
      .setScale(0.28)
      .on("pointerdown", this.startMovingLeft, this)
      .on("pointerup", this.stopMoving, this)
      .setDepth(2);

    this.rightArrowBtn = this.add
      .image(360, 775, "rightArrowBtnImage")
      .setScale(0.28)
      .on("pointerdown", this.startMovingRight, this)
      .on("pointerup", this.stopMoving, this)
      .setDepth(2);

    this.input.keyboard.on("keydown-LEFT", this.startMovingLeft, this);
    this.input.keyboard.on("keydown-RIGHT", this.startMovingRight, this);
    this.input.keyboard.on("keyup-LEFT", this.stopMoving, this);
    this.input.keyboard.on("keyup-RIGHT", this.stopMoving, this);

    this.fallingFruitsGroup = this.add.group();

    // Game transitions starts here
    this.darkBack = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(3);

    this.time.delayedCall(1000, () => {
      this.spriteEnergy.visible = false;
      this.spriteJump.y = this.avatarObject[0].y;
      this.spriteJump.setAlpha(1);
      this.spriteJump.play("avatarJump");

      this.tweens.add({
        targets: this.avatarObject,
        scaleY: 0.22,
        y: "+=10",
        ease: "Power1",
        duration: 100,
        yoyo: true,
        onStart: () => {
          if (this.avatarMask) {
            this.avatarMask.destroy();
          }
          this.avatarMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "push")
            .setScale(0.25)
            .setDepth(6);
          this.avatarJumpSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.avatarJumpSound.play();
        },
        onComplete: () => {
          this.tweens.add({
            targets: this.avatarObject,
            y: "-=400",
            ease: "Power1",
            duration: 700,
            yoyo: true,
            onStart: () => {
              this.avatarGroundSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.avatarGroundSound.play();
            },
            onComplete: () => {
              this.spriteJump.visible = false;
              this.spriteLand.y = this.avatarObject[0].y;
              this.spriteLand.setAlpha(1);
              this.spriteLand.play("avatarLand");
              this.darkBack.visible = false;
              this.cameras.main.shake(200, 0.03);

              this.tweens.add({
                targets: this.avatarObject,
                scaleY: 0.22,
                y: "+=10",
                ease: "Power1",
                duration: 100,
                yoyo: true,
                onComplete: () => {
                  if (this.avatarMask) {
                    this.avatarMask.destroy();
                  }
                  this.time.delayedCall(500, () => {
                    this.spriteLand.visible = false;
                    this.basket.setAlpha(1);
                    this.leftArrowBtn.setInteractive({ useHandCursor: true });
                    this.rightArrowBtn.setInteractive({ useHandCursor: true });
                    this.npcMask = this.add
                      .image(60, 615, "ziz")
                      .setScale(0.25);
                    this.canMove = true;
                    this.spawnFIsCos = Math.random() < 0.5;
                    this.spawnFX = Math.floor(Math.random() * 5) + 1;
                    this.spawnFY = Math.floor(Math.random() * 20) + 100;

                    this.goldFruitSpawnEvent = this.time.addEvent({
                      delay: 500,
                      callback: this.spawnGoldFruit,
                      callbackScope: this,
                      loop: true,
                    });
                  });
                },
              });
            },
          });
        },
      });
    });

    this.positiveHitSound = this.sound.add("positiveHitSound");
    this.bossCatchSound = this.sound.add("bossCatchSound");

    this.physics.add.collider(
      this.basketTop,
      this.fallingFruitsGroup,
      this.handleCollision,
      null,
      this
    );
  }

  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "AnsCorrectPlayScene",
    });
  }

  startMovingLeft() {
    this.stopMoving();
    if (this.canMove) {
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(-300);
      }
    }
  }

  startMovingRight() {
    this.stopMoving();
    if (this.canMove) {
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(300);
      }
    }
  }

  stopMoving() {
    for (var i = 0; i < this.avatarObject.length; i++) {
      this.avatarObject[i].setVelocityX(0);
    }
  }

  handleBossCollision(x, fallingImage) {
    this.spriteFruitShine.setAlpha(0);
    this.fruitShineSound.stop();
    fallingImage.destroy();
    this.bossCatchSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.bossCatchSound.play();

    this.spriteCatch.setAlpha(1);
    this.spriteCatch.play("catching");

    this.time.delayedCall(1000, () => {
      let avatarX = this.avatarObject[0].x;
      this.avatarObject = [];
      this.friendAvatarObject = [];
      this.fallingFruitsGroup;
      this.totalFruitsCaught = 0;
      this.totalCountForStop = 0;
      this.canMove = false;
      this.spawnFruitCount = 0;
      this.isGameOngoing = true;
      this.isCollisionEnabled = true;
      this.isBossGenerated = false;
      this.scene.start("BossScene", {
        avatar: this.avatar,
        round: this.round,
        score: this.score,
        questions: this.questions,
        isCorrectAnswer: this.isCorrectAnswer,
        friend: this.friend,
        bossRound: this.bossRound,
        noOfRounds: this.noOfRounds,
        difficulty: this.difficulty,
        countCorrect: this.countCorrect,
        avatarX: avatarX,
      });
    });
  }

  handleCollision(x, fallingImage) {
    if (this.isCollisionEnabled) {
      fallingImage.destroy();
      this.score += fallingImage.score;
      this.scoreText.setText(
        i18n.t("miniGames.general.score") + " : " + this.score
      );

      let scoreHint = this.add
        .text(0, 0, "", {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        })
        .setOrigin(0, 0.5)
        .setAlpha(0);
      scoreHint.setAlpha(1);

      scoreHint.setText("+" + fallingImage.score);

      scoreHint.x = this.basket.x;
      scoreHint.y = this.basket.y - 50;
      this.tweens.add({
        targets: [scoreHint],
        y: 500,
        duration: 500,
        ease: "Sine.easeOut",
        onStart: () => {
          this.positiveHitSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.positiveHitSound.play();

          this.spriteCatch.setAlpha(1);
          this.spriteCatch.play("catching");

          if (this.avatarMask) {
            this.avatarMask.destroy();
          }
          this.avatarMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "happy")
            .setScale(0.25)
            .setDepth(6);

          this.time.delayedCall(1000, () => {
            if (this.avatarMask) {
              this.avatarMask.setScale(0);
              this.avatarMask.destroy();
            }
          });
        },
        onComplete: () => {
          scoreHint.setAlpha(0);
        },
      });
      this.totalFruitsCaught += 1;
      this.totalCountForStop += 1;
    }
  }

  update() {
    if (this.avatarObject[0]) {
      this.basket.x = this.avatarObject[0].x;
      this.spriteEnergy.x = this.avatarObject[0].x;
      this.spriteEnergy.y = this.avatarObject[0].y - 10;
      this.spriteLand.x = this.avatarObject[0].x;
      this.spriteJump.x = this.avatarObject[0].x;
    }
    if (this.basket) {
      this.spriteCatch.x = this.basket.x;
      this.basketTop.x = this.basket.x;
      this.basketTop.y = this.basket.y - 40;
    }
    if (this.avatarMask && this.avatarObject[0]) {
      this.avatarMask.x = this.avatarObject[0].x;
      this.avatarMask.y = this.avatarObject[0].y;
    }

    if (this.totalCountForStop >= 16 && this.isGameOngoing) {
      if (!this.isBossRound) {
        this.isGameOngoing = false;
        this.gameFinish();
      }
    }

    if (this.spawnFruitCount >= 16) {
      this.time.removeEvent(this.goldFruitSpawnEvent);
      if (this.isBossRound && !this.isBossGenerated) {
        this.isBossGenerated = true;
        this.generateBoss();
      }
    }

    this.fallingFruitsGroup.getChildren().forEach(function (fallingImage) {
      if (fallingImage.y >= 700) {
        if (fallingImage.isActive) {
          fallingImage.isActive = false;
          if (
            fallingImage.type == "goldFruit"
          ) {
            this.totalCountForStop += 1;
            this.tweens.add({
              targets: fallingImage,
              y: "-=5",
              yoyo: true,
              duration: 500,
              ease: "Sine.easeInOut",
              onStart: () => {
                this.tweens.add({
                  targets: fallingImage,
                  x: Math.random() < 0.5 ? "+=10" : "-=10",
                  duration: 500,
                  ease: "Sine.easeInOut",
                });
              },
              onComplete: () => {
                this.tweens.add({
                  targets: fallingImage,
                  alpha: 0,
                  duration: 500,
                  ease: "Sine.easeInOut",
                  onComplete: () => {
                    fallingImage.destroy();
                  },
                });
              },
            });
          }
        }
      } else {
        fallingImage.y += 4;
      }
    }, this);

    if (this.bossFruit && this.isBossRound) {
      this.bossFruit.y += 3;
      this.spriteFruitShine.y = this.bossFruit.y;
      this.spriteFruitShine.x = this.bossFruit.x;
      if (this.bossFruit.y >= 830) {
        this.isGameOngoing = false;
        this.bossFruit.destroy();
        this.spriteFruitShine.setAlpha(0);
        this.fruitShineSound.stop();
        this.gameFinish();
      }
    }
  }

  generateBoss() {
    this.time.delayedCall(1000, () => {
      this.bossFruit = this.physics.add
        .sprite(Phaser.Math.Between(40, 440), 0, "fruitBoss")
        .setScale(0.35);

      this.spriteFruitShine.setAlpha(1);
      this.spriteFruitShine.play("FruitShine");
      this.fruitShineSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.fruitShineSound.play();

      this.physics.add.collider(
        this.basketTop,
        this.bossFruit,
        this.handleBossCollision,
        null,
        this
      );
    });
  }

  gameFinish() {
    this.time.delayedCall(1000, () => {
      this.stopMoving();
      this.canMove = false;

      if (this.round != this.noOfRounds) {
        let avatarX = this.avatarObject[0].x;
        this.avatarObject = [];
        this.friendAvatarObject = [];
        this.fallingFruitsGroup;
        this.totalFruitsCaught = 0;
        this.totalCountForStop = 0;
        this.canMove = false;
        this.spawnFruitCount = 0;
        this.isGameOngoing = true;
        this.isCollisionEnabled = true;
        this.isBossGenerated = false;
        this.scene.start("PlayScene", {
          avatar: this.avatar,
          round: this.round + 1,
          score: this.score,
          questions: this.questions,
          friend: this.friend,
          bossRound: this.bossRound,
          noOfRounds: this.noOfRounds,
          difficulty: this.difficulty,
          countCorrect: this.countCorrect,
          avatarX: avatarX,
        });
      } else {
  
      
        let game_cache_data = _.cloneDeep(store.state.miniGame.data);
          game_cache_data.round = this.round;
          game_cache_data.score = this.score;
          game_cache_data.countCorrect = this.countCorrect;
          
          store.commit("updateMiniGame", game_cache_data);
        

        this.time.delayedCall(1000, () => {
          router.push({
            name: "PageExploreDailyGameScore",
          });
        });
      }
    });
  }

  spawnGoldFruit() {
    this.spawnFruitCount++;
    const fallingImage = this.physics.add
      .sprite(
        this.spawnFIsCos
          ? Math.cos((this.spawnFruitCount % 400) / this.spawnFX) *
              this.spawnFY +
              250
          : Math.cos((this.spawnFruitCount % 400) / this.spawnFX) *
              this.spawnFY +
              250,
        -50,
        "fruitGold"
      )
      .setDepth(1)
      .setScale(0.35);

    fallingImage.type = "goldFruit";
    fallingImage.score = 5;
    fallingImage.isActive = true;
    this.fallingFruitsGroup.add(fallingImage);
  }
}
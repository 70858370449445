import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import _ from "lodash";
import Phaser from "phaser";

export default class PlayScene extends Scene {
  constructor() {
    super({ key: "PlayScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round == data.noOfRounds ? 2 : data.round + 1;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.countCorrect = data.countCorrect;
    this.difficulty = data.difficulty;
    this.playerHP = data.playerHP ? data.playerHP : 1200;
    this.enemyHP = data.enemyHP ? data.enemyHP : 400;
    this.enemyCount = data.enemyCount ? data.enemyCount : 3;
    this.isStrong = data.isAfterQuestion && data.isCorrectAnswer;
    this.isCollectable =
      data.isAfterQuestion && data.isCorrectAnswer & (data.enemyCount == 1);
    this.isWeak = data.isAfterQuestion && !data.isCorrectAnswer;
    this.enemyTotalHP = data.enemyCount
      ? data.enemyCount == 1
        ? 750
        : 400
      : 400;
    this.isFriendCame = data.isFriendCame;
    this.noOfRounds = data.noOfRounds;
  }

  KO_VALUE = 250;
  PLAYER_HP = 1200;
  MINION_HP = 400;
  BOSS_HP = 750;

  currentMeter = 0;
  isMeterRunning = false;
  playerMeterReadings = 0;
  enemyMeterReadings = 0;
  enemyLifeBoxes = [];
  playerTotalHP = this.PLAYER_HP;
  playerAvatarObject = [];
  avatarBarObject = [];

  create() {
    this.meterDuration =
      this.difficulty == 3 ? 800 : this.difficulty == 2 ? 1000 : 1200;
    this.registerAnims();
    this.registerSounds();

    //* setup the Background  *//
    this.imgBackground = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);

    this.imgWave1 = this.add.image(0, 180, "imgWave").setOrigin(0);
    this.imgWave2 = this.add.image(-100, 220, "imgWave").setOrigin(0);
    this.imgWave3 = this.add.image(0, 260, "imgWave").setOrigin(0);
    this.imgBoat = this.add
      .image(240, 170, "imgBoat")
      .setOrigin(0)
      .setScale(0.85);

    this.sptBubbles = this.add
      .sprite(240, 620, "sptBubbles")
      .setOrigin(0)
      .setScale(0.85)
      .setAlpha(0);
    this.imgWave4 = this.add.image(0, 660, "imgWave").setOrigin(0);
    this.imgWave5 = this.add.image(-100, 700, "imgWave").setOrigin(0);
    this.imgWave6 = this.add.image(0, 740, "imgWave").setOrigin(0);
    this.imgDock = this.add
      .image(0, 250, "imgDock")
      .setOrigin(0)
      .setScale(0.75, 1);
    this.chest = this.add
      .sprite(380, 400, "sptChest")
      .setScale(0.6)
      .setAlpha(0);
    this.imgWave7 = this.add.image(-100, 800, "imgWave").setOrigin(0);

    this.tweens.add({
      targets: [this.imgWave1, this.imgWave3, this.imgWave4, this.imgWave6],
      x: "-=100",
      duration: 9000,
      repeat: -1,
      yoyo: true,
    });

    this.tweens.add({
      targets: [this.imgWave2, this.imgWave5, this.imgWave7],
      x: "+=100",
      duration: 10000,
      repeat: -1,
      yoyo: true,
    });

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreBoard = this.add.image(100, 40, "imgScorePanel").setScale(0.4);

    this.scoreText = this.add
      .text(
        40,
        40,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5);

    this.add.image(240, 130, "imgEnemyPanel").setOrigin(0.5).setAlpha(0.8);

    for (let index = 0; index < 3; index++) {
      let liveBox = this.add
        .image(107 + index * 135, 125, "imgEny" + (index + 1))
        .setScale(0.45)
        .setOrigin(0.5)
        .setTint(this.enemyCount <= 3 - index ? 0xffffff : 0x000000)
        .setAlpha(this.enemyCount <= 3 - index ? 1 : 0.6);
      liveBox.imgX = this.add
        .image(105 + index * 134, 130, "imgX")
        .setScale(0.7)
        .setOrigin(0.5)
        .setAlpha(this.enemyCount > 2 - index ? 0 : 1);

      this.enemyLifeBoxes.push(liveBox);
    }

    this.dark = this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0);

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(100, 380, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(100, 380, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(100, 380, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerEnergy = this.add
      .sprite(0, 0, "sptEnergy")
      .setScale(0.7)
      .setAlpha(0);

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 1000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.playerWand = this.add.sprite(190, 380, "sptWand").setDepth(1);
    this.playerWand.power = this.add
      .sprite(190, 380, "sptWandPower")
      .setDepth(5);

    this.playerDizzy = this.add
      .sprite(100, 330, "sptDizzyAnim")
      .setFrame(2)
      .setDepth(6);

    this.playerShield = this.add
      .sprite(100, 350, "sptShield")
      .setScale(0.85)
      .setAlpha(0)
      .setDepth(7);

    this.playerHPBoard = this.add
      .graphics()
      .fillStyle(0xffffff)
      .fillRect(40, 280, 120, 50)
      .setAlpha(0.9);

    this.playerHPBarBack = this.add
      .graphics()
      .fillStyle(0x000000)
      .fillRect(43, 308, 114, 19)
      .setAlpha(0.2);

    this.playerHPBar = this.add.graphics();
    if (this.playerHP <= this.KO_VALUE) {
      this.playerHPBar
        .fillStyle(0xff0000)
        .fillRect(43, 308, (114 * this.playerHP) / this.playerTotalHP, 19);
    } else {
      this.playerHPBar
        .fillStyle(0x00ff00)
        .fillRect(43, 308, (114 * this.playerHP) / this.playerTotalHP, 19);
    }

    this.playerName = this.add
      .text(
        50,
        280,
        this.name.length > 12 ? this.name.substring(0, 12) : this.name,
        {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Sassoon, sans-serif",
        }
      )
      .setOrigin(0);

    this.playerHPTxt = this.add
      .text(50, 310, this.playerHP, {
        color: "#000",
        fontSize: "15px",
        fontFamily: "Sassoon, sans-serif",
      })
      .setOrigin(0);

    // * Enemy *//
    this.enemy = this.add
      .image(
        this.enemyCount == 1 ? 400 : 380,
        390,
        this.enemyCount == 1
          ? "imgEny3x"
          : this.enemyCount == 2
          ? "imgEny2"
          : "imgEny1"
      )
      .setOrigin(0.5)
      .setDepth(2);

    if (this.enemyCount == 1) {
      this.canon = this.add.sprite(325, 415, "sptCanon").setDepth(2);
      if (this.enemyMask) {
        this.enemyMask.destroy();
      }
      this.enemyMask = this.add
        .image(this.enemy.x, this.enemy.y, "maskBoss")
        .setScale(0.25)
        .setDepth(3)
        .setFlipX(true);
    }

    this.enemyDizzy = this.add
      .sprite(this.enemyCount == 1 ? 390 : 370, 350, "sptDizzyAnim")
      .setFrame(2)
      .setDepth(6);

    this.enemyTween = this.tweens.add({
      targets: [this.enemy],
      scaleY: 1.01,
      scaleX: 0.99,
      y: "-=5",
      delay: 500,
      duration: 1000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.enemyHPBoard = this.add
      .graphics()
      .fillStyle(0xffffff)
      .fillRect(320, 280, 120, 50)
      .setAlpha(0.9);

    this.enemyHPBarBack = this.add
      .graphics()
      .fillStyle(0x000000)
      .fillRect(323, 308, 114, 19)
      .setAlpha(0.2);

    this.enemyHPBar = this.add.graphics();
    if (this.enemyHP <= this.KO_VALUE) {
      this.enemyHPBar
        .fillStyle(0xff0000)
        .fillRect(
          323 - (114 * this.enemyHP) / this.enemyTotalHP + 114,
          308,
          (114 * this.enemyHP) / this.enemyTotalHP,
          19
        );
    } else {
      this.enemyHPBar
        .fillStyle(0x00ff00)
        .fillRect(
          323 - (114 * this.enemyHP) / this.enemyTotalHP + 114,
          308,
          (114 * this.enemyHP) / this.enemyTotalHP,
          19
        );
    }

    this.enemyHPTxt = this.add
      .text(430, 310, this.enemyHP, {
        color: "#000",
        fontSize: "15px",
        fontFamily: "Sassoon, sans-serif",
      })
      .setOrigin(1, 0);

    this.enemyName = this.add
      .text(
        430,
        280,
        this.enemyCount == 1
          ? "Yellowbeard"
          : this.enemyCount == 2
          ? "Will"
          : "Jack",
        {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Sassoon, sans-serif",
        }
      )
      .setOrigin(1, 0);

    this.sptAttackBtn = this.add
      .sprite(240, 700, "sptAttackBtn")
      .on("pointerdown", this.clickAttackBtn, this)
      .setScale(0.7)
      .setFrame(2);

    this.sptIdea = this.add
      .sprite(240, 700, "sptIdea")
      .setAlpha(0)
      .setDepth(8)
      .setScale(1.2)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", this.goQuestion, this);

    this.Meter_1_Shadow = this.add
      .graphics()
      .lineStyle(14, 0x000000)
      .beginPath()
      .arc(
        80,
        490,
        25,
        -Math.PI / 2 - 2 * Math.PI * 0.07,
        -Math.PI / 2 - 2 * Math.PI * 0.93,
        true
      )
      .strokePath()
      .setAlpha(0.2);
    this.Meter_1_BackBar = this.add.graphics().setAlpha(0);
    this.drawMeterBack(this.Meter_1_BackBar, 80, 490, 25, 14);
    this.Meter_1 = this.add.graphics();

    this.Meter_2_Shadow = this.add
      .graphics()
      .lineStyle(14, 0x000000)
      .beginPath()
      .arc(
        160,
        490,
        25,
        -Math.PI / 2 - 2 * Math.PI * 0.07,
        -Math.PI / 2 - 2 * Math.PI * 0.93,
        true
      )
      .strokePath()
      .setAlpha(0.2);
    this.Meter_2_BackBar = this.add.graphics().setAlpha(0);
    this.drawMeterBack(this.Meter_2_BackBar, 160, 490, 25, 14);
    this.Meter_2 = this.add.graphics();

    if (this.isWeak) {
      if (this.playerMask) {
        this.playerMask.destroy();
      }
      this.playerMask = this.add
        .image(
          this.playerAvatarObject[0].x,
          this.playerAvatarObject[0].y,
          "maskWeary"
        )
        .setScale(0.25)
        .setDepth(6);
      this.tweens.add({
        targets: [this.Meter_2_Shadow],
        alpha: 0.5,
        duration: 100,
        ease: "Power1",
        repeat: 4,
        yoyo: true,
        onComplete: () => {
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.Meter_2_Shadow.setAlpha(0);
          this.sfxWeak.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxWeak.play();
        },
      });
    }

    if (this.isStrong) {
      this.Meter_4_Shadow = this.add
        .graphics()
        .lineStyle(14, 0x000000)
        .beginPath()
        .arc(
          120,
          560,
          25,
          -Math.PI / 2 - 2 * Math.PI * 0.07,
          -Math.PI / 2 - 2 * Math.PI * 0.93,
          true
        )
        .strokePath()
        .setAlpha(0);
      this.Meter_4_BackBar = this.add.graphics().setAlpha(0);
      this.drawMeterBack(this.Meter_4_BackBar, 120, 560, 25, 14);
      this.Meter_4 = this.add.graphics();

      this.tweens.add({
        targets: [this.Meter_4_Shadow, this.playerEnergy],
        alpha: 0.5,
        duration: 100,
        ease: "Power1",
        repeat: 4,
        yoyo: true,
        onComplete: () => {
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(
              this.playerAvatarObject[0].x,
              this.playerAvatarObject[0].y,
              "maskHappyx"
            )
            .setScale(0.25)
            .setDepth(6);
          this.dark.setAlpha(0.5);
          this.Meter_4_Shadow.setAlpha(0.2);
          this.playerEnergy.setAlpha(1);
          this.playerEnergy.play("animPlayerEnergy");
          this.sfxStrong.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxStrong.play();
        },
      });
    }

    this.Meter_1_Shadow_enemy = this.add
      .graphics()
      .lineStyle(10, 0x000000)
      .beginPath()
      .arc(
        350,
        490,
        20,
        -Math.PI / 2 - 2 * Math.PI * 0.07,
        -Math.PI / 2 - 2 * Math.PI * 0.93,
        true
      )
      .strokePath()
      .setAlpha(0.1);
    this.Meter_1_BackBar_enemy = this.add.graphics().setAlpha(0);
    this.drawMeterBack(this.Meter_1_BackBar_enemy, 350, 490, 20, 10);
    this.Meter_1_enemy = this.add.graphics();

    this.Meter_2_Shadow_enemy = this.add
      .graphics()
      .lineStyle(10, 0x000000)
      .beginPath()
      .arc(
        410,
        490,
        20,
        -Math.PI / 2 - 2 * Math.PI * 0.07,
        -Math.PI / 2 - 2 * Math.PI * 0.93,
        true
      )
      .strokePath()
      .setAlpha(this.enemyCount < 3 ? 0.1 : 0);
    this.Meter_2_BackBar_enemy = this.add.graphics().setAlpha(0);
    this.drawMeterBack(this.Meter_2_BackBar_enemy, 410, 490, 20, 10);
    this.Meter_2_enemy = this.add.graphics();

    this.Meter_4_Shadow_enemy = this.add
      .graphics()
      .lineStyle(10, 0x000000)
      .beginPath()
      .arc(
        380,
        540,
        20,
        -Math.PI / 2 - 2 * Math.PI * 0.07,
        -Math.PI / 2 - 2 * Math.PI * 0.93,
        true
      )
      .strokePath()
      .setAlpha(this.enemyCount < 2 ? 0.1 : 0);
    this.Meter_4_BackBar_enemy = this.add.graphics().setAlpha(0);
    this.drawMeterBack(this.Meter_4_BackBar_enemy, 380, 540, 20, 10);
    this.Meter_4_enemy = this.add.graphics();

    if (this.round == 1) {
      this.Meter_1_Shadow_enemy.setAlpha(0);
      this.enemy.x = 580;
      this.enemyName.setAlpha(0);
      this.enemyHPTxt.setAlpha(0);
      this.enemyHPBar.setAlpha(0);
      this.enemyHPBarBack.setAlpha(0);
      this.enemyHPBoard.setAlpha(0);
      this.enemyEntry();
    } else {
      // after setup intro message then
      this.time.delayedCall(2000, () => {
        this.Meter_1_BackBar.setAlpha(0.7);
        this.currentMeter = 1;
        this.sptAttackBtn.setInteractive({ useHandCursor: true });
        this.sptAttackBtn.setFrame(0);

        this.btnTween = this.tweens.add({
          targets: this.sptAttackBtn,
          scaleX: 0.75,
          scaleY: 0.75,
          duration: 500,
          repeat: -1,
          yoyo: true,
        });
      });
    }
  }

  update() {
    if (this.playerAvatarObject[0]) {
      this.playerEnergy.x = this.playerAvatarObject[0].x;
      this.playerEnergy.y = this.playerAvatarObject[0].y - 10;
    }

    if (this.playerMask && this.playerAvatarObject[0]) {
      this.playerMask.x = this.playerAvatarObject[0].x;
      this.playerMask.y = this.playerAvatarObject[0].y;
    }

    if (this.playerWand && this.playerAvatarObject[0]) {
      this.playerWand.x = this.playerAvatarObject[0].x + 70;
      this.playerWand.y = this.playerAvatarObject[0].y - 30;
      this.playerWand.power.x = this.playerAvatarObject[0].x + 80;
      this.playerWand.power.y = this.playerAvatarObject[0].y - 110;
    }

    if (this.enemyMask && this.enemy) {
      this.enemyMask.x = this.enemy.x;
      this.enemyMask.y = this.enemy.y;
    }

    this.playerHPBar.y = this.playerAvatarObject[0].y - 400;
    this.playerHPBarBack.y = this.playerAvatarObject[0].y - 400;
    this.playerHPBoard.y = this.playerAvatarObject[0].y - 400;
    this.playerHPTxt.y = this.playerAvatarObject[0].y - 90;
    this.playerName.y = this.playerAvatarObject[0].y - 115;

    this.enemyHPBar.y = this.enemy.y - 400;
    this.enemyHPBarBack.y = this.enemy.y - 400;
    this.enemyHPBoard.y = this.enemy.y - 400;
    this.enemyHPTxt.y = this.enemy.y - 90;
    this.enemyName.y = this.enemy.y - 115;
  }

  startGame() {
    let startText = this.add
      .text(240, 400, i18n.t("miniGames.general.ready"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
        stroke: "#000000", // Stroke color
        strokeThickness: 5, // Stroke thickness
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(8)
      .setShadow(3, 3, "#000", 0, true, true);

    this.tweens.add({
      targets: startText,
      scaleX: 1,
      scaleY: 1,
      duration: 300,
      delay: 1000,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.sfxGo.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxGo.play();
      },
      onComplete: () => {
        this.tweens.add({
          targets: startText,
          scaleX: 0,
          scaleY: 0,
          duration: 300,
          delay: 1000,
          ease: "Sine.easeInOut",
          onComplete: () => {
            startText.setText(i18n.t("miniGames.kombat.fight"));
            this.tweens.add({
              targets: startText,
              scaleX: 1,
              scaleY: 1,
              duration: 300,
              ease: "Sine.easeInOut",
              onStart: () => {
                this.sfxGo.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxGo.play();
              },
              onComplete: () => {
                this.tweens.add({
                  targets: startText,
                  scaleX: 0,
                  scaleY: 0,
                  duration: 300,
                  delay: 1000,
                  ease: "Sine.easeInOut",
                  onStart: () => {
                    this.sfxRound.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    this.sfxRound.play();
                  },
                  onComplete: () => {
                    // after setup intro message then
                    this.Meter_1_BackBar.setAlpha(0.7);
                    this.currentMeter = 1;
                    this.sptAttackBtn.setInteractive({ useHandCursor: true });
                    this.sptAttackBtn.setFrame(0);

                    this.btnTween = this.tweens.add({
                      targets: this.sptAttackBtn,
                      scaleX: 0.75,
                      scaleY: 0.75,
                      duration: 500,
                      repeat: -1,
                      yoyo: true,
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  enemyEntry() {
    this.tweens.add({
      targets: this.enemy,
      x: "-=200",
      duration: 500,
      delay: 1000,
      ease: "Sine.easeInOut",
      onComplete: () => {
        this.time.delayedCall(1000, () => {
          this.tweens.add({
            targets: [this.enemyHPBar, this.enemyHPTxt, this.enemyName],
            alpha: 1,
            duration: 100,
          });
          this.tweens.add({
            targets: [this.enemyHPBarBack],
            alpha: 0.2,
            duration: 100,
          });
          this.tweens.add({
            targets: [this.enemyHPBoard],
            alpha: 0.9,
            duration: 100,
          });
          this.Meter_1_Shadow_enemy.setAlpha(0.1);
          this.startGame();
        });
      },
    });
  }

  clickAttackBtn() {
    this.sptAttackBtn.setFrame(1);
    this.sptAttackBtn.setScale(0.7);
    this.btnTween.pause();
    this.toggleMeter();
  }

  registerSounds() {
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxFire = this.sound.add("sfxFire");
    this.sfxWater = this.sound.add("sfxWater");
    this.sfxMagic = this.sound.add("sfxMagic");
    this.sfxCry = this.sound.add("sfxCry");
    this.sfxHit = this.sound.add("sfxHit");
    this.sfxThunder = this.sound.add("sfxThunder");
    this.sfxBomb = this.sound.add("sfxBomb");
    this.sfxThrow = this.sound.add("sfxThrow");
    this.sfxWarningAlarm = this.sound.add("sfxWarningAlarm");
    this.sfxWarningAlarm.loop = true;
    this.sfxNoti1 = this.sound.add("sfxNoti1");
    this.sfxNoti2 = this.sound.add("sfxNoti2");
    this.sfxNoti3 = this.sound.add("sfxNoti3");
    this.sfxBoss = this.sound.add("sfxBoss");
    this.sfxLanding = this.sound.add("sfxLanding");
    this.sfxSmoke = this.sound.add("sfxSmoke");
    this.sfxReady = this.sound.add("sfxReady");
    this.sfxGo = this.sound.add("sfxGo");
    this.sfxRound = this.sound.add("sfxRound");
    this.sfxCharge = this.sound.add("sfxCharge");
    this.sfxStrong = this.sound.add("sfxStrong");
    this.sfxWeak = this.sound.add("sfxWeak");
    this.sfxShieldHit = this.sound.add("sfxShieldHit");
    this.sfxShieldPop = this.sound.add("sfxShieldPop");

    this.sfxBossSuccess = this.sound.add("sfxBossSuccess");
    this.sfxBossFail = this.sound.add("sfxBossFail");
    this.sfxCollect = this.sound.add("sfxCollect");
    this.sfxWon = this.sound.add("sfxWon");
    this.sfxWin = this.sound.add("sfxWin");
    this.sfxLost = this.sound.add("sfxLost");
    this.sfxBubble = this.sound.add("sfxBubble");
    this.sfxChest = this.sound.add("sfxChest");
  }

  registerAnims() {
    this.anims.create({
      key: "avatarPower",
      frames: this.anims.generateFrameNumbers("sptShield", {
        frames: [2, 3, 4],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.anims.create({
      key: "animPlayerEnergy",
      frames: this.anims.generateFrameNumbers("sptEnergy", {
        frames: [2, 3, 4, 5],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.anims.create({
      key: "animLanding",
      frames: this.anims.generateFrameNumbers("sptLanding", {
        frames: [0, 1, 2, 3, 4, 5],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "animDizzy",
      frames: this.anims.generateFrameNumbers("sptDizzyAnim", {
        frames: [0, 1, 0, 1],
      }),
      frameRate: 4,
      repeat: -1,
    });

    this.anims.create({
      key: "animBubbles",
      frames: this.anims.generateFrameNumbers("sptBubbles", {
        frames: [0, 1],
      }),
      frameRate: 4,
      repeat: -1,
    });

    this.anims.create({
      key: "animAttackFire",
      frames: this.anims.generateFrameNumbers("sptAttackFire", {
        frames: [1, 2, 1, 2, 3, 4, 3, 4, 4, 4, 4, 4, 3, 2, 1, 0],
      }),
      frameRate: 8,
    });

    this.anims.create({
      key: "animMiniAttackFire",
      frames: this.anims.generateFrameNumbers("sptMiniAttackFire", {
        frames: [1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 0],
      }),
      frameRate: 8,
    });

    this.anims.create({
      key: "animAttackThunder",
      frames: this.anims.generateFrameNumbers("sptAttackThunder", {
        frames: [1, 2, 1, 2, 3, 4, 3, 4, 4, 4, 4, 4, 3, 2, 1, 0],
      }),
      frameRate: 8,
    });

    this.anims.create({
      key: "animMiniAttackThunder",
      frames: this.anims.generateFrameNumbers("sptMiniAttackThunder", {
        frames: [1, 2, 1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 0],
      }),
      frameRate: 8,
    });

    this.anims.create({
      key: "animAttackWater",
      frames: this.anims.generateFrameNumbers("sptAttackWater", {
        frames: [1, 2, 1, 2, 3, 4, 5, 4, 5, 5, 4, 3, 2, 1, 0],
      }),
      frameRate: 8,
    });

    this.anims.create({
      key: "animAttackMagic",
      frames: this.anims.generateFrameNumbers("sptAttackMagic", {
        frames: [1, 2, 1, 2, 3, 4, 5, 4, 5, 5, 4, 3, 2, 1, 0],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "animMiniAttackMagic",
      frames: this.anims.generateFrameNumbers("sptMiniAttackMagic", {
        frames: [1, 2, 1, 2, 3, 4, 5, 4, 5, 5, 4, 3, 2, 1, 0],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "animWandWater",
      frames: this.anims.generateFrameNumbers("sptWandPower", {
        frames: [3, 4, 3, 4, 3, 4, 3, 4, 3, 4, 3, 4, 3, 4, 0],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "animWandFire",
      frames: this.anims.generateFrameNumbers("sptWandPower", {
        frames: [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 0],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "animWandThunder",
      frames: this.anims.generateFrameNumbers("sptWandPower", {
        frames: [5, 6, 5, 6, 5, 6, 5, 6, 5, 6, 5, 6, 5, 6, 0],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "animWandShield",
      frames: this.anims.generateFrameNumbers("sptWandPower", {
        frames: [
          7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 7,
          8, 7, 8, 7, 8, 7, 8, 7, 8, 0,
        ],
      }),
      frameRate: 5,
    });

    this.anims.create({
      key: "animKnife",
      frames: this.anims.generateFrameNumbers("sptKnife", {
        frames: [4, 3, 2, 1, 0],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.anims.create({
      key: "animKnifeAttack",
      frames: this.anims.generateFrameNumbers("sptPlayerAttacks", {
        frames: [1, 2, 3, 3, 3, 3, 3, 0],
      }),
      frameRate: 15,
    });

    this.anims.create({
      key: "animBlast",
      frames: this.anims.generateFrameNumbers("sptBlast", {
        frames: [0, 1, 2, 3, 4, 4, 4, 4, 4, 4, 4, 5, 6, 7],
      }),
      frameRate: 15,
    });

    this.anims.create({
      key: "animSmoke",
      frames: this.anims.generateFrameNumbers("sptSmoke", {
        frames: [3, 4, 5, 4, 3, 4, 5, 6, 7],
      }),
      frameRate: 5,
    });

    this.anims.create({
      key: "animBomb",
      frames: this.anims.generateFrameNumbers("sptBomb", {
        frames: [0, 1, 2, 3],
      }),
      frameRate: 10,
      repeat: -1,
    });

    this.anims.create({
      key: "animIdea",
      frames: this.anims.generateFrameNumbers("sptIdea", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });

    this.anims.create({
      key: "animShine",
      frames: this.anims.generateFrameNumbers("sptShine", {
        frames: [0, 2],
      }),
      repeat: -1,
      frameRate: 5,
    });
  }

  goQuestion() {
    this.isKOBtn = false;
    this.currentMeter = 0;
    this.isMeterRunning = false;
    this.playerMeterReadings = 0;
    this.enemyMeterReadings = 0;
    this.enemyLifeBoxes = [];
    this.playerTotalHP = this.PLAYER_HP;
    this.playerAvatarObject = [];
    this.avatarBarObject = [];

    this.registry.destroy();
    this.events.off();
    this.scene.stop("PlayScene");

    this.scene.start("QuestionScene", {
      avatar: this.avatar,
      name: this.name,
      round: this.round,
      score: this.score,
      questions: this.questions,
      friend: this.friend,
      isFriendCame: this.isFriendCame,
      difficulty: this.difficulty,
      countCorrect: this.countCorrect,
      playerHP: this.playerHP,
      enemyHP: this.enemyHP,
      enemyCount: this.enemyCount,
      noOfRounds: this.noOfRounds,
    });
  }

  friendAttack() {
    this.isFriendCame = true;
    let friendAvatar = [];

    for (let key of Object.keys(this.friend.avatar)) {
      if (this.friend.avatar[key]) {
        if (key == "body") {
          friendAvatar.push(
            this.add.image(-100, 600, key).setScale(0.25).setDepth(2)
          );
          friendAvatar.push(
            this.add
              .image(-100, 600, key)
              .setTint("0x" + this.friend.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setDepth(2)
          );
        } else if (key != "color" && key != "right") {
          friendAvatar.push(
            this.add.image(-100, 600, key).setScale(0.25).setDepth(2)
          );
        }
      }
    }

    let friendNameBoard = this.add
      .graphics()
      .fillStyle(0xffffff)
      .fillRect(40, 520, 120, 30)
      .setAlpha(0.9)
      .setDepth(99)
      .setAlpha(0);

    let friendName = this.add
      .text(
        50,
        525,
        this.friend.name.length > 12
          ? this.friend.name.substring(0, 12)
          : this.friend.name,
        {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Sassoon, sans-serif",
        }
      )
      .setOrigin(0)
      .setDepth(99)
      .setAlpha(0);

    let friendWand = this.add
      .sprite(170, 570, "sptWand")
      .setDepth(1)
      .setAlpha(0);
    friendWand.power = this.add.sprite(180, 490, "sptWandPower").setDepth(5);

    this.tweens.add({
      targets: friendAvatar,
      x: "+=200",
      duration: 1000,
      ease: "Sine.easeInOut",
      onComplete: () => {
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        this.playerMask = this.add
          .image(
            this.playerAvatarObject[0].x,
            this.playerAvatarObject[0].y,
            "maskHappyx"
          )
          .setScale(0.25)
          .setDepth(6);
        friendNameBoard.setAlpha(1);
        friendName.setAlpha(1);
        friendWand.setAlpha(1);
        if (Math.floor(Math.random() * 10) > 5 && this.enemyCount > 1) {
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(
              this.playerAvatarObject[0].x,
              this.playerAvatarObject[0].y,
              "maskHappyx"
            )
            .setScale(0.25)
            .setDepth(7);

          this.time.delayedCall(1000, () => {
            this.sfxShieldPop.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxShieldPop.play();
            friendWand.setFrame(1);
            friendWand.power.play("animWandShield");
            this.time.delayedCall(1000, () => {
              this.enemyAttack();
            });
            this.shield = true;
            this.playerShield.setAlpha(1);
            this.playerShield.play("avatarPower");
            this.playerTween.pause();
          });
          this.time.delayedCall(4500, () => {
            this.playerShield.setAlpha(0);
          });
        } else {
          this.time.delayedCall(1000, () => {
            let friendReading =
              Math.floor(Math.random() * (200 - 180 + 1)) + 180;
            let parts = this.breakNumberIntoParts(friendReading, 2);

            let Meter_1_BackBar_friend = this.add.graphics().setAlpha(0);
            this.drawMeterBack(Meter_1_BackBar_friend, 70, 700, 20, 10);
            let Meter_1_friend = this.add.graphics();

            let Meter_2_BackBar_friend = this.add.graphics().setAlpha(0);
            this.drawMeterBack(Meter_2_BackBar_friend, 130, 700, 20, 10);
            let Meter_2_friend = this.add.graphics();

            Meter_1_BackBar_friend.setAlpha(0.7);
            this.tweens.addCounter({
              from: 0,
              to: (parts[0] / 100) * 1000,
              duration: 800,
              onUpdate: (tween) => {
                this.runMeterEnemy(
                  Meter_1_friend,
                  70,
                  700,
                  20,
                  6,
                  tween.getValue()
                );
              },
              onComplete: () => {
                Meter_1_friend.reading = this.add
                  .text(70, 700, parts[0], {
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "Sassoon, sans-serif",
                  })
                  .setOrigin(0.5);
                Meter_2_BackBar_friend.setAlpha(0.7);

                this.tweens.addCounter({
                  from: 0,
                  to: (parts[1] / 100) * 1000,
                  duration: 800,
                  onUpdate: (tween) => {
                    this.runMeterEnemy(
                      Meter_2_friend,
                      130,
                      700,
                      20,
                      6,
                      tween.getValue()
                    );
                  },
                  onComplete: () => {
                    Meter_2_friend.reading = this.add
                      .text(130, 700, parts[0], {
                        color: "#fff",
                        fontSize: "18px",
                        fontFamily: "Sassoon, sans-serif",
                      })
                      .setOrigin(0.5);
                  },
                });
              },
            });

            this.time.delayedCall(2000, () => {
              let friendMask = this.add
                .image(friendAvatar[0].x, friendAvatar[0].y, "maskAttack")
                .setScale(0.25)
                .setDepth(6);

              let mgob;

              friendWand.setFrame(1);
              friendWand.power.play("animWandThunder");

              mgob = this.add
                .sprite(this.enemy.x, 330, "sptAttackThunder")
                .setDepth(3);

              this.enemyTween.pause();
              mgob.play("animAttackThunder");
              this.sfxThunder.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxThunder.play();

              if (this.enemyCount == 1) {
                if (this.enemyMask) {
                  this.enemyMask.destroy();
                }
                this.enemyMask = this.add
                  .image(this.enemy.x, this.enemy.y, "maskBossHurt")
                  .setScale(0.25)
                  .setDepth(3)
                  .setFlipX(true);
              } else {
                if (this.enemyMask) {
                  this.enemyMask.destroy();
                }
                this.enemyMask = this.add
                  .image(this.enemy.x, this.enemy.y, "maskFear")
                  .setScale(0.25)
                  .setDepth(3)
                  .setFlipX(true);
              }

              this.time.delayedCall(1500, () => {
                Meter_1_BackBar_friend.destroy();
                Meter_2_BackBar_friend.destroy();
                Meter_1_friend.reading.destroy();
                Meter_2_friend.reading.destroy();
                Meter_1_friend.destroy();
                Meter_2_friend.destroy();

                if (friendMask) {
                  friendMask.destroy();
                }
                mgob.destroy();
                this.afterEnemyGetHit(friendReading);
              });
            });
          });
        }

        this.time.delayedCall(4500, () => {
          friendNameBoard.setAlpha(0);
          friendName.setAlpha(0);
          friendWand.setAlpha(0);
          friendWand.power.setAlpha(0);
          this.tweens.add({
            targets: friendAvatar,
            x: "-=200",
            delay: 1000,
            duration: 1000,
            ease: "Sine.easeInOut",
            onComplete: () => {
              if (this.playerMask) {
                this.playerMask.destroy();
              }
              friendAvatar.forEach((element) => {
                element.destroy();
              });
              friendNameBoard.destroy();
              friendName.destroy();
              friendWand.power.destroy();
              friendWand.destroy();
            },
          });
        });
      },
    });
  }

  playerAttack() {
    this.time.delayedCall(1000, () => {
      if (this.playerMeterReadings == 0) {
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        let playerEye = this.playerAvatarObject.find(
          (sprite) => sprite.texture.key === "eye"
        );
        if (playerEye) {
          playerEye.setAlpha(0);
        }
        this.playerMask = this.add
          .image(
            this.playerAvatarObject[0].x,
            this.playerAvatarObject[0].y,
            "maskSad"
          )
          .setScale(0.25)
          .setDepth(6);

        this.time.delayedCall(1000, () => {
          playerEye.setAlpha(1);
          if (this.playerMask) {
            this.playerMask.destroy();
            this.afterPlayerAttack();
          }
        });
      } else {
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        let playerEye = this.playerAvatarObject.find(
          (sprite) => sprite.texture.key === "eye"
        );
        if (playerEye) {
          playerEye.setAlpha(0);
        }
        this.playerMask = this.add
          .image(
            this.playerAvatarObject[0].x,
            this.playerAvatarObject[0].y,
            "maskAttack"
          )
          .setScale(0.25)
          .setDepth(6);

        let mgob;

        if (this.isStrong) {
          if (this.playerMeterReadings < 100) {
            this.playerWand.setFrame(1);
            this.playerWand.power.play("animWandWater");

            mgob = this.add
              .sprite(310, 400, "sptAttackWater")
              .setDepth(3)
              .setScale(1.2);

            mgob = this.add
              .sprite(this.enemy.x, 330, "sptAttackWater")
              .setDepth(3);

            this.enemyTween.pause();
            mgob.play("animAttackWater");
            this.sfxWater.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxWater.play();
          } else if (this.playerMeterReadings < 150) {
            this.playerWand.setFrame(1);
            this.playerWand.power.play("animWandFire");

            mgob = this.add
              .sprite(this.enemy.x, 330, "sptAttackFire")
              .setDepth(3);

            this.enemyTween.pause();
            mgob.play("animAttackFire");
            this.sfxFire.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxFire.play();
          } else if (this.playerMeterReadings < 200) {
            this.playerWand.setFrame(2);

            mgob = this.add
              .sprite(310, 400, "sptAttackMagic")
              .setDepth(3)
              .setScale(1.2);

            this.enemyTween.pause();
            mgob.play("animAttackMagic");
            this.sfxMagic.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxMagic.play();
          } else {
            this.playerWand.setFrame(1);
            this.playerWand.power.play("animWandThunder");

            mgob = this.add
              .sprite(this.enemy.x, 330, "sptAttackThunder")
              .setDepth(3);

            this.enemyTween.pause();
            mgob.play("animAttackThunder");
            this.sfxThunder.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxThunder.play();
          }
        } else {
          if (this.playerMeterReadings < 50) {
            this.playerWand.setFrame(1);
            this.playerWand.power.play("animWandFire");

            mgob = this.add
              .sprite(this.enemy.x, 330, "sptMiniAttackFire")
              .setDepth(3);

            this.enemyTween.pause();
            mgob.play("animMiniAttackFire");
            this.sfxFire.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxFire.play();
          } else if (this.playerMeterReadings < 80) {
            this.playerWand.setFrame(2);

            mgob = this.add
              .sprite(310, 410, "sptMiniAttackMagic")
              .setDepth(3)
              .setScale(1.2);

            this.enemyTween.pause();
            mgob.play("animMiniAttackMagic");
            this.sfxMagic.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxMagic.play();
          } else {
            this.playerWand.setFrame(1);
            this.playerWand.power.play("animWandThunder");

            mgob = this.add
              .sprite(this.enemy.x, 330, "sptMiniAttackThunder")
              .setDepth(3);

            this.enemyTween.pause();
            mgob.play("animMiniAttackThunder");
            this.sfxThunder.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxThunder.play();
          }
        }

        if (this.enemyCount == 1) {
          if (this.enemyMask) {
            this.enemyMask.destroy();
          }
          this.enemyMask = this.add
            .image(this.enemy.x, this.enemy.y, "maskBossHurt")
            .setScale(0.25)
            .setDepth(3)
            .setFlipX(true);
        } else {
          if (this.enemyMask) {
            this.enemyMask.destroy();
          }
          this.enemyMask = this.add
            .image(this.enemy.x, this.enemy.y, "maskFear")
            .setScale(0.25)
            .setDepth(3)
            .setFlipX(true);
        }

        this.time.delayedCall(1500, () => {
          this.playerWand.setFrame(0);
          this.playerWand.power.setFrame(0);
          playerEye.setAlpha(1);
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          mgob.destroy();
          this.afterPlayerAttack();
        });
      }
    });
  }

  breakNumberIntoParts(number, count) {
    const equalPart = Math.floor(number / count);
    const remainder = number % count;

    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(equalPart + (i < remainder ? 1 : 0));
    }

    return result;
  }

  enemyAttack() {
    if (this.enemyCount == 1) {
      this.enemyMeterReadings =
        this.difficulty == 1
          ? Math.floor(Math.random() * (150 - 100 + 1)) + 100
          : this.difficulty == 2
          ? Math.floor(Math.random() * (300 - 200 + 1)) + 200
          : Math.floor(Math.random() * (300 - 280 + 1)) + 280;
      let parts = this.breakNumberIntoParts(this.enemyMeterReadings, 3);
      this.Meter_1_BackBar_enemy.setAlpha(0.7);
      this.tweens.addCounter({
        from: 0,
        to: (parts[0] / 100) * 1000,
        duration: 400,
        onUpdate: (tween) => {
          this.runMeterEnemy(
            this.Meter_1_enemy,
            350,
            490,
            20,
            6,
            tween.getValue()
          );
        },
        onComplete: () => {
          this.Meter_1_enemy.reading = this.add
            .text(350, 490, parts[0], {
              color: "#000",
              fontSize: "18px",
              fontFamily: "Sassoon, sans-serif",
            })
            .setOrigin(0.5);
          this.Meter_2_BackBar_enemy.setAlpha(0.7);

          this.tweens.addCounter({
            from: 0,
            to: (parts[1] / 100) * 1000,
            duration: 400,
            onUpdate: (tween) => {
              this.runMeterEnemy(
                this.Meter_2_enemy,
                410,
                490,
                20,
                6,
                tween.getValue()
              );
            },
            onComplete: () => {
              this.Meter_4_BackBar_enemy.setAlpha(0.7);
              this.Meter_2_enemy.reading = this.add
                .text(410, 490, parts[1], {
                  color: "#000",
                  fontSize: "18px",
                  fontFamily: "Sassoon, sans-serif",
                })
                .setOrigin(0.5);
              this.tweens.addCounter({
                from: 0,
                to: (parts[2] / 100) * 1000,
                duration: 400,
                onUpdate: (tween) => {
                  this.runMeterEnemy(
                    this.Meter_4_enemy,
                    380,
                    540,
                    20,
                    6,
                    tween.getValue()
                  );
                },
                onComplete: () => {
                  this.Meter_4_enemy.reading = this.add
                    .text(380, 540, parts[2], {
                      color: "#000",
                      fontSize: "18px",
                      fontFamily: "Sassoon, sans-serif",
                    })
                    .setOrigin(0.5);
                },
              });
            },
          });
        },
      });
    } else if (this.enemyCount == 2) {
      this.enemyMeterReadings =
        this.difficulty == 1
          ? Math.floor(Math.random() * (100 - 80 + 1)) + 80
          : this.difficulty == 2
          ? Math.floor(Math.random() * (200 - 150 + 1)) + 150
          : Math.floor(Math.random() * (200 - 180 + 1)) + 180;
      let parts = this.breakNumberIntoParts(this.enemyMeterReadings, 2);
      this.Meter_1_BackBar_enemy.setAlpha(0.7);
      this.tweens.addCounter({
        from: 0,
        to: (parts[0] / 100) * 1000,
        duration: 400,
        onUpdate: (tween) => {
          this.runMeterEnemy(
            this.Meter_1_enemy,
            350,
            490,
            20,
            6,
            tween.getValue()
          );
        },
        onComplete: () => {
          this.Meter_1_enemy.reading = this.add
            .text(350, 490, parts[0], {
              color: "#000",
              fontSize: "18px",
              fontFamily: "Sassoon, sans-serif",
            })
            .setOrigin(0.5);
          this.Meter_2_BackBar_enemy.setAlpha(0.7);

          this.tweens.addCounter({
            from: 0,
            to: (parts[1] / 100) * 1000,
            duration: 400,
            onUpdate: (tween) => {
              this.runMeterEnemy(
                this.Meter_2_enemy,
                410,
                490,
                20,
                6,
                tween.getValue()
              );
            },
            onComplete: () => {
              this.Meter_2_enemy.reading = this.add
                .text(410, 490, parts[1], {
                  color: "#000",
                  fontSize: "18px",
                  fontFamily: "Sassoon, sans-serif",
                })
                .setOrigin(0.5);
            },
          });
        },
      });
    } else {
      this.enemyMeterReadings =
        this.difficulty == 1
          ? Math.floor(Math.random() * (60 - 30 + 1)) + 30
          : this.difficulty == 2
          ? Math.floor(Math.random() * (100 - 60 + 1)) + 60
          : Math.floor(Math.random() * (100 - 90 + 1)) + 90;
      let parts = this.breakNumberIntoParts(this.enemyMeterReadings, 1);
      this.Meter_1_BackBar_enemy.setAlpha(0.7);
      this.tweens.addCounter({
        from: 0,
        to: (parts[0] / 100) * 1000,
        duration: 500,
        onUpdate: (tween) => {
          this.runMeterEnemy(
            this.Meter_1_enemy,
            350,
            490,
            20,
            6,
            tween.getValue()
          );
        },
        onComplete: () => {
          this.Meter_1_enemy.reading = this.add
            .text(350, 490, parts[0], {
              color: "#000",
              fontSize: "18px",
              fontFamily: "Sassoon, sans-serif",
            })
            .setOrigin(0.5);
        },
      });
    }

    this.time.delayedCall(1500, () => {
      let mgob;

      if (this.enemyCount == 1) {
        if (this.enemyMask) {
          this.enemyMask.destroy();
        }
        this.enemyMask = this.add
          .image(this.enemy.x, this.enemy.y, "maskBossAttack")
          .setScale(0.25)
          .setDepth(3)
          .setFlipX(true);
        this.canon.setFrame(1);

        mgob = this.add
          .sprite(this.enemy.x - 100, this.enemy.y + 30, "sptBomb")
          .setScale(0.6)
          .setDepth(7);

        mgob.play("animBomb");

        this.tweens.add({
          targets: [mgob],
          x: this.playerAvatarObject[0].x,
          duration: 500,
          onComplete: () => {
            mgob.destroy();
            mgob = this.add
              .sprite(
                this.playerAvatarObject[0].x,
                this.playerAvatarObject[0].y,
                "sptBlast"
              )
              .setDepth(6)
              .setFrame(7);
            mgob.play("animBlast");
            this.sfxBomb.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxBomb.play();
            this.sfxCry.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxCry.play();
          },
        });
        this.sfxSmoke.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxSmoke.play();
      } else {
        if (this.enemyMask) {
          this.enemyMask.destroy();
        }
        this.enemyMask = this.add
          .image(this.enemy.x, this.enemy.y, "maskAttack")
          .setScale(0.25)
          .setDepth(3)
          .setFlipX(true);
        mgob = this.add
          .sprite(this.enemy.x, this.enemy.y, "sptKnife")
          .setDepth(7);

        mgob.play("animKnife");

        if (this.shield) {
          this.tweens.add({
            targets: [mgob],
            x: 180,
            duration: 400,
            onComplete: () => {
              this.sfxShieldHit.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxShieldHit.play();
              mgob.destroy();
            },
          });
        } else {
          this.tweens.add({
            targets: [mgob],
            x: this.playerAvatarObject[0].x,
            duration: 800,
            onComplete: () => {
              this.sfxHit.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxHit.play();
              this.sfxCry.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxCry.play();
              mgob.destroy();
              mgob = this.add
                .sprite(
                  this.playerAvatarObject[0].x,
                  this.playerAvatarObject[0].y,
                  "sptPlayerAttacks"
                )
                .setDepth(7)
                .setFrame(3);
              mgob.play("animKnifeAttack");
              this.tweens.add({
                targets: this.playerAvatarObject,
                alpha: 0,
                delay: 500,
                duration: 100,
                yoyo: true,
                repeat: 3,
              });
              this.tweens.add({
                targets: this.playerMask,
                alpha: 0,
                delay: 500,
                duration: 100,
                yoyo: true,
                repeat: 3,
              });
            },
          });
          this.playerTween.pause();

          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(
              this.playerAvatarObject[0].x,
              this.playerAvatarObject[0].y,
              "maskFear"
            )
            .setScale(0.25)
            .setDepth(7);
        }

        this.sfxThrow.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxThrow.play();
      }

      this.time.delayedCall(3000, () => {
        if (this.enemyCount == 1) {
          this.canon.setFrame(0);
          if (this.enemyMask) {
            this.enemyMask.destroy();
          }
          this.enemyMask = this.add
            .image(this.enemy.x, this.enemy.y, "maskBoss")
            .setScale(0.25)
            .setDepth(3)
            .setFlipX(true);
        } else {
          if (this.enemyMask) {
            this.enemyMask.destroy();
          }
        }

        this.afterEnemyAttack(this.enemyMeterReadings);
      });
    });
  }

  endGame() {
    let star;
    this.game_cache_data = _.cloneDeep(store.state.miniGame.data);
    this.score += (this.difficulty - 1) * 50;
    if (this.enemyCount == 2) {
      star = 1;
    } else if (this.enemyCount == 1) {
      star = 2;
    } else if (this.enemyCount == 0) {
      star = 3;
    } else {
      star = 0;
    }
    this.game_cache_data.star = star;
    this.game_cache_data.score = this.score;


    this.game_cache_data.gameLoot = Math.floor(Math.random() * (5 - 3 + 1) + 3);
    store.commit("updateMiniGame", this.game_cache_data);

    this.time.delayedCall(1000, () => {
      router.push({
        name: "PageExploreDailyGameScore",
      });
    });
  }

  afterPlayerAttack() {
    this.time.delayedCall(1000, () => {
      this.Meter_1.clear();
      this.Meter_1.reading.destroy();
      this.Meter_1_BackBar.setAlpha(0);
      if (!this.isWeak) {
        this.Meter_2.clear();
        this.Meter_2.reading.destroy();
        this.Meter_2_BackBar.setAlpha(0);
      }
      if (this.isWeak) {
        this.isWeak = false;
        this.Meter_2_Shadow.setAlpha(0.2);
      }
      if (this.isStrong) {
        this.playerEnergy.setAlpha(0);
        this.dark.setAlpha(0);
        this.isStrong = false;
        this.Meter_4.clear();
        this.Meter_4.reading.destroy();
        this.Meter_4_BackBar.setAlpha(0);
        this.Meter_4_Shadow.setAlpha(0);
      }
      this.afterEnemyGetHit(this.playerMeterReadings);
    });
  }

  afterEnemyGetHit(meterReading) {
    let _enemyHP = this.updateEnemyHP(meterReading);
    if (_enemyHP > 0) {
      this.time.delayedCall(1000, () => {
        if (this.enemyCount == 1) {
          if (this.enemyMask) {
            this.enemyMask.destroy();
          }
          this.enemyMask = this.add
            .image(this.enemy.x, this.enemy.y, "maskBoss")
            .setScale(0.25)
            .setDepth(3)
            .setFlipX(true);
        } else {
          if (this.enemyMask) {
            this.enemyMask.destroy();
          }
        }
        this.enemyTween.resume();
        this.time.delayedCall(1000, () => {
          if (
            !this.isFriendCame &&
            this.friend &&
            this.friend.avatar &&
            this.friend.avatar.body &&
            ((Math.floor(Math.random() * 10) > 6 && this.difficulty < 3) ||
              this.forceFriend)
          ) {
            this.friendAttack();
          } else {
            this.enemyAttack();
          }
        });
      });
    } else {
      if (this.playerMask) {
        this.playerMask.destroy();
      }
      this.playerMask = this.add
        .image(
          this.playerAvatarObject[0].x,
          this.playerAvatarObject[0].y,
          "maskHappyx"
        )
        .setScale(0.25)
        .setDepth(6);
      this.sfxWon.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxWon.play();
      this.enemyCount--;

      this.updateEnemy();
      this.tweens.add({
        targets: [
          this.enemyHPBar,
          this.enemyHPBarBack,
          this.enemyHPBoard,
          this.enemyHPTxt,
          this.enemyName,
        ],
        alpha: 0,
        duration: 200,
        delay: 500,
      });
      this.enemyDizzy.play("animDizzy");

      if (this.enemyCount > 0) {
        if (this.enemyMask) {
          this.enemyMask.destroy();
        }
        this.enemyMask = this.add
          .image(this.enemy.x, this.enemy.y, "maskSad")
          .setScale(0.25)
          .setDepth(3)
          .setFlipX(true);

        this.tweens.add({
          targets: [this.enemy, this.enemyDizzy],
          x: "+=200",
          duration: 500,
          delay: 1000,
          ease: "Sine.easeInOut",
          onComplete: () => {
            if (this.playerMask) {
              this.playerMask.destroy();
            }
            if (this.enemyMask) {
              this.enemyMask.destroy();
            }

            this.enemy.destroy();

            if (this.enemyCount == 1) {
              this.warningBackground = this.add
                .rectangle(240, 390, 480, 200, 0xff0000)
                .setDepth(7)
                .setAlpha(0);
              this.warningText = this.add
                .text(240, 370, "", {
                  fontFamily: "Sassoon, sans-serif",
                  fontSize: 48,
                })
                .setOrigin(0.5)
                .setScale(0)
                .setDepth(8)
                .setShadow(3, 3, "#000", 0, true, true);

              this.warningMessageText = this.add
                .text(240, 420, "", {
                  fontFamily: "Sassoon, sans-serif",
                  fontSize: 28,
                })
                .setOrigin(0.5)
                .setScale(0)
                .setDepth(8)
                .setShadow(1.5, 1.5, "#000", 0, true, true);

              this.time.delayedCall(1000, () => {
                this.sfxWarningAlarm.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxWarningAlarm.play();
                this.warningText.setText(i18n.t("miniGames.kombat.warning"));
                this.warningText.setTint(0xffffff);
                this.warningMessageText.setText(
                  i18n.t("miniGames.kombat.boss")
                );
                this.warningMessageText.setTint(0xffffff);
                this.tweens.add({
                  targets: this.warningBackground,
                  alpha: 0.5,
                  duration: 200,
                  ease: "Sine.easeInOut",
                });
                this.tweens.add({
                  targets: [this.warningText, this.warningMessageText],
                  scaleY: 1,
                  scaleX: 1,
                  duration: 200,
                  ease: "Sine.easeInOut",
                  onComplete: () => {
                    this.tweens.add({
                      targets: this.warningText,
                      alpha: 0,
                      repeat: 3,
                      yoyo: true,
                      duration: 200,
                      ease: "Sine.easeInOut",
                      onComplete: () => {
                        this.sfxWarningAlarm.stop();
                        this.tweens.add({
                          targets: [this.warningText, this.warningMessageText],
                          scaleY: 0,
                          scaleX: 0,
                          duration: 200,
                          delay: 1000,
                          ease: "Sine.easeInOut",
                        });
                        this.tweens.add({
                          targets: [this.warningBackground],
                          alpha: 0,
                          duration: 200,
                          delay: 1000,
                          ease: "Sine.easeInOut",
                          onComplete: () => {
                            this.time.delayedCall(500, () => {
                              this.sfxLanding.setVolume(
                                store.state.settings.data.audio.sfx *
                                  store.state.settings.data.audio.master
                              );
                              this.sfxLanding.play();
                              this.enemy = this.add
                                .image(400, -110, "imgEny3x")
                                .setOrigin(0.5);
                              this.canon = this.add
                                .sprite(325, -85, "sptCanon")
                                .setDepth(2);

                              this.tweens.add({
                                targets: [this.enemy, this.canon],
                                y: "+= 500",
                                duration: 500,
                                ease: "Power1",
                                onComplete: () => {
                                  let dust = this.add
                                    .sprite(370, 390, "sptLanding")
                                    .setScale(0.5);
                                  dust.play("animLanding");
                                  this.sfxBoss.setVolume(
                                    store.state.settings.data.audio.sfx *
                                      store.state.settings.data.audio.master
                                  );
                                  this.sfxBoss.play();
                                  this.cameras.main.shake(500, 0.01);
                                  this.Meter_4_Shadow_enemy.setAlpha(0.1);

                                  this.enemyMask = this.add
                                    .image(
                                      this.enemy.x,
                                      this.enemy.y,
                                      "maskBoss"
                                    )
                                    .setScale(0.25)
                                    .setDepth(3)
                                    .setFlipX(true);
                                  this.time.delayedCall(1000, () => {
                                    this.enemyHP = this.BOSS_HP;
                                    this.enemyTotalHP = this.BOSS_HP;

                                    this.updatePlayerHP(0);
                                    this.updateEnemyHP(0);
                                    this.tweens.add({
                                      targets: [
                                        this.enemyHPBar,
                                        this.enemyHPTxt,
                                        this.enemyName,
                                      ],
                                      alpha: 1,
                                      duration: 100,
                                    });
                                    this.tweens.add({
                                      targets: [this.enemyHPBarBack],
                                      alpha: 0.2,
                                      duration: 100,
                                    });
                                    this.tweens.add({
                                      targets: [this.enemyHPBoard],
                                      alpha: 0.9,
                                      duration: 100,
                                    });
                                    dust.destroy();
                                    this.sfxRound.setVolume(
                                      store.state.settings.data.audio.sfx *
                                        store.state.settings.data.audio.master
                                    );
                                    this.sfxRound.play();
                                    this.preparePlayerAttack();
                                  });
                                },
                              });
                            });
                          },
                        });
                      },
                    });
                  },
                });
              });
            } else {
              this.enemy = this.add.image(580, 390, "imgEny2").setOrigin(0.5);
              this.tweens.add({
                targets: this.enemy,
                x: "-=200",
                duration: 500,
                delay: 1000,
                ease: "Sine.easeInOut",
                onComplete: () => {
                  this.time.delayedCall(1000, () => {
                    this.enemyHP = this.MINION_HP;
                    this.updatePlayerHP(0);
                    this.updateEnemyHP(0);
                    this.tweens.add({
                      targets: [
                        this.enemyHPBar,
                        this.enemyHPTxt,
                        this.enemyName,
                      ],
                      alpha: 1,
                      duration: 100,
                    });
                    this.tweens.add({
                      targets: [this.enemyHPBarBack],
                      alpha: 0.2,
                      duration: 100,
                    });
                    this.tweens.add({
                      targets: [this.enemyHPBoard],
                      alpha: 0.9,
                      duration: 100,
                    });
                    this.Meter_2_Shadow_enemy.setAlpha(0.1);
                    this.sfxRound.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    this.sfxRound.play();
                    this.preparePlayerAttack();
                  });
                },
              });
            }

            this.enemyName.setText(
              this.enemyCount == 1
                ? "Yellowbeard"
                : this.enemyCount == 2
                ? "Will"
                : "Jack"
            );

            this.enemyTween = this.tweens.add({
              targets: [this.enemy],
              scaleY: 1.01,
              scaleX: 0.99,
              y: "-=5",
              delay: 500,
              duration: 1000,
              ease: "Sine.easeInOut",
              repeat: -1,
              yoyo: true,
            });

            this.enemyDizzy.destroy();
            this.enemyDizzy = this.add
              .sprite(this.enemyCount == 1 ? 390 : 370, 350, "sptDizzyAnim")
              .setFrame(2)
              .setDepth(6);
          },
        });
      } else {
        if (this.enemyMask) {
          this.enemyMask.destroy();
        }
        this.enemyMask = this.add
          .image(this.enemy.x, this.enemy.y, "maskBossHurt")
          .setScale(0.25)
          .setDepth(3)
          .setFlipX(true);
        this.time.delayedCall(1000, () => {
          let smoke = this.add
            .sprite(380, 420, "sptSmoke")
            .setDepth(6)
            .setFrame(7)
            .setScale(2);
          smoke.play("animSmoke");
          this.sfxSmoke.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxSmoke.play();
          // this.sptAttackBtn.setAlpha(0);
          this.time.delayedCall(200, () => {
            if (this.isCollectable) {
              this.chest.setAlpha(1);
              const startX = this.chest.x; // Initial x-coordinate
              const startY = this.chest.y; // Initial y-coordinate
              const endX = 150; // Destination x-coordinate
              const endY = 580; // Destination y-coordinate

              const duration = 700; // Duration of the tween in milliseconds
              const height = 300; // Height of the arc

              // Custom tween to simulate an arc motion
              this.tweens.add({
                targets: [this.chest],
                x: endX,
                y: endY,
                duration: duration,
                ease: "Linear",
                onUpdate: function (tween, target) {
                  const progress = tween.progress;
                  const curveX = Phaser.Math.Interpolation.Bezier(
                    [startX, (startX + endX) / 2, endX],
                    progress
                  );
                  const curveY = Phaser.Math.Interpolation.Bezier(
                    [startY, startY - height, endY],
                    progress
                  );
                  target.x = curveX;
                  target.y = curveY;
                },
                onComplete: () => {
                  this.shine = this.add
                    .sprite(this.chest.x, this.chest.y, "sptShine")
                    .setAlpha(0)
                    .setDepth(9)
                    .setScale(2);

                  this.time.delayedCall(2500, () => {
                    this.chest.setDepth(10);
                    this.sfxBossSuccess.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    this.sfxBossSuccess.play();
                    let bgSh = this.add
                      .rectangle(240, 427, 480, 854, 0x000000)
                      .setAlpha(0.5)
                      .setDepth(8);
                    this.shine.setAlpha(1);
                    this.shine.play("animShine");
                    this.tweens.add({
                      targets: this.shine,
                      angle: "+=360",
                      duration: 5000,
                      ease: "Linear",
                      repeat: -1,
                    });
                    this.tweens.add({
                      targets: [this.chest],
                      scaleX: 0.65,
                      scaleY: 0.65,
                      ease: "Power1",
                      duration: 500,
                      repeat: -1,
                      yoyo: true,
                    });
                    this.shine.setInteractive({ cursor: "pointer" }).on(
                      "pointerdown",
                      () => {
                        this.chest.setFrame(1);
                        this.collection = this.add
                          .image(this.chest.x, this.chest.y, "imgCollection")
                          .setScale(0.2)
                          .setDepth(10);
                        this.tweens.add({
                          targets: this.collection,
                          y: "-=100",
                          scaleX: 0.4,
                          scaleY: 0.4,
                          duration: 500,
                          ease: "Linear",
                        });
                        this.sfxChest.setVolume(
                          store.state.settings.data.audio.sfx *
                            store.state.settings.data.audio.master
                        );
                        this.sfxChest.play();
                        this.time.delayedCall(1500, () => {
                          this.game_cache_data = _.cloneDeep(store.state.miniGame.data);
                          //this.game_cache_data.boss.isCollected = true;
                          store.commit("updateMiniGame", this.game_cache_data);
                          this.sfxCollect.setVolume(
                            store.state.settings.data.audio.sfx *
                              store.state.settings.data.audio.master
                          );
                          this.sfxCollect.play();
                          bgSh.destroy();
                          this.shine.destroy();
                          this.collection.setDepth(1);
                          this.tweens.add({
                            targets: this.collection,
                            x: this.playerAvatarObject[0].x,
                            y: this.playerAvatarObject[0].y,
                            scaleX: 0.1,
                            scaleY: 0.1,
                            duration: 300,
                            ease: "Linear",
                            onComplete: () => {
                              this.tweens.add({
                                targets: this.boss,
                                alpha: 0,
                                duration: 500,
                                ease: "Linear",
                                onComplete: () => {
                                  this.chest.destroy();
                                  this.gameWon();
                                },
                              });
                            },
                          });
                        });
                      },
                      this
                    );
                  });
                },
              });
              // this.tweens.add({
              //   targets: [this.chest],
              //   y: 680,
              //   x: 200,
              //   ease: "Power1",
              //   duration: 500,
              //   onComplete: () => {
              //     this.shine = this.add
              //       .sprite(this.chest.x, this.chest.y, "sptShine")
              //       .setAlpha(0)
              //       .setDepth(9)
              //       .setScale(2);

              //     this.time.delayedCall(2500, () => {
              //       this.chest.setDepth(10);
              //       this.sfxBossSuccess.setVolume(
              //         store.state.settings.data.audio.sfx *
              //           store.state.settings.data.audio.master
              //       );
              //       this.sfxBossSuccess.play();
              //       let bgSh = this.add
              //         .rectangle(240, 427, 480, 854, 0x000000)
              //         .setAlpha(0.5)
              //         .setDepth(8);
              //       this.shine.setAlpha(1);
              //       this.shine.play("animShine");
              //       this.tweens.add({
              //         targets: this.shine,
              //         angle: "+=360",
              //         duration: 5000,
              //         ease: "Linear",
              //         repeat: -1,
              //       });
              //       this.tweens.add({
              //         targets: [this.chest],
              //         scaleX: 0.65,
              //         scaleY: 0.65,
              //         ease: "Power1",
              //         duration: 500,
              //         repeat: -1,
              //         yoyo: true,
              //       });
              //       this.shine.setInteractive({ cursor: "pointer" }).on(
              //         "pointerdown",
              //         () => {
              //           this.chest.setFrame(1);
              //           this.collection = this.add
              //             .image(this.chest.x, this.chest.y, "imgCollection")
              //             .setScale(0.2)
              //             .setDepth(10);
              //           this.tweens.add({
              //             targets: this.collection,
              //             y: "-=100",
              //             scaleX: 0.4,
              //             scaleY: 0.4,
              //             duration: 500,
              //             ease: "Linear",
              //           });
              //           this.sfxChest.setVolume(
              //             store.state.settings.data.audio.sfx *
              //               store.state.settings.data.audio.master
              //           );
              //           this.sfxChest.play();
              //           this.time.delayedCall(1500, () => {
              //             this.game_cache_data = _.cloneDeep(store.state.miniGame.data);
              //             this.game_cache_data.boss.isCollected = true;
              //             store.commit("updateMiniGame", this.game_cache_data);
              //             this.sfxCollect.setVolume(
              //               store.state.settings.data.audio.sfx *
              //                 store.state.settings.data.audio.master
              //             );
              //             this.sfxCollect.play();
              //             bgSh.destroy();
              //             this.shine.destroy();
              //             this.collection.setDepth(1);
              //             this.tweens.add({
              //               targets: this.collection,
              //               x: this.playerAvatarObject[0].x,
              //               y: this.playerAvatarObject[0].y,
              //               scaleX: 0.1,
              //               scaleY: 0.1,
              //               duration: 300,
              //               ease: "Linear",
              //               onComplete: () => {
              //                 this.tweens.add({
              //                   targets: this.boss,
              //                   alpha: 0,
              //                   duration: 500,
              //                   ease: "Linear",
              //                   onComplete: () => {
              //                     this.chest.destroy();
              //                     this.gameWon();
              //                   },
              //                 });
              //               },
              //             });
              //           });
              //         },
              //         this
              //       );
              //     });
              //   },
              // });
            }
            this.enemy.destroy();
            this.canon.destroy();
            this.enemyDizzy.destroy();
            this.enemyMask.destroy();
            this.tweens.add({
              targets: [this.imgBoat],
              x: "-=20",
              duration: 1000,
              repeat: -1,
              yoyo: true,
            });
            this.tweens.add({
              targets: [this.imgBoat],
              y: "+=600",
              delay: 1000,
              duration: 5000,
              onStart: () => {
                this.sptBubbles.setAlpha(1).play("animBubbles");
                this.sfxBubble.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxBubble.play();
                if (!this.isCollectable) {
                  this.gameWon();
                }
              },
              onComplete: () => {
                this.sptBubbles.setAlpha(0);
              },
            });
          });

          // hide enemy rounds
          this.Meter_1_Shadow_enemy.setAlpha(0);
          this.Meter_2_Shadow_enemy.setAlpha(0);
          this.Meter_4_Shadow_enemy.setAlpha(0);
        });
      }
    }
  }

  gameWon() {
    if (this.playerMask) {
      this.playerMask.destroy();
    }
    this.playerMask = this.add
      .image(
        this.playerAvatarObject[0].x,
        this.playerAvatarObject[0].y,
        "maskHappyx"
      )
      .setScale(0.25)
      .setDepth(6);
    this.time.delayedCall(500, () => {
      this.add
        .rectangle(240, 427, 480, 854, 0x000000)
        .setAlpha(0.5)
        .setDepth(8);
      this.add
        .text(240, 500, i18n.t("miniGames.kombat.won"), {
          color: "#ffffff",
          fontSize: "50px",
          fontFamily: "Sassoon, sans-serif",
          stroke: "#000000", // Stroke color
          strokeThickness: 5, // Stroke thickness
        })
        .setDepth(8)
        .setShadow(3, 3, "#000", 0, true, true)
        .setOrigin(0.5);
      this.sfxWin.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxWin.play();
      this.endGame();
    });
  }

  afterEnemyAttack(enemyMeterReadings) {
    this.time.delayedCall(1000, () => {
      if (this.enemyCount == 1) {
        this.Meter_1_enemy.clear();
        this.Meter_1_enemy.reading.destroy();
        this.Meter_1_BackBar_enemy.setAlpha(0);
        this.Meter_2_enemy.clear();
        this.Meter_2_enemy.reading.destroy();
        this.Meter_2_BackBar_enemy.setAlpha(0);
        this.Meter_4_enemy.clear();
        this.Meter_4_enemy.reading.destroy();
        this.Meter_4_BackBar_enemy.setAlpha(0);
      } else if (this.enemyCount == 2) {
        this.Meter_1_enemy.clear();
        this.Meter_1_enemy.reading.destroy();
        this.Meter_1_BackBar_enemy.setAlpha(0);
        this.Meter_2_enemy.clear();
        this.Meter_2_enemy.reading.destroy();
        this.Meter_2_BackBar_enemy.setAlpha(0);
      } else {
        this.Meter_1_enemy.clear();
        this.Meter_1_enemy.reading.destroy();
        this.Meter_1_BackBar_enemy.setAlpha(0);
      }

      if (!this.shield) {
        let _playerHP = this.updatePlayerHP(enemyMeterReadings);

        if (_playerHP > 0) {
          this.time.delayedCall(1000, () => {
            if (this.playerMask) {
              this.playerMask.destroy();
            }
            this.playerTween.resume();
            this.preparePlayerAttack();
          });
        } else {
          this.playerDizzy.play("animDizzy");
          this.time.delayedCall(1500, () => {
            this.add
              .rectangle(240, 427, 480, 854, 0x000000)
              .setAlpha(0.5)
              .setDepth(8);
            this.add
              .text(240, 500, i18n.t("miniGames.kombat.lost"), {
                color: "#fff",
                fontSize: "50px",
                fontFamily: "Sassoon, sans-serif",
                stroke: "#000000", // Stroke color
                strokeThickness: 5, // Stroke thickness
              })
              .setDepth(8)
              .setShadow(3, 3, "#000", 0, true, true)
              .setOrigin(0.5);
            this.sfxLost.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxLost.play();
            if (this.playerMask) {
              this.playerMask.destroy();
            }
            this.playerMask = this.add
              .image(
                this.playerAvatarObject[0].x,
                this.playerAvatarObject[0].y,
                "maskSad"
              )
              .setScale(0.25)
              .setDepth(6);

            this.endGame();
          });
        }
      } else {
        this.shield = false;
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        this.playerTween.resume();
        this.preparePlayerAttack();
      }
    });
  }

  preparePlayerAttack() {
    this.playerMeterReadings = 0;
    this.currentMeter = 1;
    this.sptAttackBtn.setInteractive({ useHandCursor: true });
    this.btnTween.resume();

    if (this.enemyHP <= this.KO_VALUE || this.enemyCount == 1) {
      this.isKOBtn = true;
      this.sptAttackBtn.setAlpha(0);
      this.sptIdea.setAlpha(1);
      this.sptIdea.play("animIdea");
    } else {
      this.sptAttackBtn.setFrame(0);
      this.Meter_1_BackBar.setAlpha(0.7);
    }
  }

  toggleMeter() {
    if (this.isMeterRunning) {
      this.sfxCharge.stop();
      this.timerTween.stop();
      this.isMeterRunning = false;
      let currentReadning = 0;

      if (this.isStrong) {
        currentReadning =
          Math.ceil((this.timerTween.totalElapsed / this.meterDuration) * 100) >
          96
            ? 100
            : Math.ceil(
                (this.timerTween.totalElapsed / this.meterDuration) * 100
              );
      } else {
        currentReadning =
          Math.ceil((this.timerTween.totalElapsed / this.meterDuration) * 50) >
          48
            ? 50
            : Math.ceil(
                (this.timerTween.totalElapsed / this.meterDuration) * 50
              );
      }

      this.playerMeterReadings += currentReadning;

      if (this.currentMeter == 1) {
        if (
          Math.ceil((this.timerTween.totalElapsed / this.meterDuration) * 50) >
          48
        ) {
          this.score += 5;
          this.scoreIndication(80, 500, 5);
          this.scoreText.setText(
            i18n.t("miniGames.general.score") + " : " + this.score
          );
          this.runMeter(this.Meter_1, 80, 490, 25, 10, this.meterDuration);
          this.sfxNoti3.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxNoti3.play();
        } else {
          this.sfxNoti1.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxNoti1.play();
        }
        this.Meter_1.reading = this.add
          .text(80, 490, currentReadning, {
            color: "#fff",
            fontSize: "18px",
            fontFamily: "Sassoon, sans-serif",
          })
          .setOrigin(0.5);

        if (!this.isWeak) {
          this.Meter_2_BackBar.setAlpha(0.7);
          this.currentMeter = 2;
          this.toggleMeter();
        } else {
          this.sptAttackBtn.setFrame(2);
          this.sptAttackBtn.removeInteractive();
          this.playerAttack();
        }
      } else if (this.currentMeter == 2) {
        if (
          Math.ceil((this.timerTween.totalElapsed / this.meterDuration) * 50) >
          48
        ) {
          this.score += 5;
          this.scoreIndication(160, 500, 5);
          this.scoreText.setText(
            i18n.t("miniGames.general.score") + " : " + this.score
          );
          this.runMeter(this.Meter_2, 160, 490, 25, 10, this.meterDuration);
          this.sfxNoti3.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxNoti3.play();
        } else {
          this.sfxNoti1.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxNoti1.play();
        }
        this.Meter_2.reading = this.add
          .text(160, 490, currentReadning, {
            color: "#fff",
            fontSize: "18px",
            fontFamily: "Sassoon, sans-serif",
          })
          .setOrigin(0.5);

        if (this.isStrong) {
          this.Meter_4_BackBar.setAlpha(0.7);
          this.currentMeter = 4;
          this.toggleMeter();
        } else {
          this.sptAttackBtn.setFrame(2);
          this.sptAttackBtn.removeInteractive();
          this.playerAttack();
        }
      } else if (this.currentMeter == 4) {
        if (
          Math.ceil((this.timerTween.totalElapsed / this.meterDuration) * 50) >
          48
        ) {
          this.score += 5;
          this.scoreIndication(120, 560, 5);
          this.scoreText.setText(
            i18n.t("miniGames.general.score") + " : " + this.score
          );
          this.runMeter(this.Meter_4, 120, 560, 25, 10, this.meterDuration);
          this.sfxNoti3.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxNoti3.play();
        } else {
          this.sfxNoti1.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxNoti1.play();
        }
        this.Meter_4.reading = this.add
          .text(120, 560, currentReadning, {
            color: "#fff",
            fontSize: "18px",
            fontFamily: "Sassoon, sans-serif",
          })
          .setOrigin(0.5);
        this.sptAttackBtn.setFrame(2);
        this.sptAttackBtn.removeInteractive();
        this.playerAttack();
      }
    } else {
      this.sfxCharge.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxCharge.play();
      if (this.currentMeter == 1) {
        this.sfxNoti1.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxNoti1.play();
      }

      this.timerTween = this.tweens.addCounter(
        {
          from: 0,
          to: this.meterDuration,
          duration: this.meterDuration,
          onUpdate: (tween) => {
            if (this.currentMeter == 1) {
              this.runMeter(this.Meter_1, 80, 490, 25, 10, tween.getValue());
            } else if (this.currentMeter == 2) {
              this.runMeter(this.Meter_2, 160, 490, 25, 10, tween.getValue());
            } else if (this.currentMeter == 4) {
              this.runMeter(this.Meter_4, 120, 560, 25, 10, tween.getValue());
            }
          },
          onComplete: () => {
            this.sfxNoti2.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxNoti2.play();
            this.timerTween.stop();
            this.isMeterRunning = false;
            this.sptAttackBtn.removeInteractive();
            if (this.currentMeter == 1) {
              this.runMeter(
                this.Meter_1,
                80,
                490,
                25,
                10,
                this.meterDuration,
                true
              );
              this.Meter_1.reading = this.add
                .text(80, 490, 0, {
                  color: "#fff",
                  fontSize: "18px",
                  fontFamily: "Sassoon, sans-serif",
                })
                .setOrigin(0.5);

              if (!this.isWeak) {
                this.Meter_2_BackBar.setAlpha(0.7);
                this.currentMeter = 2;
                this.time.delayedCall(500, () => {
                  this.toggleMeter();
                  this.sptAttackBtn.setInteractive({ useHandCursor: true });
                });
              } else {
                this.sptAttackBtn.setFrame(2);
                this.sptAttackBtn.removeInteractive();
                this.playerAttack();
              }
            } else if (this.currentMeter == 2) {
              this.runMeter(
                this.Meter_2,
                160,
                490,
                25,
                10,
                this.meterDuration,
                true
              );
              this.Meter_2.reading = this.add
                .text(160, 490, 0, {
                  color: "#fff",
                  fontSize: "18px",
                  fontFamily: "Sassoon, sans-serif",
                })
                .setOrigin(0.5);
              if (this.isStrong) {
                this.Meter_4_BackBar.setAlpha(0.7);
                this.currentMeter = 4;
                this.time.delayedCall(500, () => {
                  this.toggleMeter();
                  this.sptAttackBtn.setInteractive({ useHandCursor: true });
                });
              } else {
                this.sptAttackBtn.setFrame(2);
                this.sptAttackBtn.removeInteractive();
                this.playerAttack();
              }
            } else if (this.currentMeter == 4) {
              this.runMeter(
                this.Meter_4,
                120,
                560,
                25,
                10,
                this.meterDuration,
                true
              );
              this.Meter_4.reading = this.add
                .text(120, 560, 0, {
                  color: "#fff",
                  fontSize: "18px",
                  fontFamily: "Sassoon, sans-serif",
                })
                .setOrigin(0.5);
              this.sptAttackBtn.setFrame(2);
              this.sptAttackBtn.removeInteractive();
              this.playerAttack();
            }
          },
        },
        this
      );
      this.isMeterRunning = true;
    }
  }

  runMeter(meter, X, Y, r, t, elapsedTime, isZero = false) {
    var elapsedRatio = elapsedTime / this.meterDuration;
    var endAngle = -Math.PI / 2 - elapsedRatio * (Math.PI * 2 * 0.92);

    meter.clear();

    if (elapsedRatio > 0.1 && elapsedRatio <= 0.95) {
      meter.lineStyle(t, 0xffffff);
      meter.beginPath();
      meter.arc(X, Y, r, -Math.PI / 2 - 2 * Math.PI * 0.08, endAngle, true);
      meter.strokePath();
    }

    // Draw the last segment (Green - remaining 10%)
    if (elapsedRatio > 0.95) {
      meter.lineStyle(t, isZero ? 0xe96a5c : 0x00ffff);
      meter.beginPath();
      meter.arc(X, Y, r, -Math.PI / 2 - 2 * Math.PI * 0.08, endAngle, true);
      meter.strokePath();
    }
  }

  runMeterEnemy(meter, X, Y, r, t, elapsedTime, isZero = false) {
    var elapsedRatio = elapsedTime / 1000;
    var endAngle = -Math.PI / 2 - elapsedRatio * (Math.PI * 2 * 0.92);

    meter.clear();

    if (elapsedRatio > 0.1 && elapsedRatio <= 0.95) {
      meter.lineStyle(t, 0xffffff);
      meter.beginPath();
      meter.arc(X, Y, r, -Math.PI / 2 - 2 * Math.PI * 0.08, endAngle, true);
      meter.strokePath();
    }

    // Draw the last segment (Green - remaining 10%)
    if (elapsedRatio > 0.95) {
      meter.lineStyle(t, isZero ? 0xe96a5c : 0x00ffff);
      meter.beginPath();
      meter.arc(X, Y, r, -Math.PI / 2 - 2 * Math.PI * 0.08, endAngle, true);
      meter.strokePath();
    }
  }

  drawMeterBack(meterBack, X, Y, r, t) {
    meterBack.lineStyle(t, 0x000000);
    meterBack.beginPath();
    meterBack.arc(
      X,
      Y,
      r,
      -Math.PI / 2 - 2 * Math.PI * 0.07,
      -Math.PI / 2 - 2 * Math.PI * 0.93,
      true
    );
    meterBack.strokePath();

    meterBack.lineStyle(t - 3, 0x00ffff);
    meterBack.beginPath();
    meterBack.arc(
      X,
      Y,
      r,
      -Math.PI / 2 - 2 * Math.PI * 0.85,
      -Math.PI / 2 - 2 * Math.PI * 0.92,
      true
    );
    meterBack.strokePath();
  }

  updateEnemyHP(value) {
    let tempEnemyHP = this.enemyHP;
    this.enemyHP -= value;
    if (value > 0) {
      let indication = this.add
        .text(380, 400, "-" + value, {
          color: "#c44032",
          fontSize: "25px",
          fontFamily: "Sassoon, sans-serif",
        })
        .setOrigin(0.5);
      this.tweens.add({
        targets: indication,
        alpha: 0,
        y: "-=150",
        duration: 1500,
        ease: "Sine.easeInOut",
        onComplete: () => {
          indication.destroy();
        },
      });
    }

    if (this.enemyHP < 0) {
      this.enemyHP = 0;
      if (this.enemyCount == 1) {
        this.score += Math.floor((tempEnemyHP / this.BOSS_HP) * 400);
      } else {
        this.score += Math.floor((tempEnemyHP / this.MINION_HP) * 200);
      }
      this.scoreText.setText(
        i18n.t("miniGames.general.score") + " : " + this.score
      );
    } else {
      if (this.enemyCount == 1) {
        this.score += Math.floor((value / this.BOSS_HP) * 400);
      } else {
        this.score += Math.floor((value / this.MINION_HP) * 200);
      }

      this.scoreText.setText(
        i18n.t("miniGames.general.score") + " : " + this.score
      );
    }
    this.enemyHPBar.clear();
    if (this.enemyHP <= this.KO_VALUE) {
      this.enemyHPBar
        .fillStyle(0xff0000)
        .fillRect(
          323 - (114 * this.enemyHP) / this.enemyTotalHP + 114,
          308,
          (114 * this.enemyHP) / this.enemyTotalHP,
          19
        );
    } else {
      this.enemyHPBar
        .fillStyle(0x00ff00)
        .fillRect(
          323 - (114 * this.enemyHP) / this.enemyTotalHP + 114,
          308,
          (114 * this.enemyHP) / this.enemyTotalHP,
          19
        );
    }
    this.enemyHPTxt.setText(this.enemyHP);
    return this.enemyHP;
  }

  updatePlayerHP(value) {
    this.playerHP -= value;
    if (value > 0) {
      let indication = this.add
        .text(100, 400, "-" + value, {
          color: "#c44032",
          fontSize: "25px",
          fontFamily: "Sassoon, sans-serif",
        })
        .setDepth(7)
        .setOrigin(0.5);
      this.tweens.add({
        targets: indication,
        alpha: 0,
        y: "-=150",
        duration: 1500,
        ease: "Sine.easeInOut",
        onComplete: () => {
          indication.destroy();
        },
      });
    }

    if (this.playerHP < 0) {
      this.playerHP = 0;
    }
    this.playerHPBar.clear();
    if (this.playerHP <= this.KO_VALUE) {
      this.playerHPBar
        .fillStyle(0xff0000)
        .fillRect(43, 308, (114 * this.playerHP) / this.playerTotalHP, 19);
    } else {
      this.playerHPBar
        .fillStyle(0x00ff00)
        .fillRect(43, 308, (114 * this.playerHP) / this.playerTotalHP, 19);
    }
    this.playerHPTxt.setText(this.playerHP);
    return this.playerHP;
  }

  updateEnemy() {
    for (let index = 0; index < 3; index++) {
      this.enemyLifeBoxes[index].setTint(
        this.enemyCount <= 3 - index ? 0xffffff : 0x000000
      );
      this.enemyLifeBoxes[index].imgX.setAlpha(
        this.enemyCount > 2 - index ? 0 : 1
      );
    }
  }

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "PlayScene",
    });
  }

  scoreIndication(x, y, score, increase = true) {
    let indication = this.add
      .text(x, y, increase ? "+ " + score : "- " + score, {
        color: increase ? "#fff" : "#ff0000",
        fontSize: "20px",
        fontFamily: "Sassoon, sans-serif",
      })
      .setOrigin(0.5);
    this.tweens.add({
      targets: indication,
      alpha: 0,
      y: "-=50",
      duration: 1000,
      ease: "Sine.easeInOut",
      onComplete: () => {
        indication.destroy();
      },
    });
  }
}
